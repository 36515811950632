import moment from "moment";
export const getUserReadings = async (service, userId) => {
  const res = await service.get(`/user/rmssd?user_id=${userId}`);
  const parsedRes = parseResponse(res);
  const organisedReadings = organiseReadings(parsedRes.readings);
  return organisedReadings;
};

const organiseReadings = (readings) => {
  const data = readings.map((reading) => ({
    rmssd: reading.rmssd + " ms",
    date: moment(reading.created_at).format("YYYY-MM-DD"),
    time: moment(reading.created_at).format("hh:mm:ss A"),
    tags: reading.tags,
  }));

  return data;
};

const parseResponse = (res) => {
  const hasNaN = res.toString().search("NaN");
  if (hasNaN === -1) {
    return res;
  } else {
    const parsedData = JSON.parse(res.toString().replace(/\bNaN\b/g, "0"));
    return parsedData;
  }
};
