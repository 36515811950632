import _ from "lodash";

export const getAggregate = async (service, orgId) => {
  const aggregate = await service.get(
    `/hrv_analytics/dashboard_aggregated?type=org&id=${orgId}`
  );
  return aggregate;
};

export const getParticipants = async (service, orgId) => {
  const res = await getAllParticipants(service, orgId);
  return splitParticpants(res);
};

export const getAllParticipants = async (service, orgId) => {
  return service.get(`/group/profile?orgId=${orgId}&rmssd=true`);
};

export const getOrgGroupDashboardStats = async (service, groupId) => {
  return service.get(
    `/hrv_analytics/dashboard_aggregated?type=group&id=${groupId}`
  );
};

export const allGroupsWithTheirHRV = async (service, searchParams) => {
  const params = new URLSearchParams(searchParams);
  const stats = await service.get(
    `/hrv_analytics/org_groups_trends?${params.toString()}`
  );
  return stats;
};

const splitParticpants = (users = []) => {
  const res = users.map((user) => ({
    ...user,
    name: user.name || user.email.slice(0, user.email.indexOf("@")),
  }));
  const positiveTrends = _.filter(res, (user) => user.trend === true);
  const negetiveTrends = _.filter(res, (user) => user.trend !== true);
  return {
    positiveTrends,
    negetiveTrends,
  };
};
