import React from "react";
import ContentLoader from "react-content-loader";

const DashboardLoader = props => (
  <ContentLoader speed={2} height={340} width={550}>
    <rect x="0" y="0" rx="3" ry="3" height="2" width="150" />
    <rect x="0" y="150" rx="3" ry="3" height="2" width="150" />
    <rect x="0" y="0" rx="3" ry="3" height="150" width="2" />
    <rect x="150" y="0" rx="3" ry="3" height="150" width="2" />
    <rect x="20" y="30" rx="3" ry="3" height="10" width="60" />
    <rect x="20" y="60" rx="3" ry="3" height="25" width="50" />
    <rect x="80" y="70" rx="3" ry="3" height="15" width="15" />
    <rect x="20" y="108" rx="3" ry="3" height="10" width="40" />

    <rect x="190" y="0" rx="3" ry="3" height="2" width="150" />
    <rect x="190" y="150" rx="3" ry="3" height="2" width="150" />
    <rect x="190" y="0" rx="3" ry="3" height="150" width="2" />
    <rect x="340" y="0" rx="3" ry="3" height="150" width="2" />
    <rect x="210" y="30" rx="3" ry="3" height="10" width="60" />
    <rect x="210" y="50" rx="3" ry="3" height="25" width="50" />
    <rect x="270" y="70" rx="3" ry="3" height="15" width="15" />
    <rect x="210" y="108" rx="3" ry="3" height="10" width="40" />

    <rect x="380" y="0" rx="3" ry="3" height="2" width="150" />
    <rect x="380" y="150" rx="3" ry="3" height="2" width="150" />
    <rect x="380" y="0" rx="3" ry="3" height="150" width="2" />
    <rect x="530" y="0" rx="3" ry="3" height="150" width="2" />
    <rect x="400" y="30" rx="3" ry="3" height="10" width="60" />
    <rect x="400" y="60" rx="3" ry="3" height="25" width="50" />
    <rect x="460" y="70" rx="3" ry="3" height="15" width="15" />
    <rect x="400" y="108" rx="3" ry="3" height="10" width="40" />

    <rect x="0" y="180" rx="3" ry="3" height="2" width="150" />
    <rect x="0" y="330" rx="3" ry="3" height="2" width="150" />
    <rect x="0" y="180" rx="3" ry="3" height="150" width="2" />
    <rect x="150" y="180" rx="3" ry="3" height="150" width="2" />
    <rect x="20" y="210" rx="3" ry="3" height="10" width="60" />
    <rect x="20" y="240" rx="3" ry="3" height="25" width="50" />
    <rect x="80" y="250" rx="3" ry="3" height="15" width="15" />
    <rect x="20" y="288" rx="3" ry="3" height="10" width="40" />

    <rect x="190" y="180" rx="3" ry="3" height="2" width="150" />
    <rect x="190" y="330" rx="3" ry="3" height="2" width="150" />
    <rect x="190" y="180" rx="3" ry="3" height="150" width="2" />
    <rect x="340" y="180" rx="3" ry="3" height="150" width="2" />
    <rect x="210" y="210" rx="3" ry="3" height="10" width="60" />
    <rect x="210" y="240" rx="3" ry="3" height="25" width="50" />
    <rect x="270" y="250" rx="3" ry="3" height="15" width="15" />
    <rect x="210" y="288" rx="3" ry="3" height="10" width="40" />

    <rect x="380" y="180" rx="3" ry="3" height="2" width="150" />
    <rect x="380" y="330" rx="3" ry="3" height="2" width="150" />
    <rect x="380" y="180" rx="3" ry="3" height="150" width="2" />
    <rect x="530" y="180" rx="3" ry="3" height="150" width="2" />
    <rect x="420" y="250" rx="3" ry="3" height="10" width="70" />
    <rect x="450" y="220" rx="3" ry="3" height="70" width="10" />
  </ContentLoader>
);

export default DashboardLoader;
