const CONSTANT = {
  SECRET_KEY: [
    37,
    205,
    66,
    19,
    155,
    79,
    88,
    125,
    145,
    104,
    242,
    204,
    58,
    181,
    219,
    169,
    24,
    31,
    144,
    8,
    145,
    36,
    121,
    130,
    176,
    44,
    72,
    170,
    90,
    184,
    211,
    25
  ],
  PUBLIC_KEY: [
    51,
    143,
    47,
    138,
    248,
    153,
    101,
    137,
    85,
    87,
    139,
    3,
    167,
    81,
    126,
    196,
    238,
    170,
    84,
    123,
    248,
    127,
    156,
    2,
    57,
    202,
    195,
    30,
    229,
    132,
    113,
    40
  ]
};

export default CONSTANT;
