import { createStore, applyMiddleware } from "redux";
import reducers from "../reducers";
import thunkMiddleware from "redux-thunk";
import { verifyAuth } from "../actions";

export default function confugureStore(persistedState) {
  const store = createStore(
    reducers,
    persistedState,
    applyMiddleware(thunkMiddleware)
  );
  store.dispatch(verifyAuth());
  return store;
}
