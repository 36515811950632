import moment from "moment";
import _ from "lodash";

export const getOrganisations = async service => {
  const res = await service.get(`/org/profile`);
  const data = organizeData(res);
  return data;
};

export const removeOrganisation = (service, orgId) => {
  return service.delete(`/org/profile?orgId=${orgId}`);
};

const organizeData = res => {
  let data = res.map(org => ({
    ...org,
    created: moment
      .utc(org.created_at)
      .local()
      .format("MMMM DD, YYYY")
  }));
  _.remove(data, ["name", "Default"]);
  return data;
};
