export const getUser = async service => {
  const user = await service.get("/user/profile");
  return user;
};

export const getAttributes = location => {
  const titles = {
    "/group": "Dashboard",
    "/group/participants": "Users",
    "/group/activity": "Activity"
  };

  const title =
    location.pathname.split("/").length > 3 && location.state
      ? getUserName(location.state.user)
      : titles[location.pathname];

  return {
    title: title,
    enableBack: location.pathname.split("/").length > 3
  };
};

const getUserName = user => {
  const fName = user.first_name ? user.first_name + " " : "";
  const lName = user.last_name ? user.last_name + " " : "";
  return fName + lName || user.slice(0, typeof user === "string" ? user.indexOf("@") : 0);
};
