import React from "react";
import ReportsChart from "./chart";
import { Card, Grid } from "@material-ui/core";
import Heading from "./heading/index";
import moment from "moment";
import _ from "lodash";
import { CSVLink, CSVDownload } from "react-csv";
import SessionList from "./SessionList"

const defaultRange = {
  startDate: moment().subtract(8, "month").toDate(),
  endDate: moment().toDate(),
};

const defaultChartSettings = {
  reading_taken_time: "whole_day",
  chart_data_group_by: "distinct",
};

function AllUsersRMSSD(props) {
  const { card, usersRMSSD, selectedTags } = props;
  const [dateRange, setDateRange] = React.useState(defaultRange);
  const [chartSettings, setChartSettings] = React.useState(defaultChartSettings);

  const handleFormatData = React.useCallback(() => {
    const time_12 = moment("12:00", "HH:mm");
    // filter users rmssd based on the chart settings
    const filteredRmssdsWithUsers = [...usersRMSSD].map((ur) => {
      const readings = ur?.readings.filter(({ dateTime, tags = [] }) => {
        const date = moment(dateTime, "Y-MM-DD HH:mm");
        const term1 = date.isBetween(moment(dateRange.startDate), moment(dateRange.endDate), "[]");
        var term2 = true;
        if (chartSettings.reading_taken_time === "until_mid_day") {
          term2 = moment(date.format("HH:mm"), "HH:mm").isSameOrBefore(time_12);
        } else if (chartSettings.reading_taken_time === "after_mid_day") {
          term2 = moment(date.format("HH:mm"), "HH:mm").isSameOrAfter(time_12);
        }
        const selectedTagsResult = selectedTags?.length ? selectedTags.some((tag) => tags.some((sTag) => sTag.id === tag.id)) : true;
        return term1 && term2 && selectedTagsResult;
      });
      return { ...ur, readings };
    });
    console.log(filteredRmssdsWithUsers, "filteredRmssdsWithUsers");
    // put all users rmssd into one array so to make it easy to find out user based rmssd later on
    const allReadings = _.flatMap(filteredRmssdsWithUsers, (obj) => {
      return obj.readings;
    }).sort((a, b) => {
      return new Date(a.dateTime) - new Date(b.dateTime);
    });
    return { allReadings };
    // to where save all formatted data
    var finalData = [];
    // set of unique dates and times so we can show xAxis based on unique dates
    const uniqueDateTimes = new Set();
    allReadings.map(({ dateTime }) => {
      const realFormat = "Y-MM-DD HH:mm";
      const date = moment(dateTime, realFormat);
      const toFormat = chartSettings.chart_data_group_by === "average" ? "Y-MM-DD" : realFormat;
      uniqueDateTimes.add(date.format(toFormat));
      return true;
    });

    if (chartSettings.chart_data_group_by === "average") {
      var groupedRMSSD = new Map();
      allReadings.map((obj) => {
        const { dateTime } = obj;
        const realFormat = "Y-MM-DD HH:mm";
        const key = moment(dateTime, realFormat).format("Y-MM-DD");
        const found = groupedRMSSD.get(key);
        if (found) {
          found.readings = [...found.readings, obj];
        } else {
          groupedRMSSD.set(key, {
            date: key,
            readings: [obj],
          });
        }
        return true;
      });
      groupedRMSSD = [...groupedRMSSD.values()];
      finalData = groupedRMSSD.map(({ date, readings }) => {
        const point = {};
        filteredRmssdsWithUsers.map((obj, key) => {
          if (readings.some((rd) => rd?.userId === obj.user.user_id)) {
            const userReadings = readings.filter((rd) => rd?.userId === obj.user.user_id);
            point[`user_${key}_yAxis`] = _.meanBy(userReadings, (rd) => rd?.reading_result);
            if (typeof userReadings?.[0]?.tooltipText === "undefined") {
              debugger;
            }
            point[`user_${key}_tooltip_Text`] = userReadings?.[0]?.tooltipText;
            point[`user_tooltip_${key}_DateTime`] = userReadings?.[0]?.dateTime;
            point[`user_${key}_readingsCount`] = userReadings?.[0]?.length;
            point[`xAxis`] = moment(date, "Y-MM-DD").format("DD-MM");
            point[`toolTipDateTime`] = moment(date, "Y-MM-DD").format("DD-MM-Y");
            point[`tags`] = userReadings?.[0]?.tags.map((item) => item.tag_name).toString();
          }
          return true;
        });
        return point;
      });
    } else {
      finalData = [];
      [...uniqueDateTimes.values()].map((dateTime) => {
        const point = {};
        filteredRmssdsWithUsers.map((obj, key) => {
          const foundReading = allReadings.find((reading) => reading.userId === obj.user.user_id && dateTime === reading.dateTime);
          if (foundReading) {
            point[`user_${key}_yAxis`] = foundReading.reading_result;
            point[`user_${key}_tooltip_Text`] = foundReading.tooltipText;
            point[`user_tooltip_${key}_DateTime`] = foundReading.dateTime;
            point[`xAxis`] = moment(dateTime, "Y-MM-DD HH:mm").format("DD-MM");
            point[`toolTipDateTime`] = moment(dateTime, "Y-MM-DD HH:mm").format("DD-MM-Y HH:mm");
            point[`tags`] = foundReading.tags.map((item) => item.tag_name).toString();
          }
          return true;
        });
        finalData.push(point);
        return false;
      });
    }
    return finalData;
  }, [usersRMSSD, chartSettings.chart_data_group_by, chartSettings.reading_taken_time, dateRange.startDate, dateRange.endDate, selectedTags]);
  const formattedData = handleFormatData();
  const daily_readings = formattedData.allReadings
  const data = daily_readings.map((item) => ({
    userId: item.userId,
    dateTime: item.dateTime,
    readingResult: item.reading_result,
    rmssd: item.rmssd,
    totalPower: item.totalPower,
    hf: item.hf,
    lf: item.lf,
    vlf: item.vlf,
    "HF%": `${Math.round((item.hf / item.totalPower) * 100)}%`,
    "LF%": `${Math.round((item.lf / item.totalPower) * 100)}%`,
    "VLF%": `${Math.round((item.vlf / item.totalPower) * 100)}%`,
    /* hfnu: item.hfnu,
    lfnu: item.lfnu, */
    meanHr: item.meanHr,
    maxHr: item.maxHr,
    minHr: item.minHr,
    minMax: item.minMax,
    sdnn: item.sdnn,
    meanNni: item.meanNni,
    medianNni: item.medianNni,
    rangeNni: item.rangeNni,
    tooltipText: item.tooltipText,
    tagId: item.tags.map((tag) => tag.id),
    tagName: item.tags.map((tag) => tag.tag_name),
  }));
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Heading
          heading={`User Daily Readings`}
          withRangePicker={true}
          onSettingsChange={setChartSettings}
          defaultChartSettings={defaultChartSettings}
          defaultDateRange={defaultRange}
          handleRangeChange={setDateRange}
        />
        {data.length > 0 ? (
          <CSVLink filename="morning/anytime readings data" data={data}>Download Daily Readings </CSVLink>
        ) : null}
        <Card variant={"outlined"} square={false} className={card}>
          <Grid item  xs={12}>
            <ReportsChart chartSettings={chartSettings} users={usersRMSSD.map((obj) => obj.user)} type={"RMSSD"} data={data} />
          </Grid>
        </Card>
      </Grid>
    </React.Fragment>
  );
}
export default React.memo(AllUsersRMSSD);