import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 114;

export const useStyles = makeStyles(theme => ({
  logoStyle: {
    textAlign: "center",
    display: "block",
    position: "relative",
    padding: "32px 0",
    "& img": {
      width: 45
    }
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    // background: theme.palette.primary.main,
    height: "100vh",
    float: "left"
  },
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.primary.main
  },
  listContainer: {
    marginTop: 8
  },
  buildVer: {
    fontSize: 10,
    padding: "0 24px",
    paddingTop: 100,
    color: "white",
    opacity: "0.5"
  },
  date: {
    fontSize: 10,
    color: "white",
    opacity: "0.5",
    padding: "0 24px"
  }
}));
