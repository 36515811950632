import React, { useContext, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { UserContext } from "../../../../context/index";
import { roundAndCoalesce } from "../../../../utils";
import { allUsersWithTheirHRV } from "../handler";
import { useUtils } from "../../../../utils";
import TablePagination from "@material-ui/core/TablePagination";
import { PlayArrowSharp } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";
import moment from "moment";

const sortComparator = (v1, v2) => {
  if (v1 === null) return 1;
  if (v2 === null) return -1;
  if (v1 === false && v2 !== false) return -1;
  if (v1 !== false && v2 === false) return 1;
  return v1 > v2 ? 1 : -1;
};

const columns = [
  {
    field: "latest_reading_at",
    headerName: "Latest reading",
    minWidth: 160,
    flex: 1,
    type: "date",
    sortComparator,
  },
  {
    field: "isTrending",
    headerName: "Trend",
    minWidth: 160,
    type: "boolean",
    flex: 1,
    sortComparator,
    renderCell: (params) => {
      return params?.row?.isTrending !== null ? (
        <PlayArrowSharp
          style={
            params?.row?.isTrending
              ? {
                  color: "#32bf11",
                  transform: `rotateZ(-90deg)`,
                }
              : {
                  color: "#fd586f",
                  transform: `rotateZ(90deg)`,
                }
          }
        />
      ) : (
        "N/A"
      );
    },
  },
  {
    field: "user",
    headerName: "User",
    minWidth: 160,
    flex: 1,
    sortComparator,
  },
  {
    field: "latest",
    headerName: "Latest",
    description: "Latest HRV",
    minWidth: 120,
    type: "number",
    hideSortIcons: true,
    flex: 1,
    sortComparator,
  },
  {
    field: "previous",
    headerName: "Previous",
    description: "Previous HRV",
    type: "number",
    width: 170,
    hideSortIcons: true,
    flex: 1,
    sortComparator,
  },
  {
    field: "last-week",
    headerName: "Last week",
    description: "Last week HRV",
    type: "number",
    width: 170,
    hideSortIcons: true,
    flex: 1,
    sortComparator,
  },
  {
    field: "last-month",
    headerName: "Last month",
    description: "Last month HRV",
    type: "number",
    width: 170,
    hideSortIcons: true,
    flex: 1,
    sortComparator,
  },
  {
    field: "all-time",
    headerName: "All time",
    description: "All time HRV",
    type: "number",
    width: 170,
    hideSortIcons: true,
    flex: 1,
    sortComparator,
  },
  {
    field: "population",
    headerName: "Population",
    description: "Average HRV for Your Demographic",
    type: "number",
    width: 170,
    hideSortIcons: true,
    flex: 1,
    sortComparator,
  },
];

const useStyles = makeStyles({
  root: {
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    borderRadius: 10,
    border: 0,
  },
});

function UsersWithHRV(props) {
  const { onRowClick } = props;
  const [loading, setLoading] = React.useState(false);
  const [groupUsers, setGroupUsers] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalUsers, setTotalUsers] = React.useState(5);
  const user = useContext(UserContext);
  const { showToast } = useUtils();
  const classes = useStyles();
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const stats = await allUsersWithTheirHRV(props.service, {
          groupId: user.current_group_id,
          limit: rowsPerPage,
          offset: page * rowsPerPage,
        });
        setGroupUsers(stats.list);
        setTotalUsers(stats.noOfRows);
        setLoading(false);
      } catch (err) {
        showToast("error", err.message);
        setLoading(false);
      }
    })();
  }, [props.service, showToast, user, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const rowsToDataGrid = React.useMemo(() => {
    return groupUsers.map((participant) => {
      const {
        user_id: id,
        first_name,
        last_name,
        last_reading_at,
        population_avg_hrv,
        latest_avg_rmssd: latest,
        yesterday_avg_rmssd: previous,
        last_week_avg_rmssd: lastWeek,
        last_month_avg_rmssd: lastMonth,
        all_time_avg_rmssd: allTime,
      } = participant;
      return {
        latest_reading_at: last_reading_at ? moment(last_reading_at, "Y-MM-DD hh:mm:ss").utc(false).local().format("MMM DD hh:mm A") : "-",
        id,
        user: `${first_name} ${last_name}`,
        isTrending: latest && lastWeek ? latest > lastWeek : null,
        latest: roundAndCoalesce(latest),
        previous: roundAndCoalesce(previous),
        "last-week": roundAndCoalesce(lastWeek),
        "last-month": roundAndCoalesce(lastMonth),
        "all-time": roundAndCoalesce(allTime),
        population: population_avg_hrv,
        participant: { user_id: id, id, first_name, last_name },
      };
    });
  }, [groupUsers]);
  const [sortModel, setSortModel] = React.useState([
    {
      field: "isTrending",
      sort: "asc",
    },
  ]);

  return (
    <React.Fragment>
      <DataGrid
        rows={rowsToDataGrid}
        columns={columns}
        pageSize={rowsPerPage}
        autoHeight
        loading={loading}
        disableSelectionOnClick
        density="compact"
        onRowClick={(params) => {
          onRowClick(params.row?.participant);
        }}
        components={{
          Pagination: () => (
            <CustomPagination
              {...{
                page,
                rowsPerPage,
                handleChangePage,
                handleChangeRowsPerPage,
                count: totalUsers,
              }}
            />
          ),
        }}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        className={classes.root}
      />
    </React.Fragment>
  );
}

function CustomPagination(props) {
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, count } = props;
  return (
    <TablePagination
      component="div"
      rowsPerPageOptions={[5, 10, 20, 40, 50, 100]}
      count={count}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
}
export default React.memo(UsersWithHRV);
