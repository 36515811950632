import React from "react";
import { useSelector } from "react-redux";
import Http from "./service";

export default WrapperComponent => {
  return function MiddlewareRender(props) {
    const { firebaseUser } = useSelector(state => ({
      firebaseUser: state.auth.user
    }));
    return <WrapperComponent {...props} service={new Http(firebaseUser)} />;
  };
};
