import _ from "lodash";

const columns = [
  {
    title: "Name",
    field: "name"
  },
  {
    title: "Username",
    field: "email"
  },
  {
    title: "Organization",
    field: "org_name"
  }
];
const addOrgAdmin = async (service, body) => {
  const res = await service.put(`/org/admin`, body);
  return res;
};
const getOrgUsers = async (service, orgId, admins) => {
  const res = await service.get(`/org/users?orgId=${orgId}&type=internal`);
  const data = res.map(user => ({
    ...user,
    name: (user.first_name || "") + " " + (user.last_name || "")
  }));
  const users = organizeData(data, admins);
  return users;
};

const organizeData = (data, admins) => {
  let filteredData = _.differenceBy(data, admins, "user_id");
  return filteredData;
};
const getUsers = async (service, orgId, keyword, admins) => {
  let params = `keyword=${keyword}`;
  if (orgId) params = `${params}&orgId=${orgId}`;
  const res = await service.getAutoSuggestions(`/user/list?${params}`);
  const users = organizeData(res, admins);
  return users;
};

export { columns, addOrgAdmin, getOrgUsers, getUsers };
