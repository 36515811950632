import { UPDATE_USER, UPDATE_NOTIFICATION } from "../../actions/app";

export default (
  state = {
    user: null,
    notification: {
      count: 0
    }
  },
  action
) => {
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...state,
        user: action.user
      };
    case UPDATE_NOTIFICATION: {
      return {
        ...state,
        notification: action.notification
      };
    }
    default:
      return state;
  }
};
