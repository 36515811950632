class Parser {
  response(res) {
    return new Promise((resolve, reject) => {
      switch (res.status) {
        case 200:
        case 201:
        case 202:
        case 203:
          resolve(res.data);
          break;
        case 204:
          resolve(res.data || "success");
          break;
        default:
          reject(res.data || { message: "Something went wrong!" });
      }
    });
  }
}

export default Parser;
