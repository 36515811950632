import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  table: {
    borderSpacing: 0,
    border: "1px solid black"
  },
  tableWrapper: {
    "& *:before": {
      content: "",
      border: "none"
    },
    "& *:after": {
      content: "",
      border: "none"
    },
    "& *:hover:not(.Mui-disabled):before": {
      content: "",
      border: "none"
    }
  },
  toolbar: {
    position: "absolute",
    top: 0,
    right: 0,
    minHeight: 44,
    height: 44,
    margin: 12
  },
  action: { paddingRight: 10 },
  actions: {
    display: "flex",
    justifyContent: "center",
    width: "100%"
  }
});
