import DashboardIcon from "../../../assets/images/dashboard.png";
import GroupsIcon from "../../../assets/images/groups.png";
import ActivityIcon from "../../../assets/images/activity.png";
import UserIcon from "../../../assets/images/user.png";
import ReportsIcon from "../../../assets/images/reports.png";

export const menus = [
  {
    id: "dashboard",
    title: "Dashboard",
    icon: DashboardIcon,
    path: "/org",
  },
  {
    id: "groups",
    title: "Groups",
    icon: GroupsIcon,
    path: "/org/groups",
    sub: "/org/group/manage",
  },
  {
    id: "users",
    title: "Users",
    icon: UserIcon,
    path: "/org/users",
  },
  /* {
    id: "activity",
    title: "Activity",
    icon: ActivityIcon,
    path: "/org/activities",
  }, */
  {
    id: "reports",
    title: "Reports",
    icon: ReportsIcon,
    path: "/org/reports",
  },
];
