import React, { useEffect, useState } from "react";
import { useStyles } from "./style";
import AverageBox from "../../../components/avg-box";
import { getAggregate, getUserReadings } from "./handler";
import withMiddleware from "../../../middleware";
import { Grid, Typography, Divider, Button } from "@material-ui/core";
import Chart from "../../../components/chart";
import { useUtils } from "../../../utils";
import AvgCustom from "../avg-custom";
import Readings from "../../../components/readings";
import DashboardLoader from "../../../components/loader/dashboard";
import GraphLoader from "../../../components/loader/graph";
import BoxLoader from "../../../components/loader/box";
import NoReadings from "../../../components/static/no-readings";

function Profile(props) {
  const { colors, showToast } = useUtils();
  const user = props.location.state.user;
  const classes = useStyles(colors);
  const [averages, setAverages] = useState([]);
  const [readings, setReadings] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initialize();
  }, []);

  async function initialize() {
    try {
      setLoading(true);
      const aggregates = await getAggregate(
        props.service,
        user.user_id_1 || user.user_id
      );
      setAverages(aggregates.avg);
      const readings = await getUserReadings(
        props.service,
        user.user_id_1 || user.user_id
      );
      setReadings(readings);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      showToast("error", err.message);
    }
  }

  function navigateToReadings() {
    props.history.push("/group/participants/readings", {
      user: props.location.state.user,
    });
  }

  return (
    <div className={classes.root}>
      <Grid container justify="center" spacing={4}>
        <Grid
          item
          xs={6}
          style={{ height: 368, paddingBottom: 0, overflowY: "auto" }}
        >
          {loading ? (
            <DashboardLoader />
          ) : averages.length > 0 ? (
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.fullHeight}
            >
              {averages.map((avg, index) => (
                <div key={index}>
                  <AverageBox value={avg.rmssd} data={avg} />
                </div>
              ))}
              {/* <AvgCustom type="user" userId={user.user_id_1 || user.user_id} /> */}
            </Grid>
          ) : (
            <NoReadings />
          )}
        </Grid>
        <Grid item xs={6}>
          {loading ? <GraphLoader /> : <Chart data={averages} />}
        </Grid>
      </Grid>
      <Grid
        container
        justify="center"
        spacing={4}
        className={classes.trendContainer}
      >
        <Grid item xs={6}>
          <Grid container>
            <Grid item>
              <Typography className={classes.typoHead}>
                Last 5 Readings
              </Typography>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs>
              {readings.length > 5 && (
                <Grid container justify="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => navigateToReadings()}
                  >
                    View All
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>

          {loading ? (
            <BoxLoader height={100} />
          ) : readings.length > 0 ? (
            readings
              .slice(0, 5)
              .map((reading, index) => (
                <Readings key={index} reading={reading} onClick={() => {}} />
              ))
          ) : (
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justify="center"
              className={classes.emptyContainer}
            >
              <Typography className={classes.typoMessage}>
                No Readings
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </div>
  );
}

export default withMiddleware(Profile);
