import React, { useState, useEffect, useContext } from "react";
import Table from "../../../../components/table";
import withMiddleware from "../../../../middleware";
import { UserContext } from "../../../../context";
import _ from "lodash";
import { useStyles } from "./style";
import { getRequests } from "./handler";
import { updateRequestStatus } from "./handler";
import { useHistory } from "react-router-dom";
import { useUtils } from "../../../../utils";
import { useDispatch } from "react-redux";
import { updateNotification } from "../../../../actions/app";

const columns = [
  {
    title: "Name",
    field: "name",
    cellStyle: {
      fontSize: 16,
      fontWeight: "normal",
    },
  },
  {
    title: "Email",
    field: "email",
    cellStyle: {
      fontSize: 16,
      fontWeight: "normal",
    },
  },
];

function Requests(props) {
  const classes = useStyles();
  const history = useHistory();
  const { showToast } = useUtils();
  const user = useContext(UserContext);
  const dispatch = useDispatch();
  const { service } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    try {
      setLoading(true);
      const participants = await getRequests(service, user.current_group_id);
      setData(participants);
      setLoading(false);
    } catch (err) {
      showToast("error", err.message);
      setLoading(false);
    }
  }

  async function manageRequest(id, key) {
    try {
      setLoading(true);
      const body = {
        requestId: id.toString(),
        status: key,
      };
      const res = await updateRequestStatus(service, body);
      let users = _.clone(data);
      _.remove(users, (user) => user.id === id);
      setData(users);
      setLoading(false);
      dispatch(
        updateNotification({
          id: "participants",
          count: users.length,
        })
      );
      showToast("success", res.message);
    } catch (err) {
      showToast("error", err.message);
      setLoading(false);
    }
  }

  function navigateToUser(e, user) {
    history.push("/group/participants/profile", { user: user });
  }

  return (
    <>
      <Table
        columns={columns}
        data={data}
        loading={loading}
        actions={[
          {
            tootlTip: "Accept",
            icon: "check_circle_outline",
            key: "accepted",
            color: "primary",
          },
          {
            tootlTip: "Reject",
            icon: "delete",
            key: "rejected",
            color: "error",
          },
        ]}
        onActionClick={(e, data, key) => manageRequest(data.id, key)}
        onRowClick={navigateToUser}
      />
    </>
  );
}

export default withMiddleware(Requests);
