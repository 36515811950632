import React, { useState } from "react";
import { useStyles } from "./style";
import { Grid, Dialog } from "@material-ui/core";
import { useUtils } from "../../../utils";
import AdminUsers from "./admin-users";
import AddAdmin from "./add-admin";
import OrgName from "./org-name";

function Organisation(props) {
  const { location } = props;
  const { colors } = useUtils();
  const classes = useStyles(colors);
  const [open, setOpen] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [updateAdmin, setUpdateAdmin] = useState();

  return (
    <Grid className={classes.root}>
      <OrgName org={location.state.org} />
      <AdminUsers
        org={location.state.org}
        updateAdmin={updateAdmin}
        onOpen={isOpen => setOpen(isOpen)}
        onChangeAdmins={admins => setAdmins(admins)}
      />
      {open && (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          maxWidth={false}
          disableBackdropClick
          classes={{
            paper: classes.paper
          }}
          fullWidth
        >
          <AddAdmin
            org={location.state.org}
            admins={admins}
            onClose={() => setOpen(false)}
            onUpdateOrgAdmins={admins => setUpdateAdmin(admins)}
          />
        </Dialog>
      )}
    </Grid>
  );
}
export default Organisation;
