import { basePath } from "../../env";
import axios from "axios";
import ResponseParser from "./parser";
import _ from "lodash";
import { firebaseApp } from "../../components/firebase";

const METHOD = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
};

class Http {
  CancelToken = axios.CancelToken;
  source = this.CancelToken.source();

  constructor(user) {
    this.user = user;
  }

  updateUser = (user) => {
    this.user = user;
  };

  get = (endPoint) => {
    return this.caller(endPoint, METHOD.get);
  };

  post = (endPoint, body) => {
    return this.caller(endPoint, METHOD.post, body);
  };

  put = (endPoint, body) => {
    return this.caller(endPoint, METHOD.put, body);
  };

  delete = (endPoint, body = "") => {
    return this.caller(endPoint, METHOD.delete, body);
  };

  getFile(endPoint) {
    const config = {
      headers: {
        // Authorization: "Bearer " + session.getIdToken()
      },
      responseType: "blob",
    };
    return axios.get(basePath + endPoint, config);
  }

  async postFile(endPoint, formData) {
    const config = {
      headers: {
        Authorization: "Bearer " + (await this.user.getIdToken()),
        "content-type": "multipart/form-data",
      },
    };
    return axios.post(basePath + endPoint, formData, config);
  }

  putFile(endPoint, formData) {
    const config = {
      headers: {
        // Authorization: "Bearer " + session.getIdToken(),
        "content-type": "multipart/form-data",
      },
    };
    return axios.put(basePath + endPoint, formData, config);
  }

  async getAutoSuggestions(endPoint) {
    const token = await this.user.getIdToken();
    this.source.cancel("New Suggestion");
    this.source = this.CancelToken.source();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: this.source.token,
    };
    const responseParser = new ResponseParser();
    try {
      const res = await axios.get(basePath + endPoint, config);
      return responseParser.response(res);
    } catch (thrown) {
      if (axios.isCancel(thrown)) {
        return [];
      }
    }
  }

  async caller(endPoint, method, body = null, headers = {}) {
    const token = await this.user.getIdToken();
    const request = {
      url: basePath + endPoint,
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        ...headers,
      },
    };
    if (method !== METHOD.get && body !== null && !_.isEmpty(body)) {
      request.data = body;
    }
    const responseParser = new ResponseParser();

    return axios(request)
      .then((res) => {
        return responseParser.response(res);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401 && this.user) {
            firebaseApp.auth().signOut();
            return Promise.reject(
              err.response.data || { message: "Unauthorized" }
            );
          }
          return responseParser.response(err.response);
        } else
          return Promise.reject(err || { message: "Something went wrong!" });
      });
  }
}

export default Http;
