import React, { useState, useEffect } from "react";
import {
  Grid,
  Chip,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Divider,
  Button,
} from "@material-ui/core";
import Table from "../../../../components/table";
import { getOrgUsers, addGroup } from "./handler";
import withMiddleware from "../../../../middleware";
import _ from "lodash";
import { useStyles } from "./style";
import { useUtils } from "../../../../utils";
import { useTheme } from "@material-ui/styles";
import { useValidation } from "../../../../components/validation";
import { validationInput } from "./validation";

const columns = [
  {
    title: "Name",
    field: "name",
  },
  {
    title: "Username",
    field: "email",
  },
];

function AddGroups(props) {
  const { service, groups, orgId, onClose, onAddGroup } = props;
  const { colors, showToast } = useUtils();
  const theme = useTheme();
  const classes = useStyles(colors);
  const { validate, validation, isValid } = useValidation(validationInput);
  const [data, setData] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [orgAdmins, setOrgAdmins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [isGroupValid, setIsGroupValid] = useState(false);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    try {
      setLoading(true);
      const { users, orgAdmins } = await getOrgUsers(service, orgId);
      setData(users);
      setOrgAdmins(orgAdmins);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      showToast("error", err.message);
    }
  }

  async function actionHandler(e, currentUser) {
    let currentAdmins = _.clone(admins);
    currentAdmins.push(currentUser);
    setAdmins(currentAdmins);
    let users = _.clone(data);
    _.remove(users, (user) => user.user_id === currentUser.user_id);
    setData(users);
  }

  function removeAdmin(user) {
    let users = _.clone(data);
    users.push(user);
    setData(users);
    let currentAdmins = _.clone(admins);
    _.remove(currentAdmins, (admin) => admin.user_id === user.user_id);
    setAdmins(currentAdmins);
  }

  async function handleAddGroup() {
    try {
      setLoading(true);
      const adminUserIds = _.map(admins, (admin) => admin.user_id);
      const body = { name: groupName, adminUserIds, orgId: orgId.toString() };
      const res = await addGroup(service, body);
      showToast("success", res.message);
      setLoading(false);
      onAddGroup();
    } catch (err) {
      showToast("error", err.message);
      setLoading(false);
    }
  }

  function isGroupNameAvaiable(name) {
    const isMatch = _.findIndex(
      groups,
      (group) => group["name"].toUpperCase() === name.toUpperCase()
    );
    setIsGroupValid(isMatch === -1);
  }

  return (
    <>
      <DialogTitle className={classes.header} disableTypography>
        Add Group
      </DialogTitle>
      <DialogContent>
        <Grid className={classes.content}>
          <Grid container spacing={3} style={{ paddingBottom: 8 }}>
            <Grid item xs={3}>
              <Typography variant="body2" style={{ paddingTop: 8 }}>
                Group Name
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                variant="outlined"
                disabled={loading}
                value={groupName}
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                }}
                inputProps={{ maxLength: 50 }}
                error={!validation.groupName.isValid}
                helperText={validation.groupName.errMessage}
                onChange={(e) => {
                  validate(validation["groupName"], e.target.value);
                  isGroupNameAvaiable(e.target.value);
                  setGroupName(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Divider style={{ margin: "12px 0" }} />
          <Typography style={{ marginBottom: 12 }} variant="body2">
            Add Group Admins
          </Typography>
          {admins.length + orgAdmins.length > 0 && (
            <Grid container className={classes.container}>
              {orgAdmins.map((admin, index) => (
                <Grid item key={index}>
                  <Chip
                    className={classes.chip}
                    variant="outlined"
                    disabled={loading}
                    size="small"
                    color="primary"
                    label={admin.name}
                  />
                </Grid>
              ))}
              {admins.map((admin, index) => (
                <Grid item key={index}>
                  <Chip
                    className={classes.chip}
                    variant="outlined"
                    disabled={loading}
                    size="small"
                    color="primary"
                    label={
                      (admin.first_name || "") + " " + (admin.last_name || "")
                    }
                    onDelete={() => removeAdmin(admin)}
                  />
                </Grid>
              ))}
            </Grid>
          )}
          <Table
            columns={columns}
            data={data}
            loading={loading}
            actions={[
              {
                tootlTip: "Add",
                icon: "add",
                key: "add",
                color: "primary",
              },
            ]}
            headerStyle={{
              backgroundColor: theme.palette.info.light,
              color: theme.palette.secondary.main,
            }}
            onActionClick={actionHandler}
          />
        </Grid>
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleAddGroup}
          disabled={
            !isGroupValid ||
            admins.length + orgAdmins.length <= 0 ||
            loading ||
            !isValid
          }
        >
          Add
        </Button>
        <Button
          color="primary"
          disabled={loading}
          onClick={() => onClose(false)}
        >
          Cancel
        </Button>
      </DialogActions>
    </>
  );
}

export default withMiddleware(AddGroups);
