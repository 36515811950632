import { createTheme } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import { baseTheme } from "../index";

export const colors = {
  hrv_primary: "#BD48E8",
  hrv_black: "#2e2e2e",
  hrv_error: "#d83144",
  hrv_medium_gray: "#636363",
  hrv_white: "#fff",
  hrv_border_color: "#e8e8e8",
  hrv_light_gray: "#fafafa",
  hrv_info_main: "#9739B9",
  hrv_info_light: "#F1DAFA",
  hrv_info_dark: "#BD48E8",
  hrv_graphs: "rgb(90, 165, 227, 1)",
};

export const adminTheme = createTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    primary: { main: colors.hrv_primary },
    secondary: { main: colors.hrv_black },
    error: { main: colors.hrv_error },
    info: {
      main: colors.hrv_info_main,
      light: colors.hrv_info_light,
      dark: colors.hrv_info_dark,
    },
  },
  overrides: {
    ...baseTheme.overrides,
    MuiButton: {
      ...baseTheme.overrides.MuiButton,
      text: {
        color: colors.hrv_primary,
      },
    },
  },
});
