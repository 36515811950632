export const config = {
  apiKey: "AIzaSyAsF0uZJCkGt0AzZdAe8GYEZX7ee5TPx4E",
  authDomain: "optimal-hrv.firebaseapp.com",
  databaseURL: "https://optimal-hrv.firebaseio.com",
  projectId: "optimal-hrv",
  storageBucket: "optimal-hrv.appspot.com",
  messagingSenderId: "752900704346",
  appId: "1:752900704346:web:7ae780dafd336a56a9f063",
  measurementId: "G-4HPTNCVMV4"
};
