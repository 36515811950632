import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    border: (colors) => `${colors.hrv_border_color} solid 3px`,
    borderRadius: 10,
    width: 160,
    height: 160,
    background: (colors) => `${colors.hrv_white}`,
    padding: 24,
    marginRight: 16,
    marginBottom: 16,
    float: "left",
    cursor: "pointer",
    "&:hover": {
      borderColor: theme.palette.primary.main,
      background: theme.palette.info.light,
    },
  },
  icon: {
    height: 60,
    width: 60,
    color: (colors) => colors.hrv_border_color,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  dialogRoot: {
    width: 500,
    padding: 24,
    paddingTop: 32,
    background: (colors) => colors.hrv_white,
  },
  btnCancel: { marginRight: 32 },
  select: { padding: 8, fontSize: 16 },
  headerTypo: { fontSize: 14, color: "grey" },
  divider: {
    margin: "16px 0",
    opacity: 0.4,
  },
  menu: { padding: 0 },
  selectedMenu: {
    backgroundColor: `${theme.palette.info.light} !important`,
  },
}));
