import React, { useState, useEffect } from "react";
import MaterialTable, { MTableActions, MTableAction } from "material-table";
import { useTheme, TablePagination } from "@material-ui/core";
import { useStyles } from "./style";

function Table(props) {
  const theme = useTheme();
  const classes = useStyles();
  const {
    columns = [],
    data,
    options = {},
    actions = [],
    loading = false,
    headerStyle = {},
    onRowClick,
    onActionClick,
    dynamicAction = false,
    pageSize = 10
  } = props;

  useEffect(() => {}, [props]);

  function createDynamicAction() {
    return rowData => {
      let props = {
        ...rowData.action
      };
      if (!props.disabled && !props.hidden) {
        props = {
          ...props,
          onClick: (event, rowData) => {
            event.stopPropagation();
            event.preventDefault();
            if (onActionClick)
              onActionClick(event, rowData, rowData.action.key);
          }
        };
      }
      return props;
    };
  }

  function createActions() {
    const act = actions.map(action => rowData => ({
      hidden: rowData.hidden || false,
      disabled: rowData.disabled || false,
      ...action,
      iconProps: {
        color: action.color || "secondary",
        fontSize: "small"
      },
      onClick: (event, rowData) => {
        event.stopPropagation();
        event.preventDefault();
        if (onActionClick) onActionClick(event, rowData, action.key);
      }
    }));
    if (dynamicAction) {
      act.push(createDynamicAction());
    }
    return act;
  }

  function getColumns() {
    const cols = columns.map(col => ({
      cellStyle: {
        fontSize: 16,
        fontWeight: "normal",
        padding: "10px 18px"
      },
      ...col
    }));
    return cols;
  }

  return (
    <div className={classes.tableWrapper}>
      <MaterialTable
        columns={getColumns()}
        stickyHeader
        data={data}
        isLoading={loading}
        style={{
          fontSize: 16
        }}
        actions={createActions()}
        options={{
          search: false,
          padding: "dense",
          toolbar: false,
          searchFieldAlignment: "left",
          actionsColumnIndex: -1,
          paginationType: "stepped",
          pageSize: pageSize,
          showTitle: false,
          actionsCellStyle: {},
          headerStyle: {
            backgroundColor: theme.palette.primary.main,
            fontWeight: 500,
            color: "#ffffff",
            fontSize: 16,
            padding: "10px 18px",
            ...headerStyle
          },
          searchFieldStyle: {
            fontSize: 16,
            fontWeight: 400,
            height: 44,
            marginTop: 12,
            marginBottom: 12,
            border: "1px solid #efefef",
            borderRadius: 10,
            padding: 8,
            "&.MuiInputUnderline:before": {
              content: "",
              borderBottom: "none"
            }
          },
          ...options
        }}
        components={{
          Actions: props => (
            <div className={classes.actions}>
              <MTableActions {...props} />
            </div>
          ),
          Action: props => {
            return (
              <div className={classes.action}>
                <MTableAction {...props} hidden={true} />
              </div>
            );
          }
        }}
        onRowClick={(e, rowdData) => {
          if (onRowClick) onRowClick(e, rowdData);
        }}
      />
    </div>
  );
}

export default Table;
