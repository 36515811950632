import _ from "lodash";

export const getAggregate = async (service, groupId) => {
  const aggregates = await service.get(
    `/hrv_analytics/dashboard_aggregated?type=group&id=${groupId}`
  );
  return parseResponse(aggregates);
};

export const getRequestCount = async (service, groupId) => {
  return service.get(`/request/pending?userId=-1&groupId=${groupId}`);
};

export const getParticipants = async (service, groupId, orgId) => {
  const res = await getAllParticipants(service, groupId, orgId);
  return splitParticpants(res);
};

export const getAllParticipants = async (
  service,
  groupId,
  orgId,
  limit,
  offset
) => {
  // ${limit ? "&limit=" + limit : ""}${Number(offset) >= 0 ? "&offset=" + offset : ""}
  return service.get(
    `/group/participants?rmssd=true&groupId=${groupId}&orgId=${orgId}`
  );
};

export const getUserDashboardStats = async (service, userId) => {
  return service.get(
    `/hrv_analytics/dashboard_aggregated?type=user&id=${userId}`
  );
};

export const getUserRmssd = async (service, userId) => {
  return service.get(`/user/rmssd?user_id=${userId}&limit=1`);
};

export const allUsersWithTheirHRV = async (service, searchParams) => {
  const params = new URLSearchParams(searchParams);
  const stats = await service.get(
    `/hrv_analytics/group_users_trends?${params.toString()}`
  );
  return stats;
};

const parseResponse = (res) => {
  const hasNaN = res.toString().search("NaN");
  if (hasNaN === -1) {
    return res;
  } else {
    const parsedData = JSON.parse(res.toString().replace(/\bNaN\b/g, "0"));
    return parsedData;
  }
};

const splitParticpants = (users = []) => {
  const res = users.map((user) => ({
    ...user,
    name: user.first_name
      ? (user.first_name || "") + " " + (user.last_name || "")
      : user.email.slice(0, user.email.indexOf("@")),
  }));
  const positiveTrends = _.filter(res, (user) => user.trend === true);
  const negetiveTrends = _.filter(res, (user) => user.trend !== true);
  return {
    positiveTrends,
    negetiveTrends,
  };
};
