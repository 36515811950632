import React, { useState, useEffect } from "react";
import { Grid, Chip, Typography, IconButton } from "@material-ui/core";
import Table from "../../../../components/table";
import { addOrgAdmin, columns, getUsers } from "./handler";
import withMiddleware from "../../../../middleware";
import _ from "lodash";
import { useStyles } from "./style";
import { useUtils } from "../../../../utils";
import CloseIcon from "@material-ui/icons/Close";
import { useTheme } from "@material-ui/styles";
import Search from "./search";

function AddAdmin(props) {
  const { service, org, onClose, onUpdateOrgAdmins } = props;
  const { colors, showToast } = useUtils();
  const theme = useTheme();
  const classes = useStyles(colors);
  const [data, setData] = useState([]);
  const [admins, setAdmins] = useState(props.admins || []);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    try {
      setLoading(true);
      const users = await getUsers(service, org.id, "", admins);
      setData(users);
      setLoading(false);
    } catch (err) {
      showToast(err.message);
      setLoading(false);
    }
  }

  async function actionHandler(e, user) {
    try {
      setLoading(true);
      await addAdminUserToOrg(user);
      let currentAdmins = _.clone(admins);
      currentAdmins.push(user);
      setAdmins(currentAdmins);
      setLoading(false);
    } catch (err) {
      showToast("error", err.message);
      setLoading(false);
    }
  }

  async function addAdminUserToOrg(currentUser) {
    const body = {
      userId: currentUser.user_id,
      orgId: org.id.toString(),
      isExternal: (org.id !== currentUser.org_id).toString(),
    };
    const res = await addOrgAdmin(service, body);
    onUpdateOrgAdmins(admins);
    let users = _.clone(data);
    _.remove(users, (user) => user.user_id === currentUser.user_id);
    setData(users);
    showToast("success", res.message);
  }

  async function handleSearch(type, keyword) {
    try {
      setLoading(true);
      let orgId = null;
      if (type === "current") orgId = org.id;
      if (keyword.length > 2) {
        const users = await getUsers(service, orgId, keyword, admins);
        setData(users);
      } else if (type === "all") {
        setData([]);
      } else {
        init();
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <Typography className={classes.title}>Add Admin Users</Typography>
        <Grid className={classes.closeButton}>
          <IconButton
            size="small"
            color="primary"
            onClick={() => onClose(false)}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid className={classes.content}>
        <Grid container className={classes.container}>
          {admins.map((admin, index) => (
            <Grid item key={index}>
              <Chip
                className={classes.chip}
                variant="outlined"
                size="small"
                color="primary"
                label={admin.name}
              />
            </Grid>
          ))}
        </Grid>
        <Search onSearch={handleSearch} orgId={org.id} />

        <Table
          columns={columns}
          data={data}
          loading={loading}
          options={{
            pageSize: 5,
          }}
          actions={[
            {
              tootlTip: "Add",
              icon: "add",
              color: "primary",
            },
          ]}
          headerStyle={{
            backgroundColor: theme.palette.info.light,
            color: theme.palette.secondary.main,
          }}
          onActionClick={actionHandler}
        />
      </Grid>
    </Grid>
  );
}

export default withMiddleware(AddAdmin);
