import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Dialog,
  TextField,
  DialogActions,
  FormControl,
  DialogContent,
  DialogTitle,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { useStyles } from "../style";
import { validationInput } from "./validation";
import { useValidation } from "../../../../components/validation";
import SelectComponent from "../../../../components/select";
import Calendar from "../../../../components/calendar";
import moment from "moment";

function EditUser(props) {
  const {
    openEditUser,
    closeHandler,
    addHandler,
    loader,
    row,
    showAddAdmin,
    showNotificationOn,
  } = props;
  const classes = useStyles();
  const { validation, validate, autoValidate, isValid } = useValidation(
    validationInput
  );
  const defaultUser = {
    firstName: "",
    lastName: "",
    isAdmin: false,
    gender: "",
    dob: null,
    isNotificationOn: false,
  };
  const [user, setUser] = useState(defaultUser);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    setUser(defaultUser);
    let currentUser = {
      ...user,
      firstName: row.first_name,
      lastName: row.last_name,
      isAdmin: row.isAdmin,
      gender: row.gender ?? ""
    };
    if (row.birth_date) {
      currentUser["dob"] = moment(row.birth_date);
    } else {
      currentUser["dob"] = null;
    }
    validateInputs(row);
    setClicked(false);
    setUser(currentUser);
  }, [row]);

  function validateInputs(data) {
    var keys = [validation["firstName"], validation["lastName"]];
    var values = [row.first_name, row.last_name];
    if (data.birth_date) {
      keys.push(validation["dob"]);
      values.push(data.birth_date);
    }
    if (data.gender) {
      keys.push(validation["gender"]);
      values.push(data.gender);
    }
    validate(keys, values);
  }

  function handleAdd() {
    autoValidate(true);
    if (isValid) {
      addHandler(user);
    }
  }

  function handleAdmin(e) {
    setClicked(true);
    setUser({ ...user, isAdmin: e.target.checked ? "Yes" : "" });
  }
  function handleNotification(e) {
    setClicked(true);
    setUser({ ...user, isNotificationOn: e.target.checked ? "Yes" : "" });
  }
  function handleInputChange(field, value) {
    setUser({ ...user, [field]: value });
    validate(validation[field], value);
  }

  function handleDobChange(field, value) {
    setUser({ ...user, [field]: value });
    validate(validation[field], value.format("YYYY-MM-DD"));
  }

  function isAdminUser() {
    return user.isAdmin;
  }

  return (
    <div className={classes.root}>
      {loader && (
        <Grid
          container
          className={classes.loader}
          justify="center"
          alignItems="center"
          alignContent="center"
        >
          <CircularProgress />
        </Grid>
      )}
      <Dialog
        open={openEditUser}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
        classes={classes.dialog}
      >
        <DialogTitle id="form-dialog-title" className={classes.dialogHeader}>
          Edit User
        </DialogTitle>
        <DialogContent className={classes.paddingSpacer}>
          <FormControl fullWidth>
            <Grid container spacing={4} className={classes.miniFieldGridBottom}>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  id="firstname"
                  label="First name"
                  variant="outlined"
                  inputProps={{ maxLength: "100" }}
                  value={user.firstName}
                  type="text"
                  fullWidth
                  size="small"
                  error={!validation.firstName.isValid}
                  helperText={validation.firstName.errMessage}
                  onBlur={(e) =>
                    validate(validation["firstName"], e.target.value)
                  }
                  onChange={(e) =>
                    handleInputChange("firstName", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="lastname"
                  label="Last name"
                  variant="outlined"
                  inputProps={{ maxLength: "100" }}
                  value={user.lastName}
                  type="text"
                  size="small"
                  fullWidth
                  error={!validation.lastName.isValid}
                  helperText={validation.lastName.errMessage}
                  onBlur={(e) =>
                    validate(validation["lastName"], e.target.value)
                  }
                  onChange={(e) =>
                    handleInputChange("lastName", e.target.value)
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.miniFieldGridBottom}>
              <Grid item xs={6}>
                <Calendar
                  label="DOB"
                  name="dob"
                  position="right"
                  fullWidth
                  value={user.dob}
                  error={!validation.dob.isValid}
                  helperText={validation.dob.errMessage}
                  otherProps={{ maxDate: new Date() }}
                  onChange={(date) => {
                    handleDobChange("dob", date);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectComponent
                  value={user.gender}
                  label="Gender"
                  options={[
                    {
                      id: "Male",
                      name: "Male",
                    },
                    {
                      id: "Female",
                      name: "Female",
                    },
                    {
                      id: "Others",
                      name: "Others",
                    },
                  ]}
                  onChange={(e) => handleInputChange("gender", e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  labelPlacement="end"
                  label={<Typography variant="body2">Admin</Typography>}
                  control={
                    <Checkbox
                      checked={isAdminUser() === "Yes"}
                      onChange={handleAdmin}
                      size="small"
                      disabled={showAddAdmin && isAdminUser() === "Yes" && !clicked}
                      value="checkedB"
                      color="primary"
                      className={classes.checkbox}
                    />
                  }
                />
              </Grid>
            </Grid>
          </FormControl>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button
            onClick={handleAdd}
            variant="contained"
            color="primary"
            disabled={!isValid}
          >
            Update
          </Button>
          <Button
            onClick={() => closeHandler(false)}
            variant="contained"
            className={classes.actionButton}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditUser;
