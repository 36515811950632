import React, { useState, useEffect, useContext } from "react";
import Table from "../../../components/table";
import withMiddleware from "../../../middleware";
import { UserContext } from "../../../context";
import _ from "lodash";
import { useStyles } from "./style";
import { getOrganisations, removeOrganisation } from "./handler";
import { Grid, Button, Dialog } from "@material-ui/core";
import AddOrg from "../add-org";
import Alert from "../../../components/alert";
import { useUtils } from "../../../utils";

const columns = [
  {
    title: "Name",
    field: "name",
  },
  {
    title: "Groups",
    field: "groups",
  },
  {
    title: "Created On",
    field: "created",
  },
];

function Dashboard(props) {
  const classes = useStyles();
  const user = useContext(UserContext);
  const { showToast } = useUtils();
  const { service, history } = props;
  const [data, setData] = useState([]);
  const [row, setRow] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    try {
      setLoading(true);
      const organisations = await getOrganisations(service);
      setData(organisations);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      showToast("error", err.message);
    }
  }

  async function manageAction(e, org, key) {
    if (key === "edit") {
      history.push("/admin/organization", { org: org });
    } else if (key === "delete") {
      setRow(org);
      setShowConfirm(true);
    }
  }

  async function handleAddOrg() {
    setOpen(false);
    await init();
    showToast("success", "Organization added successfully");
  }

  async function deleteOrganisation() {
    try {
      setShowConfirm(false);
      setLoading(true);
      const res = await removeOrganisation(service, row.id);
      let orgs = _.clone(data);
      _.remove(orgs, (org) => org.id === row.id);
      setData(orgs);
      setLoading(false);
      showToast("success", res.message);
    } catch (err) {
      showToast("error", err.message);
      setLoading(false);
    }
  }

  return (
    <>
      <Grid container justify="flex-end" className={classes.btnRoot}>
        <Button
          variant="contained"
          // color="primary"
          style={{
            marginRight: 14,
          }}
          onClick={() => {
            service
              .get(`/org/update-demo-org`)
              .then(() => {
                showToast("success", "Demo org updated successfully");
              })
              .catch((err) => {
                showToast("error", err?.message);
              });
          }}
        >
          Update Demo Org Data
        </Button>
        <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
          Add Organization
        </Button>
      </Grid>
      <Grid className={classes.root}>
        <Grid>
          <Table
            onActionClick={manageAction}
            columns={columns}
            data={data}
            loading={loading}
            options={{
              search: true,
              toolbar: true,
            }}
            actions={[
              {
                tootlTip: "Edit",
                icon: "edit",
                key: "edit",
                color: "primary",
              },
              {
                tootlTip: "Delete",
                icon: "delete",
                key: "delete",
                color: "error",
              },
            ]}
          />
        </Grid>
      </Grid>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <AddOrg onOpen={(isOpen) => setOpen(isOpen)} onAddOrg={handleAddOrg} />
      </Dialog>
      <Alert
        title="Confirm"
        content="Are you sure you want to delete this entry?"
        open={showConfirm}
        onClose={() => setShowConfirm(false)}
        onConfirm={deleteOrganisation}
      />
    </>
  );
}

export default withMiddleware(Dashboard);
