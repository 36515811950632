import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function ExerciseTagsAutocomplete(props) {
  const { allTags, onTagsChange } = props;
  const [selectedTags, setSelectedTags] = React.useState([]);
  return (
    <Autocomplete
      multiple
      id="exercise-tags"
      options={allTags}
      disableCloseOnSelect
      getOptionLabel={(option) => option.tag_name}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.tag_name}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Tags"
          placeholder="Select tags"
        />
      )}
      onChange={(_event, newOptions, reason) => {
        if (reason === "clear"  ||  reason === "remove-option" ) {
          typeof onTagsChange === "function" && onTagsChange(newOptions);
        }
        setSelectedTags(newOptions);
      }}
      limitTags={2}
      onClose={() => {
        typeof onTagsChange === "function" && onTagsChange(selectedTags);
      }}
    />
  );
}

export default React.memo(ExerciseTagsAutocomplete);
