import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  title: {
    height: 60,
    padding: "0 24px",
    background: theme.palette.primary.main,
    color: "#fff"
  },
  form: { padding: 24 },
  spacing: { marginBottom: 12 },
  input: {
    padding: "10px 12px",
    fontSize: 16
  },
  gap: {
    marginRight: 24
  },
  labelContainer: {
    height: 41.7,
    marginBottom: 12,
    color: "#808080"
  },
  iconButton: {
    padding: 5
  },
  loader: {
    position: "absolute",
    background: "white",
    height: "calc(100% - 60px)",
    zIndex: 2,
    opacity: 0.5,
    width: "100%",
    left: 0,
    top: 60
  },
  error: {
    height: 44,
    fontSize: 16
  },
  infoIcon: { fontSize: 18, marginLeft: 6 }
}));
