import React from "react";
import {
  Typography,
  useTheme,
  Grid,
  Divider,
  Button,
  Popper,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { DateRangePicker } from "materialui-daterange-picker";
import Fade from "./Fade";
import moment from "moment";
import ChartDistTypes from "./DataDistribution";

const useStyles = makeStyles({
  typoHead: {
    fontSize: 16,
    fontWeight: 500,
  },
});

function Heading({
  heading = "",
  withRangePicker = false,
  handleRangeChange = () => {},
  onSettingsChange = () => {},
  defaultChartSettings,
  defaultDateRange,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [selectedDatesRange, setSelectedDatesRange] = React.useState({});

  React.useEffect(() => {
    setSelectedDatesRange(defaultDateRange);
  }, [defaultDateRange]);

  const toggle = () => setOpen(!open);
  const theme = useTheme();
  const classes = useStyles();
  const divider = {
    width: 32,
    height: 4,
    background: theme.palette.primary.main,
    borderRadius: 10,
    marginBottom: 24,
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };
  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "spring-popper" : undefined;
  return (
    <Grid container justifyContent="space-between">
      <div>
        <Typography className={classes.typoHead}>{heading}</Typography>
        <Divider style={divider} />
      </div>
      {withRangePicker ? (
        <Box display="flex">
          <Button onClick={handleClick} variant="text">
            {!selectedDatesRange?.startDate || !selectedDatesRange?.endDate
              ? "Select Start & End date"
              : `${moment(selectedDatesRange?.startDate).format(
                  "DD/MMM/YY"
                )} - ${moment(selectedDatesRange?.endDate).format(
                  "DD/MMM/YY"
                )}`}
          </Button>
          <Popper id={id} open={open} anchorEl={anchorEl} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <DateRangePicker
                  open={open}
                  toggle={toggle}
                  onChange={(dates) => {
                    if (dates?.startDate && dates?.endDate) {
                      setSelectedDatesRange(dates);
                      toggle();
                      typeof handleRangeChange === "function" &&
                        handleRangeChange(dates);
                    }
                  }}
                  initialDateRange={selectedDatesRange}
                />
              </Fade>
            )}
          </Popper>
          <ChartDistTypes
            onChange={onSettingsChange}
            defaultValues={defaultChartSettings}
          />
        </Box>
      ) : null}
    </Grid>
  );
}

export default React.memo(Heading);
