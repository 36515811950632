import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: {
    background: "#fff",
    padding: 18,
    border: (colors) => `${colors.hrv_border_color} solid 3px`,
    borderRadius: 10,
    marginBottom: 8,
  },
  avgTypo: {
    fontSize: 18,
    fontWeight: 600,
  },
  textTypo: {
    fontSize: 16,
    fontWeight: 600,
    color: "#7c7c7c",
  },
  expandIcon: { display: "flex", fontSize: 24, color: "#fff" },
  tagChip: { marginRight: 4, marginBottom: 4, fontSize: 12 },
});
