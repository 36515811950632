import React, { useEffect, useState, useContext } from "react";
import { useStyles } from "./style";
import AverageBox from "../../../components/avg-box";
import { getAggregate, getRequestCount } from "./handler";
import withMiddleware from "../../../middleware";
import { Grid, Typography, Divider, useTheme } from "@material-ui/core";
import Chart from "../../../components/chart";
// import Trends from "../../../components/trends";
import { useUtils } from "../../../utils";
// import AvgCustom from "../avg-custom";
import { UserContext } from "../../../context";
import DashboardLoader from "../../../components/loader/dashboard";
import GraphLoader from "../../../components/loader/graph";
// import BoxLoader from "../../../components/loader/box";
import NoReadings from "../../../components/static/no-readings";
import { useDispatch } from "react-redux";
import { updateNotification } from "../../../actions/app";
import UsersWithHRV from "./components/UsersWithHRV";

function Dashboard(props) {
  const { colors, showToast } = useUtils();
  const classes = useStyles(colors);
  const theme = useTheme();
  const divider = {
    width: 32,
    height: 4,
    background: theme.palette.primary.main,
    borderRadius: 10,
    marginBottom: 24,
  };
  const user = useContext(UserContext);
  const dispatch = useDispatch();
  const [averages, setAverages] = useState([]);
  // const [positiveTrends, setPositiveTrends] = useState([]);
  // const [negetiveTrends, setNegetiveTrends] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    init();
  }, [user]);

  async function init() {
    try {
      setLoading(true);
      const aggregates = await getAggregate(
        props.service,
        user.current_group_id
      );
      setAverages(aggregates.avg);
      setLoading(false);
      checkRequests();
    } catch (err) {
      showToast("error", err.message);
      setLoading(false);
    }
  }

  async function checkRequests() {
    try {
      const data = await getRequestCount(props.service, user.current_group_id);
      dispatch(
        updateNotification({
          id: "participants",
          count: data.count,
        })
      );
    } catch (err) {
      console.log(err);
    }
  }

  function navigateToProfile(user) {
    props.history.push("/group/participants/profile", { user: user });
  }

  return (
    <div className={classes.root}>
      <Grid container justify="center" spacing={4}>
        <Grid item xs={6} style={{ height: 368, paddingBottom: 0, overflowY: "auto" }}>
          {loading ? (
            <DashboardLoader />
          ) : averages.length > 0 ? (
            <Grid container justify="center" alignItems="center" className={classes.fullHeight}>
              {averages.map((avg, index) => (
                <div key={index}>
                  <AverageBox value={avg.rmssd} data={avg} />
                </div>
              ))}
              {/* <AvgCustom type="group" /> */}
            </Grid>
          ) : (
            <NoReadings />
          )}
        </Grid>
        <Grid item xs={6}>
          {loading ? <GraphLoader /> : <Chart data={averages} />}
        </Grid>
      </Grid>
      <Grid container justify="center" spacing={4} className={classes.trendContainer}>
        <Grid item xs={12}>
          <Typography className={classes.typoHead}>Users with their HRV Trend</Typography>
          <Divider style={divider} />
          <UsersWithHRV {...{ ...props, onRowClick: navigateToProfile }} />
        </Grid>
      </Grid>
      {/* <Grid
        container
        justify="center"
        spacing={4}
        className={classes.trendContainer}
      >
        <Grid item xs={6}>
          <Typography className={classes.typoHead}>
            Participants with positive HRV Trend
          </Typography>
          <Divider className={classes.divider} />
          {loading ? (
            <BoxLoader height={100} />
          ) : positiveTrends.length > 0 ? (
            positiveTrends.map((trend, index) => (
              <Trends
                key={index}
                data={trend}
                onClick={() => navigateToProfile(trend)}
              />
            ))
          ) : (
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justify="center"
              className={classes.emptyContainer}
            >
              <Typography className={classes.typoMessage}>
                No Participants with positive HRV Trends
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.typoHead}>
            Participants with negative HRV Trend
          </Typography>
          <Divider className={classes.divider} />
          {loading ? (
            <BoxLoader height={100} />
          ) : negetiveTrends.length > 0 ? (
            negetiveTrends.map((trend, index) => (
              <Trends
                key={index}
                data={trend}
                onClick={() => navigateToProfile(trend)}
              />
            ))
          ) : (
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justify="center"
              className={classes.emptyContainer}
            >
              <Typography className={classes.typoMessage}>
                No Participants with negative HRV Trends
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid> */}
    </div>
  );
}

export default withMiddleware(Dashboard);
