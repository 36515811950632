import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Typography,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import { useStyles } from "./style";
import withMiddleware from "../../../../middleware";
import {
  getAdminUsers,
  removeAdmin,
  getUsers,
  addAdmin,
  updateGroup,
} from "./handler";
import Table from "../../../../components/table";
import { useValidation } from "../../../../components/validation";
import { validationInput } from "./validation";
import { useUtils } from "../../../../utils";

function GroupManage(props) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { showToast } = useUtils();
  const [loading, setLoading] = useState(false);
  const { validation, validate, isValid } = useValidation(validationInput);
  const [userLoading, setUserLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(getGroupInfo().name);
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    loadAdmins();
    validate(validation["grpName"], getGroupInfo().name);
  }, []);

  function getGroupInfo() {
    return history.location.state.row;
  }

  async function loadAdmins() {
    try {
      setLoading(true);
      const { id, org_id } = getGroupInfo();
      const data = await getAdminUsers(props.service, id, org_id);
      setData(data.admins);
      setColumns(data.columns);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast("error", error.message);
    }
  }

  async function onActionClick(e, row, key) {
    try {
      const { id, org_id } = getGroupInfo();
      if (key === "delete") {
        setLoading(true);
        await removeAdmin(props.service, id, org_id, row["user_id"]);
      }
      if (key === "add") {
        setOpen(false);
        setLoading(true);
        await addAdmin(
          props.service,
          id.toString(),
          org_id.toString(),
          row["user_id"]
        );
      }
      loadAdmins();
    } catch (error) {}
  }

  async function getUserList() {
    try {
      setOpen(true);
      setUserLoading(true);
      const { id, org_id } = getGroupInfo();
      const data = await getUsers(props.service, id, org_id);
      setUserData(data.users);
      setColumns(data.columns);
      setUserLoading(false);
    } catch (error) {
      setUserLoading(false);
    }
  }

  async function handleUpdateGroup() {
    try {
      setLoading(true);
      if (isValid) {
        const { id, org_id } = getGroupInfo();
        const res = await updateGroup(
          props.service,
          id.toString(),
          org_id.toString(),
          name
        );
        showToast("success", res.message);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      showToast("error", err.message);
    }
  }

  return (
    <div className={classes.root}>
      <Grid container justify="center">
        <Grid container className={classes.formWrapper}>
          <Grid item xs auto="true" style={{ paddingRight: 36 }}>
            <TextField
              placeholder="Group Name"
              name="grpName"
              fullWidth
              value={name}
              variant="outlined"
              type={"text"}
              autoFocus={true}
              error={!validation.grpName.isValid}
              helperText={validation.grpName.errMessage}
              onBlur={(e) => validate(validation["grpName"], e.target.value)}
              onChange={(e) => setName(e.target.value)}
              disabled={loading}
              InputProps={{
                classes: {
                  input: classes.input,
                },
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateGroup}
              disabled={loading}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justify="center" className={classes.defaultAdminMainGrid}>
        <Grid className={classes.formWrapper}>
          <Grid
            container
            alignContent="center"
            alignItems="center"
            className={classes.containerHeader}
          >
            <Grid item xs auto="true">
              <Typography>Admin Users ({data.length})</Typography>
            </Grid>
            <Grid item xs className={classes.rightAlign}>
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={getUserList}
              >
                Add Admin
              </Button>
            </Grid>
          </Grid>
          <Table
            onActionClick={onActionClick}
            columns={columns}
            data={data}
            loading={loading}
            options={{
              search: false,
              toolbar: false,
            }}
            actions={[
              {
                tooltip: "Delete",
                icon: "delete",
                key: "delete",
                color: "error",
              },
            ]}
          />
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth={true}
        maxWidth="md"
        classes={classes.dialog}
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogHeader}>
          Add Admins
        </DialogTitle>
        <DialogContent className={classes.spacer}>
          <Table
            onActionClick={onActionClick}
            columns={columns}
            data={userData}
            loading={userLoading}
            options={{
              search: true,
              toolbar: true,
              pageSize: 5,
            }}
            actions={[{ tootlTip: "Add", icon: "add", key: "add" }]}
            headerStyle={{
              backgroundColor: theme.palette.info.light,
              color: theme.palette.secondary.main,
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default withMiddleware(GroupManage);
