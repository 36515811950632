export const getUser = async service => {
  const user = await service.get("/user/profile");
  return user;
};

export const getAttributes = location => {
  const titles = {
    "/admin": "Organizations",
    "/admin/activity": "Activity"
  };

  const orgName =
    location.state && location.state.org ? location.state.org.name : null;

  return {
    title: orgName || titles[location.pathname],
    enableBack: orgName != null
  };
};
