import React, { useState } from "react";
import { useStyles } from "../style";
import {
  Grid,
  TextField,
  Button,
  Typography,
  CircularProgress
} from "@material-ui/core";
import { useUtils } from "../../../../utils";
import clsx from "clsx";
import withMiddleware from "../../../../middleware";
import { updateOrgName } from "./handler";
import { useValidation } from "../../../../components/validation";
import { validationInput } from "./validation";

function OrgName(props) {
  const { service, org } = props;
  const { colors, showToast } = useUtils();
  const { validation, validate, autoValidate, isValid } = useValidation(
    validationInput
  );
  const classes = useStyles(colors);
  const [name, setName] = useState(org.name || "");
  const [loading, setLoading] = useState(false);

  async function updateHandler() {
    try {
      setLoading(true);
      const body = {
        orgId: org.id,
        name: name
      };
      const res = await updateOrgName(service, body);
      showToast("success", res.message);
      setLoading(false);
    } catch (err) {
      showToast("error", err.message);
      setLoading(false);
    }
  }

  function nameChangeHandler(e) {
    setName(e.target.value);
    autoValidate(true);
    validate(validation["name"], e.target.value);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={5}>
        <Grid
          className={clsx(
            classes.uniqueName,
            classes.container,
            classes.center,
            classes.minHeight
          )}
        >
          <Grid item xs>
            <Typography align="center">{org.unique_name}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs>
        <Grid className={clsx(classes.container, classes.center)}>
          {loading && (
            <Grid
              container
              className={classes.loader}
              justify="center"
              alignItems="center"
              alignContent="center"
            >
              <CircularProgress />
            </Grid>
          )}
          <Grid item xs>
            <TextField
              fullWidth
              value={name}
              label="Organization Name"
              InputProps={{
                classes: {
                  input: classes.input
                }
              }}
              error={!validation.name.isValid}
              helperText={validation.name.errMessage}
              onChange={nameChangeHandler}
              onBlur={e => validate(validation["name"], name)}
            />
          </Grid>
          <Grid item className={classes.updateButton}>
            <Button
              onClick={updateHandler}
              disabled={!isValid || org.name === name}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default withMiddleware(OrgName);
