import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  root: {
    padding: "98px 24px 24px 138px",
    margin: "0 10%"
  },
  container: {
    background: colors => colors.hrv_white,
    padding: "12px 24px",
    borderRadius: 10,
    width: "100%",
    border: colors => `${colors.hrv_border_color} solid 2px`
  },
  minHeight: {
    minHeight: 100
  },
  center: {
    display: "flex",
    alignContent: "center",
    alignItems: "center"
  },
  spacing: {
    marginTop: 24
  },
  table: {
    padding: "12px 0"
  },
  domain: {
    paddingBottom: 8,
    fontSize: 14
  },
  input: {
    height: 30
  },
  paper: {
    maxWidth: "70%",
    height: "80%"
  },
  updateButton: {
    paddingLeft: 12
  },
  loader: {
    position: "absolute",
    background: colors => colors.hrv_white,
    marginLeft: "-24px",
    height: 94,
    zIndex: 2,
    opacity: 0.5,
    width: "36.2%"
  },
  uniqueName: {
    background: `${theme.palette.info.light} !important`
  }
}));
