import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: {
    padding: "85px 24px 100px 138px",
    position: "relative",
  },
  card: {
    padding: "20px 15px",
    boxShadow: "0px 3px 11px 1px #d7d7d7",
    border: "0px",
    borderRadius: 5,
    background: "#fff",
  },
  // stickySearchBox: {
  //   position: "fixed",
  //   bottom: 0,
  //   right: 0,
  //   left: 115,
  //   zIndex: 11,
  // },
});
