import React, { useState, useEffect, useContext } from "react";
import { Grid, Button, Chip } from "@material-ui/core";
import _ from "lodash";
import { useUtils } from "../../../utils";
import { useStyles } from "./style";
import withMiddleware from "../../../middleware";
import Table from "../../../components/table";
import { UserContext } from "../../../context";
import Alert from "../../../components/alert";
import {
  getUserList,
  addUser,
  editUser,
  deleteUser,
  changePassword,
  uploadFile,
} from "./handler";
import AddUser from "./add-user";
import EditUser from "./edit-user";
import ChangePassword from "./change-password";
import ImportError from "./import-error";

function Users(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [addUserLoader, setAddUserLoader] = useState(false);
  const [editUserLoader, setEditUserLoader] = useState(false);
  const [changePasswordLoader, setChangePasswordLoader] = useState(false);
  const currentUser = useContext(UserContext);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [openEditUser, setOpenEditUser] = useState(false);
  const [openImportError, setOpenImportError] = useState(false);
  const [importErrorData, setImportErrorData] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [row, setRow] = useState({});
  const [showAddAdmin, setShowAddAdmin] = useState(false);
  const { getEncPassword, showToast } = useUtils();

  useEffect(() => {
    getUsers();
  }, []);

  const tableHeader = [
    {
      title: "First name",
      field: "first_name",
    },
    {
      title: "Last name",
      field: "last_name",
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Type",
      field: "adminText",
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center",
      },
      render: (rowData) => {
        return (
          <>
            {rowData.isAdmin === "Yes" && (
              <Chip
                label={rowData.adminText}
                size="small"
                variant="outlined"
                color="primary"
                className={classes.spacer}
              />
            )}
            {rowData.isExternal === "Yes" && (
              <Chip
                label={rowData.externalText}
                size="small"
                variant="outlined"
                color="secondary"
              />
            )}
          </>
        );
      },
    },
  ];

  function showAdmin(row, data) {
    if (row["user_id"] === currentUser.user_id) {
      return true;
    }
    return _.filter(data, { isAdmin: "Yes" }).length > 1 ? false : true;
  }

  async function getUsers() {
    try {
      setLoading(true);
      setColumns(tableHeader);
      const userList = await getUserList(
        props.service,
        currentUser.current_org_id,
        currentUser.user_id
      );
      setData(userList.mappedUsers);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  function onActionClick(e, row, key) {
    setRow(row);
    if (key === "delete") {
      setOpenDelete(true);
    }
    if (key === "changePassword") {
      setOpenChangePassword(true);
    }
    if (key === "edit") {
      setShowAddAdmin(showAdmin(row, data));
      setOpenEditUser(true);
    }
  }

  async function handleAdd(user) {
    try {
      setAddUserLoader(true);
      console.log(user);
      const password = getEncPassword(user.password);
      const username = `${user.username}`;
      const data = await addUser(
        props.service,
        user,
        password,
        username,
        currentUser.current_org_id.toString()
      );
      setAddUserLoader(false);
      setOpenAddUser(false);
      await getUsers();
      showToast("success", data.message);
    } catch (error) {
      setAddUserLoader(false);
      showToast("error", error.message);
    }
  }

  async function handleEdit(user) {
    try {
      setEditUserLoader(true);
      const data = await editUser(
        props.service,
        user,
        row["user_id"],
        currentUser.current_org_id.toString()
      );

      setEditUserLoader(false);
      setOpenEditUser(false);
      await getUsers();
      showToast("success", data.message);
    } catch (error) {
      setEditUserLoader(false);
      showToast("error", error.message);
    }
  }

  async function handleDeleteUser() {
    try {
      setLoading(true);
      setOpenDelete(false);
      const data = await deleteUser(
        props.service,
        row["user_id"],
        row["isAdmin"] === "Yes" ? "org" : "",
        row["isExternal"] === "Yes" ? "true" : "false",
        currentUser.current_org_id
      );
      showToast("success", data.message);
      await getUsers();
    } catch (error) {
      showToast("error", error.message);
    }
  }

  async function handleChangePassword(user) {
    try {
      setChangePasswordLoader(true);
      const passwordEnc = getEncPassword(user.password);
      const data = await changePassword(
        props.service,
        row["user_id"],
        passwordEnc,
        currentUser.current_org_id.toString()
      );
      showToast("success", data.message);
      setChangePasswordLoader(false);
      setOpenChangePassword(false);
    } catch (error) {
      setChangePasswordLoader(false);
      showToast("error", error.message);
    }
  }

  function validateFileType(file) {
    var filePath = file.name;
    var allowedExtensions = /(\.csv)$/i;
    if (!allowedExtensions.exec(filePath)) {
      showToast("error", "Please upload file having extension .csv only.");
      file.value = "";
      return false;
    }
    return true;
  }

  async function handleUploadFile(e) {
    const file = e.target.files[0];
    try {
      setLoading(true);
      if (validateFileType(file)) {
        const data = await uploadFile(
          props.service,
          file,
          currentUser.current_org_id,
          currentUser.domainname
        );
        if (data.data && data.data.failed) {
          setOpenImportError(true);
          setImportErrorData(data.data.failed);
        } else {
          showToast("success", data.message || data.data.message);
        }
        await getUsers();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast("error", "Invalid data");
    }
  }

  return (
    <div className={classes.root}>
      <Grid item xs={12} className={classes.table}>
        <Grid item className={classes.usersAction}>
          <Button
            variant="contained"
            component="label"
            color="primary"
            className={classes.spacer}
          >
            import
            <input
              type="file"
              id="file"
              name="file"
              value=""
              style={{ display: "none" }}
              onChange={handleUploadFile}
            />
          </Button>
          <Button
            onClick={() => setOpenAddUser(true)}
            variant="outlined"
            color="primary"
          >
            Add New
          </Button>
        </Grid>
        <Table
          onActionClick={onActionClick}
          columns={columns}
          data={data}
          loading={loading}
          dynamicAction={true}
          options={{
            search: true,
            toolbar: true,
          }}
          actions={[
            { tooltip: "Edit", icon: "edit", key: "edit" },
            {
              tooltip: "Change Password",
              icon: "vpn_key",
              key: "changePassword",
            },
          ]}
        />
      </Grid>
      <AddUser
        openAddUser={openAddUser}
        closeHandler={setOpenAddUser}
        addHandler={handleAdd}
        currentUser={currentUser}
        loader={addUserLoader}
      />
      <EditUser
        openEditUser={openEditUser}
        closeHandler={setOpenEditUser}
        addHandler={handleEdit}
        loader={editUserLoader}
        row={row}
        showAddAdmin={showAddAdmin}
      />
      <Alert
        title="Remove user from organization?"
        content="Are you sure you want to remove user from organization?"
        open={openDelete}
        onConfirm={handleDeleteUser}
        onClose={() => setOpenDelete(false)}
      />
      <ChangePassword
        openChangePassword={openChangePassword}
        closeHandler={setOpenChangePassword}
        changePasswordHandler={handleChangePassword}
        loader={changePasswordLoader}
      />
      {openImportError && (
        <ImportError
          openImportError={openImportError}
          closeHandler={setOpenImportError}
          data={importErrorData}
        />
      )}
    </div>
  );
}

export default withMiddleware(Users);
