import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 32
  },
  container: {
    background: colors => colors.hrv_white,
    padding: "12px 24px 0 24px",
    marginBottom: 24,
    borderRadius: 10,
    width: "100%",
    border: colors => `${colors.hrv_border_color} solid 2px`
  },
  header: {
    width: "70%",
    alignItems: "center",
    alignContent: "center",
    position: "fixed",
    background: colors => colors.hrv_white,
    color: colors => colors.hrv_white,
    zIndex: 15
  },
  title: {
    height: 60,
    width: "100%",
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    background: theme.palette.primary.main,
    padding: "0 32px"
  },
  closeButton: {
    position: "absolute",
    height: 32,
    width: 32,
    top: -16,
    right: -16,
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 16,
    background: colors => colors.hrv_white
  },
  content: { padding: "80px 32px 32px 32px" },
  chip: { marginRight: 12, marginBottom: 12 },
  input: {
    padding: "12px 12px",
    fontSize: 16
  }
}));
