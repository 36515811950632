import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { useStyles } from "./style";
import { UserContext } from "../../../context";
import withMiddleware from "../../../middleware";
import Table from "../../../components/table";
import Alert from "../../../components/alert";
import { groupHandler, groupSwitch, deleteGroup } from "./handler";
import AddGroups from "./add-groups";
import { useUtils } from "../../../utils";
import GroupSwitchLoading from "./components/SwitchGroupLoading";

function Groups(props) {
  const classes = useStyles();
  const history = useHistory();
  const user = useContext(UserContext);
  const { showToast } = useUtils();
  const [data, setData] = useState([]);
  const [row, setRow] = useState({});
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [groupSwitchLoading, setGroupSwitchLoading] = useState(false);
  const [showAddGroup, setShowAddGroup] = useState(false);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    loadGroups();
  }, []);

  async function loadGroups() {
    try {
      setLoading(true);
      const data = await groupHandler(props.service, user.current_org_id);
      setData(data.group);
      setColumns(data.columns);
      setLoading(false);
    } catch (err) {
      showToast("error", err.message);
    }
  }

  function actionClickHandler(e, row, key) {
    if (key === "edit") {
      history.push("/org/group/manage", {
        user,
        row,
        customTitle: row["name"]
      });
    } else {
      setRow(row);
      setOpen(true);
    }
  }

  async function rowClickHandler(e, row) {
    try {
      setGroupSwitchLoading(true);
      await groupSwitch(props.service, row.id);
      setGroupSwitchLoading(false);
      history.push("/group", { fromOrg: true });
    } catch (error) {
      setGroupSwitchLoading(false);
    }
  }

  async function handleDeleteGroup() {
    try {
      setLoading(true);
      setOpen(false);
      const res = await deleteGroup(props.service, user.current_org_id, row.id);
      showToast("success", res.message);
      loadGroups();
    } catch (err) {
      showToast("error", err.message);
      setLoading(false);
    }
  }

  async function handleAddGroup() {
    setShowAddGroup(false);
    loadGroups();
  }

  return (
    <>
      <Grid container justify="flex-end" className={classes.btnRoot}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={() => setShowAddGroup(true)}
        >
          Add Group
        </Button>
      </Grid>
      <div className={classes.root}>
        <Grid item xs={12} className={classes.table}>
          <Table
            loading={loading}
            onActionClick={actionClickHandler}
            onRowClick={rowClickHandler}
            columns={columns}
            data={data}
            options={{
              search: true,
              toolbar: true
            }}
            actions={[
              { tootlTip: "Edit", icon: "edit", key: "edit" },
              {
                tootlTip: "Delete",
                icon: "delete",
                key: "delete",
                color: "error"
              }
            ]}
          />
        </Grid>
        {open && (
          <Alert
            title="Confirm"
            content="Are you sure you want to delete this Group?"
            open={open}
            onConfirm={handleDeleteGroup}
            onClose={() => setOpen(false)}
          />
        )}
        {groupSwitchLoading && <GroupSwitchLoading />}
        {showAddGroup && (
          <Dialog
            open={showAddGroup}
            onClose={() => setShowAddGroup(false)}
            maxWidth="md"
            fullWidth
            classes={{
              paperWidthMd: classes.paperWidth
            }}
            disableBackdropClick
          >
            <AddGroups
              orgId={user.current_org_id}
              groups={data}
              onClose={() => setShowAddGroup(false)}
              onAddGroup={handleAddGroup}
            />
          </Dialog>
        )}
      </div>
    </>
  );
}

export default withMiddleware(Groups);
