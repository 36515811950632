import React, { useState, useEffect } from "react";
import Table from "../../../components/table";
import withMiddleware from "../../../middleware";
import _ from "lodash";
import { useStyles } from "./style";
import { getUserReadings } from "./handler";
import { Grid, Typography, Chip } from "@material-ui/core";
import { useUtils } from "../../../utils";

const columns = [
  {
    title: "HRV",
    width: 200,
    field: "rmssd",
    cellStyle: {
      fontSize: 16,
      fontWeight: "normal",
    },
  },
  {
    title: "Log Date",
    field: "date",
    width: 200,
    cellStyle: {
      fontSize: 16,
      fontWeight: "normal",
    },
  },
  {
    title: "Log Time",
    field: "time",
    width: 200,
    cellStyle: {
      fontSize: 16,
      fontWeight: "normal",
    },
  },
  {
    title: "Tags",
    field: "tags",
    cellStyle: {
      fontSize: 16,
      fontWeight: "normal",
    },
    render: (rowData) => {
      return (rowData.tags || []).map((tag) => (
        <Chip
          label={tag.tag_name}
          size="small"
          variant="outlined"
          color="primary"
          style={{ marginRight: 4, marginBottom: 4, fontSize: 14 }}
        />
      ));
    },
  },
];

function UserReadings(props) {
  const classes = useStyles();
  const { service, location } = props;
  const { showToast } = useUtils();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    try {
      setLoading(true);
      const user = location.state.user;
      const readings = await getUserReadings(service, user.user_id);
      setData(readings);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      showToast("error", err.message);
    }
  }

  return (
    <Grid className={classes.root}>
      <Table columns={columns} data={data} loading={loading} />
    </Grid>
  );
}

export default withMiddleware(UserReadings);
