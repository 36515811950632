// TODO: Deprecate this
export const getCustomAverages = async (
  service,
  id,
  fromDate,
  toDate,
  tags = [],
  type = "user"
) => {
  var qp = `type=${type}`;
  if (type === "group") {
    qp = qp + `&group_id=${id}`;
  } else {
    qp = qp + `&user_id=${id}`;
  }
  if (tags.length > 0) {
    const tagIds = tags.map((tag) => tag.id);
    qp = qp + "&tags=" + tagIds.join(",");
  }
  if (fromDate && toDate) {
    qp =
      qp +
      `&from=${fromDate.format("YYYY-MM-DD")}&to=${toDate.format(
        "YYYY-MM-DD"
      )}`;
  }
  // TODO: This API will be deprecated soon.
  const res = await service.get(`/aggregate/dashboard/custom?${qp}`);
  const avg = isNaN(res.rmssd) ? 0 : res.rmssd;
  return avg;
};

export const getTags = async (service, type = "user", id) => {
  return service.get(`/tags/specific?type=${type}&id=${id}`);
};
