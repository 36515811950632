import React from "react";

import { useStyles } from "./styles";
import { Grid, Typography, Button, Tooltip } from "@material-ui/core";
import EditIconImg from "../../assets/images/edit.png";
import clsx from "clsx";
import { useUtils } from "../../utils";

function AverageBox(props) {
  const { colors } = useUtils();
  const classes = useStyles(colors);
  const { data = {}, tags, enableChange = false, onEdit } = props;

  return (
    <>
      <Grid className={clsx(classes.boxStyle, enableChange && classes.boxStyle1)}>
        {enableChange && (
          <Button
            variant="text"
            onClick={() => {
              onEdit(true);
            }}
            className={classes.changeBtn}
          >
            <img src={EditIconImg} />
          </Button>
        )}
        <Typography variant="h5" className={classes.typo5}>
          {data.label || ""}
        </Typography>
        <Grid className={classes.hrvGridPaddedStyle} container>
          <Typography variant="h2" className={classes.typo1}>
            {data.rmssd ? data.rmssd : "N/A"}
          </Typography>
          {data.rmssd ? (
            <Typography variant="h4" className={classes.typo4}>
              {data.unit || ""}
            </Typography>
          ) : null}
        </Grid>

        <Typography variant="h4" className={`${classes.typo4} ${classes.typo6}`}>
          {data.footer || ""}
        </Typography>
        {tags && (
          <Tooltip title={tags}>
            <Typography className={classes.tagsTypo}>
              [
              <Typography noWrap className={classes.tagContentTypo}>
                {tags}
              </Typography>
              ]
            </Typography>
          </Tooltip>
        )}
      </Grid>
    </>
  );
}

export default AverageBox;
