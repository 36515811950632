import React from "react";
import { Card, Grid, Tabs, Tab } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useStyles } from "./style";
import withMiddleware from "../../../middleware";
import UsersAutocomplete from "./components/UsersAutocomplete";
import ExerciseTagsAutocomplete from "./components/ExerciseTagsAutocomplete";
// import ReportDateRange from "./components/ReportDateRange";
// import UsersAccordions from "./components/UsersAccordions";
import Heading from "./components/heading/index";
// import ReportsChart from "./components/chart";
import { UserContext } from "../../../context";
import ReportsHandlers from "./handler";
import AllUsersRMSSD from "./components/AllUsersRMSSD";
import UsersBioFeedbackHistory from "./components/UsersBioFeedbackHistory";
import UsersMindfullnessHistory from "./components/UsersMindfullnessHistory";

function Report(props) {
  const { service } = props;
  const handlers = React.useRef(null);
  const currentUser = React.useContext(UserContext);
  const orgId = currentUser.current_org_id;
  const classes = useStyles();
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [allUsers, setAllUsers] = React.useState([]);
  const [allTags, setAllTags] = React.useState([]);
  const [selectedUsersRMSSD, setSelectedUsersRMSSD] = React.useState([]);
  const [selectedUsersRmssdLoading, setSelectedUsersRmssdLoading] =
    React.useState(false);
  const [selectedUsersBioFeedbackHistory, setSelectedUsersBioFeedbackHistory] =
    React.useState([]);
  const [
    selectedUsersBioFeedbackHistoryLoading,
    setSelectedUsersBioFeedbackHistoryLoading,
  ] = React.useState(false);
  const [
    selectedUsersMindfullnessHistory,
    setSelectedUsersMindfullnessHistory,
  ] = React.useState([]);
  const [
    selectedUsersMindfullnessHistoryLoading,
    setSelectedUsersMindfullnessHistoryLoading,
  ] = React.useState(false);
  const startDate = "";
  const endDate = "";
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // use effects
  /**
   * For setting up api handlers with default values
   */
  React.useEffect(() => {
    handlers.current = new ReportsHandlers(service, orgId, startDate, endDate);
  }, [orgId, service, startDate, endDate]);

  /**
   * For getting all users
   */
  React.useEffect(() => {
    if (handlers.current) {
      (async () => {
        const users = await handlers.current.getUsersList();
        setAllUsers(users);
      })();
    }
    return () => {
      setAllUsers([]);
    };
  }, []);

  /**
   * For getting all tags
   */
  React.useEffect(() => {
    if (handlers.current) {
      (async () => {
        const tags = await handlers.current.getExerciseTagsList();
        setAllTags(tags);
      })();
    }
    return () => {
      setAllTags([]);
    };
  }, []);

  /**
   * For getting selected users rmssd
   */
  React.useEffect(() => {
    if (handlers.current && selectedUsers?.length) {
      (async () => {
        setSelectedUsersRmssdLoading(true);
        const rmssd = await handlers.current.getUsersRmssd(selectedUsers);
        setSelectedUsersRMSSD(rmssd);
        setSelectedUsersRmssdLoading(false);
      })();
    }
    return () => {
      setSelectedUsersRMSSD([]);
    };
  }, [selectedUsers]);

  /**
   * For getting selected users biofeedback history
   */
  React.useEffect(() => {
    if (handlers.current && selectedUsers?.length) {
      (async () => {
        setSelectedUsersBioFeedbackHistoryLoading(true);
        const biofeedbackhistroy =
          await handlers.current.getUsersBioFeedbackHistory(selectedUsers);
        setSelectedUsersBioFeedbackHistory(biofeedbackhistroy);
        setSelectedUsersBioFeedbackHistoryLoading(false);
      })();
    }
    return () => {
      setSelectedUsersBioFeedbackHistory([]);
    };
  }, [selectedUsers]);

  // For getting selected users mindfullness history
  React.useEffect(() => {
    if (handlers.current && selectedUsers?.length) {
      (async () => {
        setSelectedUsersMindfullnessHistoryLoading(true);
        const mindfullnesshistroy =
          await handlers.current.getUsersMindfullnessHistory(selectedUsers);
        setSelectedUsersMindfullnessHistory(mindfullnesshistroy);
        setSelectedUsersMindfullnessHistoryLoading(false);
      })();
    }
    return () => {
      setSelectedUsersMindfullnessHistory([]);
    };
  }, [selectedUsers]);

  return (
    <Grid className={classes.root}>
      <Grid container spacing={3}>
        {/* search box */}
        <Grid item xs={12}>
          <Heading heading="Reports Search" />
          <Card variant={"outlined"} square={false} className={classes.card}>
            <Grid container spacing={3}>
              {/* <Grid item xs={4}>
                <ReportDateRange />
              </Grid> */}
              <Grid item xs={7}>
                <UsersAutocomplete
                  allUsers={allUsers}
                  onUsersChange={setSelectedUsers}
                />
              </Grid>
              <Grid item xs={2}>
                <Button variant="contained" color="primary">
                  Apply
                </Button>
              </Grid>
              <Grid item xs={3}>
                <ExerciseTagsAutocomplete
                  allTags={allTags}
                  onTagsChange={setSelectedTags}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {selectedUsersRmssdLoading &&
          selectedUsersBioFeedbackHistoryLoading &&
          selectedUsersMindfullnessHistoryLoading && (
            <div className="loader-p">
              <div class="loader">Loading...</div>
            </div>
          )}
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Daily Readings History" />
          <Tab label="BioFeedback History" />
          <Tab label="Mindfullness History" />
        </Tabs>
        {value === 0 && (
          <AllUsersRMSSD
            selectedTags={selectedTags}
            usersRMSSD={selectedUsersRMSSD}
            card={classes.card}
          />
        )}
        {value === 1 && (
          <UsersBioFeedbackHistory
            selectedTags={selectedTags}
            usersBioFeedbackHistory={selectedUsersBioFeedbackHistory}
            card={classes.card}
            service={service}
          />
        )}
        {value === 2 && (
          <UsersMindfullnessHistory
            selectedTags={selectedTags}
            usersMindfullnessHistory={selectedUsersMindfullnessHistory}
            card={classes.card}
            service={service}
          />
        )}

        {/* {selectedUsers?.length ? (
          <Grid item xs={12}>
            <Heading heading="All users biofeedback reports" />
            <Grid container spacing={3}>
              <UsersAccordions users={selectedUsersRMSSD} accordionClassName={classes.card} />
            </Grid>
          </Grid>
        ) : null} */}
      </Grid>
    </Grid>
  );
}

export default withMiddleware(Report);
