import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  btnRoot: {
    position: "fixed",
    right: 24,
    top: 80,
    width: 200,
    zIndex: 12
  }
});
