import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 114,
    paddingTop: 80,
  },
  table: {
    padding: 24,
    overflow: "hidden",
    fontSize: 10,
  },
  usersAction: {
    textAlign: "right",
    marginBottom: 10,
  },
  spacer: {
    marginRight: 10,
  },
  bottomSpacer: {
    marginBottom: 15,
  },
  paddingSpacer: {
    paddingTop: 30,
  },
  dialogAction: {
    padding: "0 24px 24px 24px",
  },
  actionButton: {
    marginLeft: "24px !important",
  },
  dialogHeader: {
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
  },
  container: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    height: `calc(${window.innerHeight}px - 98px)`,
  },
  loader: {
    position: "absolute",
    background: "white",
    height: "calc(100% - 0px)",
    zIndex: 1500,
    opacity: 0.5,
    width: "100%",
    left: 0,
    top: 0,
  },
  error: {
    textAlign: "center",
    marginBottom: 15,
  },
  dialogSpacer: {
    padding: 16,
  },
  miniFieldBottom: {
    marginBottom: 22,
  },
  miniFieldGridBottom: {
    marginBottom: 10,
  },
  checkbox: { padding: "0 9px" },
}));
