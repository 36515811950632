import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceArea,
  Brush,
  Legend,
  Surface,
  Symbols,
} from "recharts";
import { Card, Grid } from "@material-ui/core";
import _ from "lodash";
import "./styles.css";
import { CircularProgress } from "@material-ui/core";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    // Function to map the dataKey back to the display name
    const displayName = (dataKey) => {
      if (dataKey.startsWith("user")) {
        return "Optimal Zone";
      }
      switch (dataKey) {
        case "rmssd":
          return "RMSSD";
        case "minMax":
          return "MaxMin";
        case "sdnn":
          return "SDNN";
        case "lf":
          return "LowFrequency";
        case "hf":
          return "HighFrequency";
        case "vlf":
          return "VeryLowFrequency";
        default:
          return dataKey;
      }
    };
    return (
      <div className="custom-tooltip">
        <span>{payload?.[0]?.payload?.tooltipText}</span>
        <br />
        <ul>
          <li>
            <p>Date: {payload?.[0]?.payload?.dateTime}</p>
          </li>
          {payload?.map((pd, key) => {
            const rKey = key;
            return (
              <li key={rKey} style={{ color: pd.color }}>
                <p className="label" key={rKey}>{`${displayName(
                  pd?.dataKey
                )} : ${pd?.value}`}</p>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  return null;
};

const CustomOzTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <span>{payload?.[0]?.payload?.tooltipText}</span>
        <br />
        <ul>
          <li>
            <p>Time: {payload?.[0]?.payload?.dateTime}</p>
          </li>
          <li>
            <p style={{ color: "#FF5733" }}>
              OptimalZone: {payload?.[0]?.payload?.optimalZone}
            </p>
          </li>
        </ul>
      </div>
    );
  }

  return null;
};

const legendNameToDataKey = {
  RMSSD: "rmssd",
  MaxMin: "minMax",
  SDNN: "sdnn",
  LowFrequency: "lf",
  HighFrequency: "hf",
  VeryLowFrequency: "vlf",
  // Add other mappings as necessary
};

const renderCusomizedLegend = ({ payload, setSettings }) => {
  const handleClick = ({ entry, index }) => {
    const newPayload = [...payload];
    newPayload[index] = entry;
    setSettings(newPayload);
  };
  return (
    <div className="customized-legend">
      {payload.map((entry, index) => {
        const { name, color, active } = entry;
        const style = {
          marginRight: 10,
          color: active ? "#AAA" : "#000",
        };

        return (
          <span
            className="legend-item"
            onClick={() =>
              handleClick({ entry: { ...entry, active: !active }, index })
            }
            style={style}
          >
            <Surface width={10} height={10} viewBox="0 0 10 10">
              <Symbols cx={5} cy={5} type="circle" size={80} fill={color} />
              {!active && (
                <Symbols cx={5} cy={5} type="circle" size={35} fill={"#FFF"} />
              )}
            </Surface>
            <span>{name}</span>
          </span>
        );
      })}
    </div>
  );
};

const colors = [
  "#32A834",
  "#0087a1",
  "#06D6A0",
  "#FFD166",
  "#5995ED",
  "#044389",
  "#FFAD05",
  "#2E5077",
  "#D7E8BA",
  "#611C35",
  "#EF476F",
  "#118AB2",
];
const getRandomColor = (index) => {
  return (
    colors?.[index] || `#${Math.floor(Math.random() * 16777215).toString(16)}`
  );
};

function Mindfullness(props) {
  const {
    users = [],
    type,
    data = [],
    selected,
    results = [],
    ozRawData,
    isLoading,
  } = props;
  let modifiedData = [...data];
  const [toolText, setToolText] = React.useState("");
  const [modifiedOzData, setModifiedOzData] = useState(null);

  if (selected !== undefined && selected !== "") {
    let nonEmptyArray = results.find((arr) => arr.length > 0);
    modifiedData = nonEmptyArray.map((result, index) => ({
      ...result,
      sessionName: `Minute ${index + 1}`,
    }));
    if (nonEmptyArray?.tooltipText !== toolText) {
      setToolText(nonEmptyArray?.tooltipText);
    }
  } else {
    modifiedData = modifiedData
      .flatMap((innerArray) => innerArray)
      .sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime));
  }

  useEffect(() => {
    if (ozRawData !== null && toolText !== "") {
      const updatedOzRawData = ozRawData.map((entry) => ({
        ...entry,
        tooltipText: toolText || "Session Data",
      }));
      setModifiedOzData(updatedOzRawData);
    }
  }, [toolText, ozRawData]);

  const optimalZoneData = data.map((innerArray, index) =>
    innerArray
      ?.filter((entry) => entry.optimalZone !== null)
      .map((entry) => {
        const obj = {
          dateTime: entry.dateTime,
          tooltipText: entry.tooltipText.replace("Mindfullness", ""),
        };
        obj[`user${index + 1}`] = entry.optimalZone;
        return obj;
      })
  );

  const legendNameForOz = {};
  optimalZoneData.forEach((innerArray, index) => {
    innerArray.forEach((entry) => {
      legendNameForOz[entry.tooltipText] = `user${index + 1}`;
    });
  });

  const legendColors = ["#FF5733", "#3366FF", "#33FF33", "#FF33FF", "#FFFF33"];
  const initialLegendPayload = Object.keys(legendNameForOz).map(
    (tooltipText, index) => ({
      value: legendNameForOz[tooltipText],
      type: "line",
      id: legendNameForOz[tooltipText],
      color: legendColors[index % legendColors.length],
      name: tooltipText,
      active: true,
    })
  );
  const [legendPayload, setLegendPayload] = useState(initialLegendPayload);

  const flattenedAndSortedOzData = optimalZoneData
    .flatMap((innerArray) => innerArray)
    .sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime));

  const optimalZoneLegend = [
    { name: "Optimal Zone", color: "#FF5733", active: true },
  ];
  const xDataKey =
    selected !== undefined && selected !== "" ? "sessionName" : "dateTime";
  console.log({ selected });
  const OptimalZoneLineChart = (
    <>
      <strong style={{ display: "flex", justifyContent: "center" }}>
        {" "}
        Optimal Zone{" "}
      </strong>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {selected ? (
            <>
              {ozRawData ? (
                <ResponsiveContainer key="ozChart" width="100%" height={300}>
                  <LineChart data={modifiedOzData}>
                    <CartesianGrid
                      strokeDasharray="3 3"
                      stroke="rgba(0,0,0,0.2)"
                    />
                    <XAxis stroke="rgba(0,0,0,0.4)" dataKey={xDataKey} />
                    <YAxis stroke="rgba(0,0,0,0.4)" domain={[0, 100]} />
                    <Tooltip
                      content={
                        <CustomOzTooltip cursor={false} toolTipLabel={type} />
                      }
                    />
                    <Legend
                      verticalAlign="bottom"
                      height={36}
                      align="center"
                      payload={optimalZoneLegend}
                      content={renderCusomizedLegend}
                    />
                    <ReferenceArea
                      y1={80}
                      y2={100}
                      strokeOpacity={0.3}
                      fill="green"
                    />
                    {optimalZoneLegend.map((legend, index) => {
                      if (!legend.active) {
                        return null;
                      }
                      return (
                        <Line
                          key={index}
                          dataKey="optimalZone"
                          type="monotone"
                          stroke={legend.color}
                          activeDot={{ r: 8 }}
                          strokeWidth={3}
                          dot={false}
                          connectNulls
                        />
                      );
                    })}
                    <Brush
                      dataKey="xAxis"
                      height={40}
                      stroke="rgb(0, 135, 161, 1)"
                    />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Optimal Zone data was not recorded because it was
                  disabled/OFF, make sure you have Optimal Zone enabled/ON
                  before taking session
                </div>
              )}
            </>
          ) : (
            <ResponsiveContainer
              key="optimalZoneChart"
              width="100%"
              height={300}
            >
              <LineChart data={flattenedAndSortedOzData}>
                <CartesianGrid strokeDasharray="3 3" stroke="rgba(0,0,0,0.2)" />
                <XAxis stroke="rgba(0,0,0,0.4)" dataKey={xDataKey} />
                <YAxis stroke="rgba(0,0,0,0.4)" domain={[0, 100]} />
                <Tooltip
                  content={<CustomTooltip cursor={false} toolTipLabel={type} />}
                />
                <Legend
                  verticalAlign="bottom"
                  height={36}
                  align="center"
                  payload={legendPayload}
                  setSettings={setLegendPayload}
                  content={renderCusomizedLegend}
                />
                {selected !== undefined ? (
                  <ReferenceArea
                    y1={80}
                    y2={100}
                    strokeOpacity={0.3}
                    fill="green"
                  />
                ) : null}
                {legendPayload.map((legend, index) => {
                  if (!legend.active) {
                    return null;
                  }
                  const dataKey = legendNameForOz[legend.name] || legend.name;
                  return (
                    <Line
                      key={index}
                      dataKey={dataKey}
                      type="monotone"
                      stroke={legend.color}
                      activeDot={{ r: 8 }}
                      strokeWidth={3}
                      dot={true}
                      connectNulls
                    />
                  );
                })}
                <Brush
                  dataKey="xAxis"
                  height={40}
                  stroke="rgb(0, 135, 161, 1)"
                />
              </LineChart>
            </ResponsiveContainer>
          )}
        </>
      )}
    </>
  );

  const [legendLineChart, setLegendLineChart] = useState([
    /* { name: "meanNni", color: getRandomColor(0), active: true },
    { name: "medianNni", color: getRandomColor(1), active: true },
    { name: "meanHr", color: getRandomColor(2), active: true },
    { name: "maxHr", color: getRandomColor(3), active: true },
    { name: "minHr", color: getRandomColor(4), active: true }, */
    { name: "RMSSD", color: "#FF0000", active: true },
    { name: "MaxMin", color: getRandomColor(1), active: true },
    { name: "SDNN", color: getRandomColor(2), active: true },
  ]);

  const [legendLineChartAll, setLegendLineChartAll] = useState([
    { name: "RMSSD", color: "#FF0000", active: true },
    { name: "MaxMin", color: getRandomColor(1), active: true },
    { name: "SDNN", color: getRandomColor(2), active: true },
  ]);

  const [legendBarChart, setLegendBarChart] = useState([
    { name: "LowFrequency", color: getRandomColor(0), active: true },
    { name: "HighFrequency", color: getRandomColor(1), active: true },
    { name: "VeryLowFrequency", color: getRandomColor(2), active: true },
    /* { name: 'lfnu', color: getRandomColor(3), active: true},
    { name: 'hfnu', color: getRandomColor(4), active: true}, */
  ]);

  const [legendBarChartAll, setLegendBarChartAll] = useState([
    { name: "LowFrequency", color: getRandomColor(0), active: true },
    { name: "HighFrequency", color: getRandomColor(1), active: true },
    { name: "VeryLowFrequency", color: getRandomColor(2), active: true },
  ]);

  return (
    <>
      {OptimalZoneLineChart}
      {selected !== undefined && selected !== "" ? (
        <>
          <Grid container xs={12} flexDirection="row">
            <Grid item xs={6}>
              <strong style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                Frequency Domain{" "}
              </strong>
            </Grid>
            <Grid item xs={3}>
              <strong style={{ display: "flex", justifyContent: "left" }}>
                Session Length: {data[0].sessionMinutes}{" "}
              </strong>
              <strong style={{ display: "flex", justifyContent: "left" }}>
                Avg. LF: {data[0].lf}
              </strong>
            </Grid>
            <Grid item xs={3}>
              <strong style={{ display: "flex", justifyContent: "center" }}>
                Avg. HF: {data[0].hf}{" "}
              </strong>
              <strong style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                Avg. VLF: {data[0].vlf}{" "}
              </strong>
            </Grid>
          </Grid>
        </>
      ) : (
        <strong style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          Frequency Domain{" "}
        </strong>
      )}

      <div style={{ width: "100%", height: 450, position: "relative" }}>
        {!selected ? (
          <ResponsiveContainer>
            <BarChart data={modifiedData}>
              <CartesianGrid strokeDasharray="3 3" stroke="rgba(0,0,0,0.2)" />
              <XAxis stroke="rgba(0,0,0,0.4)" dataKey={xDataKey} />
              <YAxis />
              <Tooltip
                content={<CustomTooltip cursor={false} toolTipLabel={type} />}
              />
              <Legend
                verticalAlign="bottom"
                height={36}
                align="center"
                payload={legendBarChartAll}
                setSettings={setLegendBarChartAll}
                content={renderCusomizedLegend}
              />
              {legendBarChartAll.map((legend, index) => {
                if (!legend.active) {
                  return null;
                }
                const dataKey = legendNameToDataKey[legend.name] || legend.name; // Fallback to legend.name if no mapping found
                return (
                  <Bar key={index} dataKey={dataKey} fill={legend.color} />
                );
              })}
              <Brush dataKey="xAxis" height={40} stroke="rgb(0, 135, 161, 1)" />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <ResponsiveContainer>
            <BarChart data={modifiedData}>
              <CartesianGrid strokeDasharray="3 3" stroke="rgba(0,0,0,0.2)" />
              <XAxis stroke="rgba(0,0,0,0.4)" dataKey={xDataKey} />
              <YAxis />
              <Tooltip
                content={<CustomTooltip cursor={false} toolTipLabel={type} />}
              />
              <Legend
                verticalAlign="bottom"
                height={36}
                align="center"
                payload={legendBarChart}
                setSettings={setLegendBarChart}
                content={renderCusomizedLegend}
              />
              {legendBarChart.map((legend, index) => {
                if (!legend.active) {
                  return null;
                }
                const dataKey = legendNameToDataKey[legend.name] || legend.name; // Fallback to legend.name if no mapping found
                return (
                  <Bar key={index} dataKey={dataKey} fill={legend.color} />
                );
              })}
              <Brush dataKey="xAxis" height={40} stroke="rgb(0, 135, 161, 1)" />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
      {selected !== undefined && selected !== "" ? (
        <>
          <Grid container xs={12} flexDirection="row">
            <Grid item xs={6}>
              <strong style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                Time Domain{" "}
              </strong>
            </Grid>
            <Grid item xs={3}>
              <strong style={{ display: "flex", justifyContent: "left" }}>
                Session Length: {data[0].sessionMinutes}{" "}
              </strong>
              <strong style={{ display: "flex", justifyContent: "left" }}>
                Breaths Per Minute: {data[0].breathsPerMinute}
              </strong>
            </Grid>
            <Grid item xs={3}>
              <strong style={{ display: "flex", justifyContent: "center" }}>
                Inhale Seconds: {data[0].inhaleSeconds}{" "}
              </strong>
              <strong style={{ display: "flex", justifyContent: "center" }}>
                Exhale Seconds: {data[0].exhaleSeconds}{" "}
              </strong>
            </Grid>
          </Grid>
        </>
      ) : (
        <strong style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          Time Domain{" "}
        </strong>
      )}
      <div style={{ width: "100%", height: 450, position: "relative" }}>
        {!selected ? (
          <ResponsiveContainer>
            <LineChart data={modifiedData}>
              <CartesianGrid strokeDasharray="3 3" stroke="rgba(0,0,0,0.2)" />
              <XAxis stroke="rgba(0,0,0,0.4)" dataKey={xDataKey} />
              <YAxis stroke="rgba(0,0,0,0.4)" />
              <Tooltip
                content={<CustomTooltip cursor={false} toolTipLabel={type} />}
              />
              <Legend
                verticalAlign="bottom"
                height={36}
                align="center"
                payload={legendLineChartAll}
                setSettings={setLegendLineChartAll}
                content={renderCusomizedLegend}
              />
              {legendLineChartAll.map((legend, index) => {
                if (!legend.active) {
                  return null;
                }
                const dataKey = legendNameToDataKey[legend.name] || legend.name; // Fallback to legend.name if no mapping found
                return (
                  <Line
                    key={index}
                    dataKey={dataKey}
                    stroke={legend.color}
                    activeDot={{ r: 8 }}
                    strokeWidth={3}
                    dot={true}
                    connectNulls
                  />
                );
              })}
              <Brush dataKey="xAxis" height={40} stroke="rgb(0, 135, 161, 1)" />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <ResponsiveContainer>
            <LineChart data={modifiedData}>
              <CartesianGrid strokeDasharray="3 3" stroke="rgba(0,0,0,0.2)" />
              <XAxis stroke="rgba(0,0,0,0.4)" dataKey={xDataKey} />
              <YAxis stroke="rgba(0,0,0,0.4)" />
              <Tooltip
                content={<CustomTooltip cursor={false} toolTipLabel={type} />}
              />
              <Legend
                verticalAlign="bottom"
                height={36}
                align="center"
                payload={legendLineChart}
                setSettings={setLegendLineChart}
                content={renderCusomizedLegend}
              />
              {legendLineChart.map((legend, index) => {
                if (!legend.active) {
                  return null;
                }
                const dataKey = legendNameToDataKey[legend.name] || legend.name; // Fallback to legend.name if no mapping found
                return (
                  <Line
                    key={index}
                    dataKey={dataKey}
                    stroke={legend.color}
                    activeDot={{ r: 8 }}
                    strokeWidth={3}
                    dot={true}
                    connectNulls
                  />
                );
              })}
              <Brush dataKey="xAxis" height={40} stroke="rgb(0, 135, 161, 1)" />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </>
  );
}

export default React.memo(Mindfullness);
