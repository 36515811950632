export const getParticipants = async (service, groupId, orgId) => {
  const res = await service.get(
    `/group/participants?rmssd=false&groupId=${groupId}&orgId=${orgId}`
  );
  const data = organizeData(res);
  return data;
};

export const getOptions = async (service, keyword, groupId, orgId) => {
  const options = await service.getAutoSuggestions(
    `/group/users?keyword=${keyword}&orgId=${orgId}&groupId=${groupId}`
  );
  return options;
};
export const addParticipants = async (service, data) => {
  const res = await service.put(`/group/participants`, data);
  return res;
};

export const removeParticipant = async (service, userId, groupId, orgId) => {
  const res = await service.delete(
    `/group/participants?userId=${userId}&groupId=${groupId}&orgId=${orgId}`
  );
  return res;
};

const organizeData = users => {
  const data = users.map(user => ({
    ...user,
    name: (user.first_name || "") + " " + (user.last_name || "")
  }));
  return data;
};
