import OrgIcon from "../../../assets/images/org.png";
import ActivityIcon from "../../../assets/images/activity.png";

export const menus = [
  {
    id: "organizations",
    title: "Organizations",
    icon: OrgIcon,
    path: "/admin",
  },
  /* {
    id: "activity",
    title: "Activity",
    icon: ActivityIcon,
    path: "/admin/activity"
  } */
];
