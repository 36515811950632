import React from "react";
import GroupLogo from "../../../../assets/images/groups.png";
import OrgLogo from "../../../../assets/images/org.png";
import SpinnerLoader from "react-loader-spinner";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "../style";

export default function SwitchGroupLoading() {
  const classes = useStyles();
  return (
    <Dialog
      open={true}
      classes={{
        paper: classes.paper,
      }}
    >
      <Grid container style={{ padding: 24 }}>
        <Grid className={classes.imgContainer}>
          <img src={OrgLogo} className={classes.img} />
        </Grid>
        <Grid className={classes.loader}>
          <SpinnerLoader type="ThreeDots" color="#fff" height={80} width={60} />
        </Grid>
        <Grid className={classes.imgContainerGroup}>
          <img src={GroupLogo} className={classes.img} />
        </Grid>
      </Grid>
    </Dialog>
  );
}
