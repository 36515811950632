import React, { useState } from "react";
import {
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField
} from "@material-ui/core";

import { useStyles } from "../style";
import { useUtils } from "../../../../../utils";

function Search(props) {
  const { onSearch } = props;
  const { colors } = useUtils();
  const classes = useStyles(colors);
  const [orgType, setOrgType] = useState("current");
  const [keyword, setKeyword] = useState("");

  function handleSeachChange(e) {
    setKeyword(e.target.value);
    handleSearch(orgType, e.target.value);
  }

  function handleTypeChange(e) {
    setOrgType(e.target.value);
    handleSearch(e.target.value, keyword);
  }

  function handleSearch(type, value) {
    onSearch(type, value);
  }
  return (
    <Grid container style={{ paddingBottom: 12 }}>
      <Grid item xs auto="true">
        <TextField
          variant="outlined"
          placeholder="Search User (Min 3 chars)"
          InputProps={{
            classes: {
              input: classes.input
            }
          }}
          style={{ paddingRight: 24 }}
          onChange={handleSeachChange}
          fullWidth
        />
      </Grid>
      <Grid item>
        <RadioGroup
          name="Current"
          row
          value={orgType}
          onChange={handleTypeChange}
        >
          <FormControlLabel
            value="current"
            control={<Radio color="primary" size="small" />}
            label={
              <Typography variant="body2">Current Organization</Typography>
            }
            labelPlacement="end"
          />
          <FormControlLabel
            value="all"
            control={<Radio color="primary" size="small" />}
            label={<Typography variant="body2">All Organizations</Typography>}
            labelPlacement="end"
          />
        </RadioGroup>
      </Grid>
    </Grid>
  );
}

export default Search;
