import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 80,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center"
    },
    searchContainer: {
        textAlign: 'center',
        background: '#ffffff',
        border: "#e4e8eb solid 2px",
        borderRadius: 12,
        overflow: "hidden",
        minHeight: 300,
        marginTop: 16
    },
    importMainGrid: {
        padding: "36px 44px 36px 44px"
    },
    center: {
        textAlign: "center"
    },
    uploadGrid: {
        paddingTop: 24,
        paddingBottom: 24
    },
    uploadImgGrid: {
        paddingTop: 35
    },
    addUserGrid: {
        paddingTop: 15,
        cursor: "pointer"
    },
    iconGrid: {
        paddingTop: 15
    },
    addUserIconGrid: {
        borderRadius: 8,
        border: "#e4e8eb solid 2px",
        padding: 10
    },
    fileContainer: {
        cursor: "pointer",
        background: "#fd5b6d",
        color: "#fff",
        padding: "7px 40px",
        fontSize: "1rem",
        minWidth: "64px",
        boxShadow: "none !important",
        boxSizing: "border-box",
        fontFamily: "Gotham Rounded, sans-serif",
        fontWeight: 500,
        lineHeight: 1.75,
        borderRadius: 30,
        textTransform: "uppercase"
    },
}))
