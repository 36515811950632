import React, { useState } from "react";
import {
  Grid,
  Button,
  Dialog,
  TextField,
  DialogActions,
  FormControl,
  InputAdornment,
  DialogContent,
  DialogTitle,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/VisibilityOutlined";
import VisibilityOff from "@material-ui/icons/VisibilityOffOutlined";
import { useStyles } from "../style";
import { validationInput } from "./validation";
import { useValidation } from "../../../../components/validation";
import SelectComponent from "../../../../components/select";
import Calendar from "../../../../components/calendar";

function AddUser(props) {
  const classes = useStyles();
  const { validation, validate, autoValidate, isValid } = useValidation(
    validationInput
  );
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    gender: "",
    dob: null,
  });
  const [showPassword, setShowPassword] = useState(false);
  const { openAddUser, closeHandler, addHandler, currentUser, loader } = props;

  function handleAdd() {
    autoValidate(true);
    if (isValid) {
      addHandler(user);
    }
  }

  function handleInputChange(field, value) {
    setUser({ ...user, [field]: value });
    validate(validation[field], value);
  }

  function handleDobChange(field, value) {
    setUser({ ...user, [field]: value });
    validate(validation[field], value.format("YYYY-MM-DD"));
  }

  return (
    <div className={classes.root}>
      {loader && (
        <Grid container className={classes.loader} justify="center" alignItems="center" alignContent="center">
          <CircularProgress />
        </Grid>
      )}
      <Dialog open={openAddUser} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth classes={classes.dialog}>
        <DialogTitle id="form-dialog-title" className={classes.dialogHeader}>
          Add User
        </DialogTitle>
        <DialogContent className={classes.paddingSpacer}>
          <FormControl fullWidth>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  id="firstname"
                  label="First name"
                  variant="outlined"
                  inputProps={{ maxLength: "100" }}
                  className={classes.miniFieldBottom}
                  type="text"
                  fullWidth
                  size="small"
                  error={!validation.firstName.isValid}
                  helperText={validation.firstName.errMessage}
                  onBlur={(e) => validate(validation["firstName"], e.target.value)}
                  onChange={(e) => handleInputChange("firstName", e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="lastname"
                  label="Last name"
                  variant="outlined"
                  className={classes.miniFieldBottom}
                  inputProps={{ maxLength: "100" }}
                  type="text"
                  size="small"
                  fullWidth
                  error={!validation.lastName.isValid}
                  helperText={validation.lastName.errMessage}
                  onBlur={(e) => validate(validation["lastName"], e.target.value)}
                  onChange={(e) => handleInputChange("lastName", e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.miniFieldGridBottom}>
              <Grid item xs={6}>
                <Calendar
                  label="DOB"
                  name="dob"
                  position="right"
                  fullWidth
                  value={user.dob}
                  error={!validation.dob.isValid}
                  helperText={validation.dob.errMessage}
                  otherProps={{ maxDate: new Date() }}
                  onChange={(date) => {
                    handleDobChange("dob", date);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectComponent
                  value={user.gender}
                  label="Gender"
                  options={[
                    {
                      id: "Male",
                      name: "Male",
                    },
                    {
                      id: "Female",
                      name: "Female",
                    },
                    {
                      id: "Others",
                      name: "Others",
                    },
                  ]}
                  onChange={(e) => handleInputChange("gender", e.target.value)}
                />
              </Grid>
            </Grid>

            <TextField
              label="Email"
              variant="outlined"
              className={classes.miniFieldBottom}
              type="text"
              size="small"
              // InputProps={{
              //   endAdornment: (
              //     <InputAdornment position="end">
              //       {currentUser.domainname ? `@${currentUser.domainname}` : ""}
              //     </InputAdornment>
              //   ),
              // }}
              inputProps={{
                maxLength: "50",
              }}
              fullWidth
              error={!validation.username.isValid}
              helperText={validation.username.errMessage}
              onBlur={(e) => validate(validation["username"], e.target.value)}
              onChange={(e) => handleInputChange("username", e.target.value)}
            />
            <TextField
              id="password"
              label="Password"
              variant="outlined"
              size="small"
              fullWidth
              className={classes.miniFieldGridBottom}
              type={showPassword ? "text" : "password"}
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>{showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                  </InputAdornment>
                ),
              }}
              error={!validation.password.isValid}
              helperText={validation.password.errMessage}
              onBlur={(e) => validate(validation["password"], e.target.value)}
              onChange={(e) => handleInputChange("password", e.target.value)}
            />
          </FormControl>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button onClick={handleAdd} variant="contained" color="primary" disabled={!isValid}>
            Add
          </Button>
          <Button onClick={() => closeHandler(false)} variant="contained" className={classes.actionButton}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddUser;
