import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

function ProtectedRoute({ component: Component, ...rest }) {
  const { auth } = useSelector(state => ({
    auth: state.auth
  }));
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isVerifying || (rest.path === "/admin" && auth?.user?.email !== "admin@optimalhrv.com") ? (
          <div />
        ) : auth.isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        )
      }
    />
  );
}
export default ProtectedRoute;
