import React from "react";

export const UserIcon = props => (
  <div style={{ width: props.size || 32, height: props.size || 32 }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Capa_1"
      enableBackground="new 0 0 515.556 515.556"
      height={props.size || "32px"}
      viewBox="0 0 515.556 515.556"
      width={props.size || "32px"}
    >
      <g>
        <path
          d="m257.778 0c-142.137 0-257.778 115.641-257.778 257.778s115.641 257.778 257.778 257.778 257.778-115.641 257.778-257.778-115.642-257.778-257.778-257.778zm140.412 390.282c-88.007-44.093-194.425-45.965-284.592-4.146-30.464-34.181-49.153-79.073-49.153-128.358 0-106.61 86.723-193.333 193.333-193.333s193.333 86.723 193.333 193.333c0 51.296-20.213 97.861-52.921 132.504z"
          data-original="#000000"
          data-old_color="#000000"
          fill={props.color || "#FD5B6D"}
        />
        <path
          d="m326.132 157.202c37.751 37.751 37.751 98.957 0 136.707s-98.957 37.751-136.707 0-37.751-98.957 0-136.707 98.956-37.751 136.707 0"
          data-original="#000000"
          data-old_color="#000000"
          fill={props.color || "#FD5B6D"}
        />
      </g>
    </svg>
  </div>
);
