import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";
import { colors } from "../../utils/theme";

function Chart(props) {
  const { data } = props;
  const [yValues, setYValues] = useState([]);
  const [yMax, setYMax] = useState(70);

  useEffect(() => {
    if (data && data.length > 1) {
      setYValues([]);
      let values = _.clone(data);
      const maxY = _.maxBy(values, "rmssd");
      if (maxY) {
        setYMax(maxY.rmssd || 70);
      }
      const yValue = values.map((value) => value.rmssd || 0);
      setYValues(yValue);
    }
  }, [data]);

  const options = {
    chart: {
      type: "column",
      height: 336,
      animation: false,
    },

    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    legend: {
      enabled: true,
    },
    xAxis: {
      categories: [
        "Latest Reading",
        "Previous Reading",
        "7-Day Average",
        "30-Day Average",
        "All-Time Average",
        "Population Norm",
      ],
      crosshair: false,
      gridLineWidth: 0,
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
    yAxis: {
      min: 0,
      max: yMax,
      tickInterval: 10,
      gridLineWidth: 0,
      title: {
        text: "",
      },
      color: "#ffc107",
      lineWidth: 1,
      labels: {
        style: {
          fontSize: "10px",
        },
      },
    },
    tooltip: {
      enabled: false,
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
      },
      series: {
        animation: false,
      },
    },
    series: [
      {
        showInLegend: false,
        color: colors.hrv_graphs,
        data: yValues,
      },
    ],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      oneToOne={true}
    />
  );
}

export default Chart;
