import _ from "lodash";

export const getOrgs = async service => {
  const orgs = await service.get(`/switch/orgs?role=org`);
  const sortedOrg = _.sortBy(orgs, ["name"]);
  return sortedOrg;
};

export const setCurrentOrg = async (service, body) => {
  const res = await service.put(`/user/profile`, body);
  return res;
};
