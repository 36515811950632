import { makeStyles } from "@material-ui/core/styles";
// import bgImage from "../../../assets/images/signin-background-image.png";

export const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      // backgroundImage: `url(${bgImage})`,
      // backgroundRepeat: "repeat-x",
      backgroundColor: colors => colors.hrv_light_gray
    }
  },
  loginContainer: {
    position: "relative",
    textAlign: "center",
    paddingTop: 100,
    paddingBottom: 20,
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: "30%",
      backgroundColor: (colors) => colors.hrv_primary,
      zIndex: -1,
    },
  },
  title: {
    fontSize: 24,
    color: colors => colors.hrv_white,
    fontWeight: 700,
    paddingBottom: 24
  },
  loginBody: {
    width: 540,
    maxWidth: "100%",
    backgroundColor: (colors) => colors.hrv_white,
    borderColor: (colors) => colors.hrv_border_color,
    borderWidth: "2px",
    borderRadius: 16,
    borderStyle: "solid",
    display: "block",
    position: "relative",
    margin: "0px auto 0 auto",
    padding: 45
  },
  input: {
    fontSize: 22,
    height: 40,
    fontWeight: 500,
    padding: "16px 0 0 0"
  },
  label: {
    top: 18
  },
  error: {
    paddingBottom: 24,
    textAlign: "center"
  },
  link: {
    cursor: "pointer",
    fontWeight: 500,
    fontSize: 14
  },
  logo: {
    height: 50
  },
  loader: {
    position: "absolute",
    background: "white",
    height: "calc(100% - 90px)",
    zIndex: 2,
    opacity: 0.5,
    width: "100%",
    left: 0
  },
  footer: {
    paddingTop: 16
  }
}));
