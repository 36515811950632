import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  button: {
    padding: "6px 16px 4px 16px",
    fontSize: 12,
    fontWeight: 600
  },
  dialogRemove: { padding: 10 },
  contentRoot: {
    padding: "0 24px 16px"
  }
});
