import React, { useEffect, useState, useContext, useReducer } from "react";
import { Select, MenuItem, Dialog, Grid } from "@material-ui/core";
import { useStyles } from "./style";
import withMiddleware from "../../../middleware";
import { getOrgs, setCurrentOrg } from "./handler";
import { UserContext } from "../../../context";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import Org from "../../../assets/images/org.png";
import SpinnerLoader from "react-loader-spinner";

function OrgSwitch(props) {
  const classes = useStyles();
  const { service } = props;
  const history = useHistory();
  const user = useContext(UserContext);
  const [orgs, setOrgs] = useState([]);
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const activeOrg = _.find(orgs, (org) => org.id === user.current_org_id);
    if (activeOrg) {
      setValue(activeOrg.id.toString());
    }
  }, [orgs]);

  async function init() {
    try {
      setOrgs(await getOrgs(service));
    } catch (err) {
      console.log(err);
    }
  }

  async function orgChangeHandler(e) {
    try {
      setOpen(true);
      setValue(e.target.value);
      const currentOrgId = e.target.value;
      await setCurrentOrg(service, { currentOrgId });
      history.push("/org", {
        orgId: e.target.value,
        user: "Dashboard ",
      });
      setOpen(false);
    } catch (err) {
      console.log(err);
      setOpen(false);
    }
  }
  return (
    <>
      <Select
        value={value}
        onChange={orgChangeHandler}
        variant="outlined"
        disabled={orgs.length === 0}
        color="primary"
        classes={{
          outlined: classes.root,
        }}
        MenuProps={{
          classes: {
            paper: classes.selectPaper,
          },
        }}
      >
        {orgs.map((org, index) => (
          <MenuItem
            key={index}
            value={org.id.toString()}
            disabled={org.id.toString() === value}
          >
            {org.name}
          </MenuItem>
        ))}
      </Select>
      <Dialog
        open={open}
        classes={{
          paper: classes.paper,
        }}
      >
        <Grid container style={{ padding: 24 }}>
          <Grid className={classes.imgContainer}>
            <img src={Org} className={classes.img} />
          </Grid>
          <Grid className={classes.loader}>
            <SpinnerLoader
              type="ThreeDots"
              color="#fff"
              height={80}
              width={60}
            />
          </Grid>
          <Grid className={classes.imgContainer}>
            <img src={Org} className={classes.img} />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}

export default withMiddleware(OrgSwitch);
