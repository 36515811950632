import React, { useEffect, useState } from "react";
import ProtectedRoute from "../../components/protected-route";
import { ThemeProvider } from "@material-ui/core/styles";
import Dashboard from "./dashboard";
import Drawer from "../../components/drawer";
import { adminTheme } from "../../utils/theme/admin";
import { menus } from "./menus";

import Header from "../../components/header";
import { useRouteMatch } from "react-router-dom";
import { logoutUser } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { UserContext } from "../../context";
import withMiddleware from "../../middleware";
import { getUser, getAttributes } from "./handler";
import { updateUser } from "../../actions/app";
import Organisation from "./organisation";
import Activity from "./activity";

function AdminHome(props) {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  const { user } = useSelector(state => ({
    user: state.app.user
  }));

  const { location, history, service } = props;
  const options = [
    {
      id: 1,
      label: "Sign out"
    }
  ];

  useEffect(() => {
    reFetchUser();
  }, [location.state]);

  async function reFetchUser() {
    const user = await getUser(service);
    dispatch(updateUser(user));
  }

  function signOut(id) {
    dispatch(logoutUser());
  }

  return (
    <ThemeProvider theme={adminTheme}>
      {user ? (
        <UserContext.Provider value={user}>
          <Drawer menuItems={menus} onChangeMenu={() => {}} />
          <Header
            attr={getAttributes(location)}
            options={options}
            history={history}
            onMenuClick={id => signOut(id)}
          />
          <ProtectedRoute exact path="/admin" component={Dashboard} />
          <ProtectedRoute
            exact
            path="/admin/organization"
            component={Organisation}
          />
          <ProtectedRoute exact path="/admin/activity" component={Activity} />
        </UserContext.Provider>
      ) : (
        <div />
      )}
    </ThemeProvider>
  );
}

export default withMiddleware(AdminHome);
