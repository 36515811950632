export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";

export const updateUser = user => {
  return {
    type: UPDATE_USER,
    user
  };
};

export const updateNotification = notification => {
  return {
    type: UPDATE_NOTIFICATION,
    notification
  };
};
