export const validationInput = [
  {
    key: "changePassword",
    type: "text"
  },
  {
    key: "changePasswordConfirm",
    type: "text"
  }
];
