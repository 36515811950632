import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: {
    padding: "74px 24px 24px 138px"
  },
  dateTypo: {
    fontSize: 14,
    paddingTop: 24,
    paddingBottom: 16,
    fontWeight: 600
  },
  iconStyle: {
    height: 20,
    paddingRight: 24
  },
  msgDefaultGrid: {
    paddingTop: 16,
    paddingBottom: 16
  },
  childMsgGrid: {
    padding: "12px 0"
  },
  msg: {
    background: "#fff",
    padding: 16,
    margin: "12px 36px",
    border: `#e4e8eb solid 3px`,
    borderRadius: 10,
    fontWeight: 700,
    fontSize: 12,
    alignItems: "center",
    justify: "center"
  },
  container: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    height: `calc(${window.innerHeight}px - 98px)`
  },
  time: { fontSize: 14, paddingRight: 12 }
});
