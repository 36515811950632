import firebase from "firebase";
import { config } from "../../firebase-config";

const getApp = () => {
    if(firebase.apps.length){
        return firebase.apps[0]
    }
    return firebase.initializeApp(config);
}
export const firebaseApp = getApp();
