import { createTheme } from "@material-ui/core/styles";
import { baseTheme } from "../index";
// import { grey } from "@material-ui/core/colors";
import { colors as organizationColors } from "../index";

export const colors = {
  ...organizationColors,
};

export const groupTheme = createTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    primary: { main: colors.hrv_primary },
    secondary: { main: colors.hrv_black },
    info: {
      main: colors.hrv_info_main,
      light: colors.hrv_info_light,
      dark: colors.hrv_info_dark,
    },
  },
  overrides: {
    ...baseTheme.overrides,
    MuiInputBase: {
      input: {
        height: 24,
        // fontWeight:  600,
        fontSize: 16,
      },
    },
    MuiInputLabel: {
      outlined: {
        fontSize: 16,
        "&.MuiInputLabel": {
          shrink: {
            transform: "translate(14px, -6px) scale(0.75)",
          },
          marginDense: {
            transform: "translate(14px, 14px) scale(1)",
          },
        },
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        fontSize: 24,
        color: colors.hrv_white,
      },
      toolbarBtnSelected: {
        color: colors.hrv_white,
      },
    },
    MuiTypography: {
      body2: {
        fontSize: 16,
        fontWeight: 400,
      },
    },
    MuiButton: {
      ...baseTheme.overrides.MuiButton,
      text: {
        color: colors.hrv_primary,
      },
    },
  },
});
