import React, { useState, useContext, useEffect } from "react";
import AddTwoToneIcon from "@material-ui/icons/AddTwoTone";
import {
  Grid,
  Dialog,
  Typography,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  Divider,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import { useStyles } from "./style";
import Calendar from "../../../components/calendar";
import { useUtils } from "../../../utils";
import { useValidation } from "../../../components/validation";
import { validationInput } from "./validation";
import withMiddleware from "../../../middleware";
import { getCustomAverages, getTags } from "./handler";
import AverageBox from "../../../components/avg-box";
import { UserContext } from "../../../context";
import _ from "lodash";

function AvgCustom(props) {
  const defaultAvg = {
    label: "Custom",
    unit: "ms",
    footer: "Average RMSSD",
  };
  const { colors } = useUtils();
  const { validation, validate, autoValidate, isValid } =
    useValidation(validationInput);
  const classes = useStyles(colors);
  const user = useContext(UserContext);
  const { showToast } = useUtils();
  const { service, type, userId } = props;
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [open, setOpen] = useState(false);
  const [average, setAverage] = useState(defaultAvg);
  const [loading, setLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [disbaled, setDisabled] = useState(false);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    bindTagsInUi();
  }, []);

  useEffect(() => {
    validateInputs();
  }, [validation]);

  async function handleCustomFilter() {
    if (fromDate || toDate) autoValidate(true);
    setOpen(false);
    setLoading(true);
    try {
      const rmssd = await getCustomAverages(
        service,
        type === "user" ? userId : user.current_group_id,
        fromDate,
        toDate,
        selectedTags,
        type
      );
      let avg = _.clone(average);
      avg.rmssd = rmssd;
      setAverage(avg);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      showToast("error", err.message);
    }
  }

  function getToDateLimits() {
    let limits = { maxDate: new Date() };
    if (fromDate) {
      limits = {
        ...limits,
        minDate: fromDate,
      };
    }
    return limits;
  }

  function getFromDateLimits() {
    let limits = { maxDate: new Date() };
    if (toDate) {
      limits = {
        maxDate: toDate,
      };
    }
    return limits;
  }

  async function bindTagsInUi() {
    try {
      setLoading(true);
      const tags = await getTags(
        service,
        type,
        type === "user" ? userId : user.current_group_id
      );
      console.log(tags);
      setTags(tags);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  function validateInputs(currentTags = selectedTags) {
    if (currentTags.length > 0) {
      if ((!fromDate && !toDate) || isValid) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else if (isValid) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }

  return (
    <>
      {loading ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.root}
        >
          <CircularProgress />
        </Grid>
      ) : average.rmssd > -1 ? (
        <AverageBox
          data={average}
          enableChange={true}
          tags={selectedTags.map((tag) => tag.tag_name).join(", ")}
          onEdit={() => setOpen(true)}
        />
      ) : (
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.root}
          onClick={(_) => setOpen(true)}
        >
          <AddTwoToneIcon className={classes.icon} />
        </Grid>
      )}

      <Dialog open={open}>
        <Grid className={classes.dialogRoot}>
          {tags.length > 0 && (
            <>
              <Grid container direction="row">
                <Grid
                  item
                  xs={4}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingRight: 16,
                  }}
                >
                  <Typography className={classes.headerTypo}>
                    Pick your tags
                  </Typography>
                </Grid>
                <Grid item xs={8} auto>
                  <Select
                    fullWidth
                    variant="outlined"
                    color="primary"
                    placeholder="Select tag"
                    multiple
                    value={selectedTags}
                    classes={{
                      outlined: classes.select,
                    }}
                    MenuProps={{
                      MenuListProps: {
                        className: classes.menu,
                      },
                    }}
                    renderValue={(selected) =>
                      selected.map((tag) => tag.tag_name).join(", ")
                    }
                    disabled={loading}
                    onChange={(e) => {
                      validateInputs(e.target.value);
                      setSelectedTags(e.target.value);
                    }}
                  >
                    {tags.map((tag, index) => (
                      <MenuItem
                        disableGutters
                        key={index}
                        value={tag}
                        style={{ padding: 0 }}
                        classes={{
                          selected: classes.selectedMenu,
                        }}
                      >
                        <Checkbox
                          size="small"
                          color="primary"
                          checked={selectedTags.indexOf(tag) > -1}
                        />
                        <ListItemText
                          disableTypography
                          primary={tag.tag_name}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
            </>
          )}
          <Grid
            container
            alignContent="center"
            alignItems="center"
            style={{ paddingBottom: 6 }}
          >
            <Typography className={classes.headerTypo}>
              Set your time period
            </Typography>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Calendar
                label="From"
                name="fromDate"
                position="right"
                value={fromDate}
                error={!validation.fromDate.isValid}
                helperText={validation.fromDate.errMessage}
                otherProps={getFromDateLimits()}
                inputProps={{
                  style: {
                    fontSize: 14,
                    padding: 12,
                  },
                }}
                onChange={(date) => {
                  setFromDate(date);
                  validate(validation["fromDate"], date.format("YYYY-MM-DD"));
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Calendar
                label="To"
                name="toDate"
                position="right"
                value={toDate}
                otherProps={getToDateLimits()}
                inputProps={{
                  style: {
                    fontSize: 14,
                    padding: 12,
                  },
                }}
                onChange={(date) => {
                  setToDate(date);
                  validate(validation["toDate"], date.format("YYYY-MM-DD"));
                }}
                error={!validation.toDate.isValid}
                helperText={validation.toDate.errMessage}
              />
            </Grid>
          </Grid>
          <Divider className={classes.divider} />

          <Grid container justify="flex-end">
            <Button
              onClick={(_) => setOpen(false)}
              color="default"
              variant="text"
              size="small"
              className={classes.btnCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={disbaled}
              onClick={(_) => handleCustomFilter()}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}

export default withMiddleware(AvgCustom);
