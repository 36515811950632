export const getUser = async (service) => {
  const user = await service.get("/user/profile");
  return user;
};

export const getAttributes = (location) => {
  const titles = {
    "/org": "Dashboard",
    "/org/groups": "Groups",
    "/org/users": "Users",
    "/org/activities": "Activity",
    "/org/group/manage": "Manage Groups",
    "/org/reports": "Reports",
  };

  const getTitle = () => {
    if (location.state && location.state.customTitle) {
      return location.state.customTitle;
    } else {
      return location.pathname.split("/").length > 3 && location.state
        ? getUserName(location.state.user)
        : titles[location.pathname];
    }
  };

  return {
    title: getTitle(),
    enableBack: location.pathname.split("/").length > 3,
  };
};

const getUserName = (user) => {
  const fName = user.first_name ? user.first_name + " " : "";
  const lName = user.last_name ? user.last_name + " " : "";
  return fName + lName || user.slice(0, user.indexOf("@"));
};
