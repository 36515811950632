export default class OzRawDataHandler {
  service = null;
  constructor(service) {
    this.service = service;
  }

  getRawDataForSession = async (session_id) => {
    try {
      const rawData = await this.service.get(`/raw-data/${session_id}`);
      console.log({ rawData });
      return rawData;
    } catch (error) {
      console.error("Error fetching raw data:", error);
      throw error;
    }
  };
}
