export const validationInput = [
  {
    key: "firstName",
    type: "text",
  },
  {
    key: "lastName",
    type: "text",
  },
  {
    key: "dob",
    type: "text",
  },
  {
    key: "gender",
    type: "text",
  },
];
