import React from "react";
import ContentLoader from "react-content-loader";

const GraphLoader = props => (
  <ContentLoader speed={2} height={350} width={550} fill="#333">
    <rect x="60" y="50" height="290" width="30" fill="#333" />
    <rect x="90" y="210" height="130" width="30" />
    <rect x="150" y="50" height="290" width="30" />
    <rect x="180" y="180" height="160" width="30" />
    <rect x="240" y="50" height="290" width="30" />
    <rect x="270" y="110" height="230" width="30" />
    <rect x="330" y="50" height="290" width="30" />
    <rect x="360" y="210" height="130" width="30" />
    <rect x="420" y="50" height="290" width="30" />
    <rect x="450" y="100" height="240" width="30" />
    <rect x="30" y="45" height="340" width="1" />
    <rect x="25" y="340" height="1" width="600" />
  </ContentLoader>
);

export default GraphLoader;
