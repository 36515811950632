import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import ProtectedRoute from "../../components/protected-route";
import Dashboard from "./dashboard";
import Groups from "./groups";
import Activity from "./activity";
import GroupManage from "./groups/manage";
import Drawer from "../../components/drawer";
import { menus } from "./menus";
import Header from "../../components/header";
import ImportUsers from "./import-users";
import Users from "./users";
import { getAttributes, getUser } from "./handler";
import { useDispatch, useSelector } from "react-redux";
import withMiddleware from "../../middleware";
import { updateUser } from "../../actions/app";
import { UserContext } from "../../context";
import { logoutUser } from "../../actions";
import OrgSwitch from "./org-switch";
import Reports from "./reports";
function OrgHome(props) {
  let { path, url } = useRouteMatch();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => ({
    user: state.app.user,
  }));
  const { location, service, history } = props;

  const options = [
    {
      id: 1,
      label: "Sign out",
    },
  ];

  useEffect(() => {
    reFetchUser();
  }, [location.state]);

  async function reFetchUser() {
    const user = await getUser(service);
    dispatch(updateUser(user));
  }

  function signOut(id) {
    dispatch(logoutUser());
  }

  return (
    <>
      {user ? (
        <UserContext.Provider value={user}>
          <Drawer menuItems={menus} onChangeMenu={(menu) => {}} />
          <Header
            attr={getAttributes(location)}
            options={options}
            history={history}
            otherComponents={<OrgSwitch />}
            onMenuClick={(id) => signOut(id)}
          />
          <ProtectedRoute exact path={path} component={Dashboard} />
          <ProtectedRoute exact path={`${path}/groups`} component={Groups} />
          <ProtectedRoute
            exact
            path={`${path}/import`}
            component={ImportUsers}
          />
          <ProtectedRoute exact path={`${path}/users`} component={Users} />
          <ProtectedRoute
            exact
            path={`${path}/group/manage`}
            component={GroupManage}
          />
          <ProtectedRoute
            exact
            path={`${path}/activities`}
            component={Activity}
          />
          <ProtectedRoute exact path={`${path}/reports`} component={Reports} />
        </UserContext.Provider>
      ) : (
        <div />
      )}
    </>
  );
}

export default withMiddleware(OrgHome);
