import React, { useState } from "react";
import { getOptions } from "./handler";
import withMiddleware from "../../../../../middleware";
import _ from "lodash";
import { Button, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useStyles } from "./style";
import { useUtils } from "../../../../../utils";

function AddParticipants(props) {
  const classes = useStyles();
  const { showToast } = useUtils();
  const { service, user, onAddParticipants, onOpen } = props;

  const [options, setOptions] = useState([]);
  const [participants, setPaticipants] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getSuggestions(keyword) {
    try {
      setLoading(true);
      const suggestions = await getOptions(
        service,
        keyword,
        user.current_group_id,
        user.org_id
      );
      setOptions(suggestions);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      showToast("error", err.message);
    }
  }

  return (
    <Grid
      container
      className={classes.dialogRoot}
      direction="column"
      justify="center"
    >
      <Grid item className={classes.dialogTitle}>
        Add Users
      </Grid>
      <Grid item xs>
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justify="center"
          className={classes.dialogContent}
        >
          <Autocomplete
            options={options}
            multiple={true}
            loading={loading}
            value={participants}
            renderInput={(params) => (
              <TextField fullWidth {...params} placeholder="Users" />
            )}
            getOptionLabel={(option) =>
              (option.first_name || "") + " " + (option.last_name || "")
            }
            onInputChange={(e, value) => getSuggestions(value)}
            onChange={(e, value) => {
              setPaticipants(value);
            }}
            className={classes.autocomplete}
            classes={{
              option: classes.option,
            }}
            ChipProps={{
              color: "primary",
              size: "small",
              classes: {
                colorPrimary: classes.chip,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justify="flex-end" style={{ padding: "0 24px 24px 0" }}>
          <Button
            color="primary"
            onClick={() => onOpen(false)}
            style={{ marginRight: 24 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onAddParticipants(participants)}
            disabled={participants.length === 0}
          >
            Add
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withMiddleware(AddParticipants);
