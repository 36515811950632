const getUserList = async (service, org_id, currentUserId) => {
  const users = await service.get(`/org/users?orgId=${org_id}&type=all`);
  const mappedUsers = users.map((user) => ({
    ...user,
    isAdmin: user.user_type === "org" ? "Yes" : "",
    adminText: user.user_type === "org" ? "Admin" : "",
    isExternal: user.is_external === true ? "Yes" : "",
    externalText: user.is_external === true ? "External" : "",
    action: getAction(user, currentUserId),
    hidden: user.is_external === true,
  }));
  return { mappedUsers };
};

const getAction = (row, currentUserId) => {
  const isDisabled = row.user_id === currentUserId;
  if (row.is_external === true) {
    return {
      tooltip: isDisabled ? "" : "Remove",
      icon: "close",
      key: "delete",
      disabled: isDisabled,
      iconProps: {
        color: isDisabled ? "disabled" : "error",
        fontSize: "small",
      },
    };
  } else {
    return {
      tooltip: isDisabled ? "" : "Delete",
      icon: "delete",
      key: "delete",
      disabled: isDisabled,
      iconProps: {
        color: isDisabled ? "disabled" : "error",
        fontSize: "small",
      },
    };
  }
};

const addUser = async (service, user, password, email, org_id) => {
  const birthDate = user.dob.format("YYYY-MM-DD");
  const userData = {
    password,
    firstName: user.firstName,
    lastName: user.lastName,
    gender: user.gender,
    birthDate,
    email,
    orgId: org_id,
  };
  return service.post("/org/users", userData);
};

const editUser = async (service, user, userId, org_id) => {
  const birthDate = user.dob.format("YYYY-MM-DD");
  const userData = {
    firstName: user.firstName,
    lastName: user.lastName,
    isAdmin: user.isAdmin === "Yes" ? true : false,
    gender: user.gender,
    orgId: org_id,
    birthDate,
    userId,
  };
  return service.put("/org/users", userData);
};

const deleteUser = async (service, userId, userType, isExternal, org_id) => {
  return await service.delete(
    `/org/users?orgId=${org_id}&userId=${userId}&userType=${userType}&isExternal=${isExternal}`
  );
};

const changePassword = async (service, userId, password, org_id) => {
  return await service.put(`/user/password`, {
    password,
    userId,
    orgId: org_id,
  });
};

const uploadFile = async (service, file, orgId, domain) => {
  const formData = new FormData();
  formData.append("orgId", orgId);
  formData.append("file", file);
  formData.append("domain", domain);
  return service.postFile(`/org/users/import`, formData);
};

export {
  getUserList,
  addUser,
  editUser,
  deleteUser,
  changePassword,
  uploadFile,
};
