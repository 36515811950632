import { createTheme } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

// colour pallette:
// const DARK_GREY = rgb(66, 66, 66, 1);
// const PINK = rgb(246, 86, 111, 1);
// const ORANGE = rgb(252, 157, 8, 1);
// const PRIMARY = rgb(0, 135, 161, 1);
// const PRIMARY_GRADIENT = rgb(15, 205, 241, 1);
// const LIGHT_PINK = rgb(0, 135, 161, 1);
// const GRADIENT_TOP = rgb(253, 88, 111, 1);
// const MEDIUM_GREY = rgb(140, 140, 140, 1);
// const LIGHT_GREY = rgb(218, 218, 218, 1);
// const BLUE = rgb(76, 120, 219, 1);
// const PALE_GREY = rgb(247, 249, 250, 1);
// const WHITE = rgb(255, 255, 255, 1);
// const SOFT_GREY = rgb(234, 234, 234, 1);
// const ERROR = rgb(206, 64, 64, 1);
// const GRAPH_COLOR = rgb(90, 165, 227, 1);
// const BLACK = rgb(0, 0, 0, 1);
// const GREEN = rgb(142, 201, 159, 1);

export const colors = {
  hrv_primary: "rgb(0, 135, 161, 1)", // primary
  hrv_error: "rgb(206, 64, 64, 1)",
  hrv_medium_gray: "rgb(140, 140, 140, 1)",
  hrv_black: "rgb(142, 201, 159, 1)", // secondary
  hrv_blue: "rgb(76, 120, 219, 1)",
  hrv_white: "#fff",
  hrv_border_color: "#e8e8e8",
  hrv_light_gray: "#fafafa",
  hrv_info_main: "#CA4858",
  hrv_info_dark: "#FD5B6E",
  hrv_info_light: "#FEDEE2",
  hrv_action_main: "#3BB78F",
  hrv_info_gmain: "#2493BC",
  hrv_graphs: "rgb(0, 135, 161, 1)",
};

export const baseTheme = createTheme({
  palette: {
    primary: { main: colors.hrv_primary },
    secondary: { main: colors.hrv_black },
    error: { main: colors.hrv_error },
    action: { main: colors.hrv_action_main },
    info: {
      main: colors.hrv_info_main,
      light: colors.hrv_info_light,
      dark: colors.hrv_info_dark,
      gmain: colors.hrv_info_gmain,
    },
    text: {
      primary: grey[800],
      secondary: colors.hrv_medium_gray,
    },
    background: {
      default: colors.hrv_light_gray,
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: ["Gotham Rounded", "sans-serif"],
    fontSize: 16,
    body1: {
      fontSize: 18,
      fontWeight: 500,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        boxShadow: "none !important",
      },
      containedPrimary: {
        color: colors.hrv_white,
      },
    },

    MuiMenuItem: {
      root: {
        fontSize: 14,
        fontWeight: 500,
      },
    },
    MuiLink: {
      root: { fontFamily: ["Gotham Rounded", "sans-serif"] },
    },
    MuiOutlinedInput: {
      inputMarginDense: {
        paddingTop: 10,
        paddingBottom: 8,
        fontSize: 16,
        height: 22,
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 14,
      },
      marginDense: {
        fontSize: 14,
        height: 20,
        paddingTop: 2,
      },
      shrink: {
        fontSize: 14,
        color: colors.hrv_medium_gray,
      },
    },
    MuiSnackbar: {
      root: {
        height: 60,
      },
    },
    MuiSnackbarContent: {
      message: {
        fontSize: 13,
      },
    },
    MuiDialog: {
      paperWidthSm: {
        borderRadius: 10,
      },
    },
  },
});
