import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 104,
    paddingLeft: 118
  },
  formWrapper: {
    background: "#ffffff",
    borderRadius: 10,
    padding: 24,
    border: `${"#e8e8e8"} solid 2px`,
    width: "70%"
  },
  pwdGenGrid: {
    paddingTop: 50
  },
  checkBox: {
    " & .MuiButton-text": {
      padding: "1px 9px !important"
    }
  },
  addIcon: {
    color: "#ffffff",
    background: "#e4e4e4",
    borderRadius: "50%",
    height: 23,
    width: 23
  },
  addIconGrid: {
    paddingTop: 3,
    height: 19,
    width: 19
  },
  errorMsg: {
    color: "red",
    fontSize: 12
  },
  boxStyle: {
    background: `${"#ffffff"}`,
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: `${"#e8e8e8"} solid 1px`
  },
  defaultAdminMainGrid: {
    paddingTop: 36
  },
  adminMainGrid: {
    borderBottom: `${"#e8e8e8"} solid 2px`
  },
  addAdminGrid: {
    border: `${"#fd5b6d"} solid 1px`,
    color: "#fd5b6d",
    cursor: "pointer"
  },
  closeIcon: {
    color: "#959595",
    cursor: "pointer"
  },
  closeIconGrid: {
    "& .MuiSvgIcon-root": {
      width: "0.7em !important",
      height: "0.7em !important"
    }
  },
  heading: {
    paddingBottom: 10,
    borderBottom: `${"#e8e8e8"} solid 1px`
  },
  adminList: {
    paddingBottom: 10,
    paddingTop: 10,
    borderBottom: `${"#e8e8e8"} solid 1px`
  },
  parentGrid: {
    paddingBottom: 20
  },
  adminTitle: {
    // paddingTop: 6,
    color: "#797979"
  },
  rightAlign: {
    textAlign: "right"
  },
  flexDisplay: {
    display: "flex"
  },
  dialogHeader: {
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff"
  },
  spacer: {
    padding: 16
  },
  input: {
    padding: "10px 14px"
  },
  divider: {
    marginTop: 24,
    background: theme.palette.info.light
  },
  loader: {
    position: "absolute",
    background: "white",
    height: "100%",
    zIndex: 2,
    opacity: 0.5,
    width: "100%",
    left: 0,
    top: 60
  },
  closeButton: {
    // position: "absolute",
    height: 32,
    width: 32,
    top: -16,
    right: -16,
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 16,
    background: colors => colors.hrv_white
  },
  containerHeader: { paddingBottom: 16 }
}));
