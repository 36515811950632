import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    boxShadow: "none",
    paddingLeft: 113,
    background: colors => colors.hrv_white,
    borderBottom: colors => `${colors.hrv_border_color} solid 1px`
  },
  title: {
    flexGrow: 1
  },
  spacing: {
    width: 24
  }
});
