import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import InfiniteScroll from "react-infinite-scroll-component";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  optimalZone: {
    padding: "6px 8px 2px",
    border: "1px solid #0087A1",
    marginLeft: "10px",
    fontSize: "13px",
    borderRadius: "5px",
    minWidth: "160px",
    color: "#fff",
    textAlign: "start",
    display: "inline-block",
  },
  sessionList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "15px",
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function SessionList(props) {
  const { data = [], selected = "", setSelected, allOzData } = props;
  const flatData = data
    ?.flatMap((innerArray) => innerArray)
    .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));
  const classes = useStyles();
  const [sessionItems, setSessionItems] = React.useState(
    Array.from({ length: 20 })
  );
  const [hasMore, setHasMore] = React.useState(true);
  const fetchMoreData = () => {
    if (sessionItems.length >= flatData.length) {
      setHasMore(false);
      return;
    }
    setTimeout(() => {
      setSessionItems(sessionItems.concat(Array.from({ length: 20 })));
    }, 200);
  };
  let newData = flatData.slice(0, sessionItems.length);
  return (
    <>
      {data.length > 0 ? (
        <div
          className={classes.root}
          style={{ maxHeight: 1000, overflow: "auto" }}
        >
          <InfiniteScroll
            dataLength={sessionItems.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<strong style={{ padding: "0 16px" }}>Loading...</strong>}
          >
            <List component="nav" aria-label="secondary mailbox folders">
              {allOzData.map((ozData, index) => {
                return (
                  <ListItem
                    onClick={() => {
                      if (ozData.hasData) {
                        setSelected("");
                      }
                    }}
                    key={`All_${index}`}
                    style={{
                      backgroundColor: !selected ? "#40E0D0" : "#fff",
                    }}
                    button
                  >
                    <ListItemText
                      primary={
                        <Typography className={classes.sessionList} variant="p">
                          <span>{ozData?.userName} - All</span>
                          <span
                            className={classes.optimalZone}
                            style={{
                              backgroundColor: "#0087A1",
                            }}
                          >
                            {ozData.hasData
                              ? `Optimal Zone ${ozData?.allOz}%`
                              : "Has no Session Data"}
                          </span>
                        </Typography>
                      }
                    />
                  </ListItem>
                );
              })}
              {newData.map((session, key) => {
                const isSeleceted = selected === session.sessionId;
                return (
                  <ListItem
                    onClick={() => {
                      setSelected(session.sessionId);
                    }}
                    key={key}
                    style={{
                      backgroundColor: isSeleceted ? "#40E0D0" : "#fff",
                    }}
                    button
                  >
                    <ListItemText
                      primary={
                        <Typography className={classes.sessionList} variant="p">
                          <span>Reading - {session.dateTime}</span>
                          <span
                            className={classes.optimalZone}
                            style={{
                              backgroundColor: "#0087A1",
                            }}
                          >
                            Optimal Zone {session.ozSesAvgPercentage || 0}%
                          </span>
                        </Typography>
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
          </InfiniteScroll>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
