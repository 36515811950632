import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    padding: "126px 24px 24px 138px"
  },
  btnRoot: {
    position: "fixed",
    right: 0,
    top: 64,
    padding: "12px 24px",
    width: "100%",
    zIndex: 12,
    background: "#fafafa"
  }
}));
