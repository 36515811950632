import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  typoMessage: {
    fontSize: 14
  },
  noReadings: {
    height: 336,
    background: colors => colors.hrv_white,
    borderRadius: 10,
    border: colors => `${colors.hrv_border_color} solid 3px`
  }
});
