import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    padding: "5px 15px",
    minWidth: 120
  },
  paper: {
    boxShadow: "none",
    background: "transparent"
  },
  imgContainer: {
    width: 80,
    height: 80,
    background: theme.palette.primary.main,
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5
  },
  img: { height: 40, width: 40 },
  loader: { width: 100, textAlign: "center" },
  selectPaper: {
    top: "48px !important"
  }
}));
