import React, { useEffect, useState } from "react";
import ProtectedRoute from "../../components/protected-route";
import { ThemeProvider } from "@material-ui/core/styles";
import Dashboard from "./dashboard";
import Drawer from "../../components/drawer";
import { groupTheme } from "../../utils/theme/group";
import { menus } from "./menus";
import Header from "../../components/header";
import { useRouteMatch } from "react-router-dom";
import { logoutUser } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { UserContext } from "../../context";
import Activity from "./activity";
import Participants from "./participants";
import withMiddleware from "../../middleware";
import { getUser, getAttributes } from "./handler";
import { updateUser } from "../../actions/app";
import Profile from "./user-profile";
import userReadings from "./user-readings";
import GroupSwitch from "./group-switch";
import _ from "lodash";
import orgName from "../admin/organisation/org-name";
import OrgIcon from "../../assets/images/org.png";
import { useUtils } from "../../utils";

function GroupHome(props) {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const { showToast } = useUtils();
  const { user } = useSelector((state) => ({
    user: state.app.user,
  }));
  const { location, history, service } = props;
  const [menu, setMenu] = useState(menus);
  const options = [
    {
      id: 1,
      label: "Sign out",
    },
  ];

  useEffect(() => {
    reFetchUser();
  }, [location.state]);

  async function reFetchUser() {
    try {
      if (location.state && location.state.fromOrg) {
        updateMenu();
      }
      const user = await getUser(service);
      dispatch(updateUser(user));
    } catch (err) {
      showToast("error", err.message);
    }
  }

  function signOut(id) {
    dispatch(logoutUser());
  }

  function updateMenu() {
    const existIndex = _.findIndex(menu, { id: "exit", title: "Back to Org" });
    if (existIndex === -1) {
      const orgMenu = {
        id: "exit",
        title: "Back to Org",
        path: "/org",
        icon: OrgIcon,
      };
      let oldMenus = _.clone(menu);
      oldMenus.push(orgMenu);
      setMenu(oldMenus);
    }
  }

  return (
    <ThemeProvider theme={groupTheme}>
      {user ? (
        <UserContext.Provider value={user}>
          <Drawer menuItems={menu} onChangeMenu={() => {}} />
          <Header
            attr={getAttributes(location)}
            options={options}
            history={history}
            otherComponents={<GroupSwitch />}
            onMenuClick={(id) => signOut(id)}
          />
          <ProtectedRoute exact path={path} component={Dashboard} />
          <ProtectedRoute
            exact
            path={`${path}/participants`}
            component={Participants}
          />
          <ProtectedRoute
            exact
            path={`${path}/participants/profile`}
            component={Profile}
          />
          <ProtectedRoute
            exact
            path={`${path}/participants/readings`}
            component={userReadings}
          />
          <ProtectedRoute
            exact
            path={`${path}/activity`}
            component={Activity}
          />
        </UserContext.Provider>
      ) : (
        <div />
      )}
    </ThemeProvider>
  );
}

export default withMiddleware(GroupHome);
