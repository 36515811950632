export const validationInput = [
  {
    key: "fromDate",
    type: "text"
  },
  {
    key: "toDate",
    type: "text"
  }
];
