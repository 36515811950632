import React from "react";
import { AppBar, Toolbar, Typography, IconButton } from "@material-ui/core";
import BackArrow from "@material-ui/icons/ArrowBackRounded";
import { useStyles } from "./style";
import { useUtils } from "../../utils";
import Menus from "./menu";

function Header(props) {
  const { colors } = useUtils();
  const classes = useStyles(colors);
  const {
    attr = {},
    history,
    options = [],
    onMenuClick,
    otherComponents,
  } = props;
  return (
    <AppBar className={classes.root} position="fixed">
      <Toolbar disableGutters>
        {attr.enableBack ? (
          <IconButton onClick={() => history.goBack()}>
            <BackArrow />
          </IconButton>
        ) : (
          <div className={classes.spacing} />
        )}
        <Typography color="secondary" variant="body1" className={classes.title}>
          {attr.title}
        </Typography>
        {otherComponents && otherComponents}
        {options.length > 0 && (
          <Menus
            options={options}
            onMenuSelect={(id) => {
              if (onMenuClick) onMenuClick(id);
            }}
          />
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;
