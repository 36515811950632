export const messages = {
  required: "Required field",
  enterEmail: "Please enter a valid email.",
  passwordPolicy:
    "password should have a minimum length of 6 characters, contain atleast one special character, one numeric and one uppercase.",
  passwordNotMatched: "Password doesn't match.",
  domain: "Invalid domain",
  alphaNumeric: "Invalid characters",
  name: "Invalid characters"
};
