import passwordGenerator from "generate-password";

export const generatePassword = () => {
  return (
    passwordGenerator.generate({
      length: 10,
      numbers: true,
      symbols: true,
      uppercase: true,
      exclude: "^_`{|}~().-",
      strict: true
    }) || "AKKJKS"
  );
};

export const addOrg = async (service, org, pwd) => {
  const email = `${org.email}`;
  const orgData = {
    name: org.name,
    uniqueName: org.uniqueName,
    password: pwd,
    email: email
  };
  return service.post("/org/profile", orgData);
};
