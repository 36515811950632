import React, { useState } from "react";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Brush,
  Legend,
  Surface,
  Symbols,
} from "recharts";
import "./styles.css";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    // Function to map the dataKey back to the display name
    const displayName = (dataKey) => {
      switch (dataKey) {
        case "rmssd":
          return "RMSSD";
        case "minMax":
          return "MaxMin";
        case "sdnn":
          return "SDNN";
        case "lf":
          return "LowFrequency";
        case "hf":
          return "HighFrequency";
        case "vlf":
          return "VeryLowFrequency";
        default:
          return dataKey; // Default case returns the original key if no match is found
      }
    };
    return (
      <div className="custom-tooltip">
        <span>{payload?.[0]?.payload?.tooltipText}</span>
        <br />
        <ul>
          <li>
            <p>Date: {payload?.[0]?.payload?.dateTime}</p>
          </li>
          {payload?.map((pd, key) => {
            const rKey = key;
            return (
              <li key={rKey} style={{ color: pd.color }}>
                <p className="label" key={rKey}>{`${displayName(
                  pd?.dataKey
                )} : ${pd?.value}`}</p>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  return null;
};

const renderCusomizedLegend = ({ payload, setSettings }) => {
  const handleClick = ({ entry, index }) => {
    const newPayload = [...payload];
    newPayload[index] = entry;
    console.log(newPayload);
    setSettings(newPayload);
  };
  return (
    <div className="customized-legend">
      {payload.map((entry, index) => {
        const { name, color, active } = entry;
        const style = {
          marginRight: 10,
          color: active ? "#AAA" : "#000",
        };

        return (
          <span
            className="legend-item"
            onClick={() =>
              handleClick({ entry: { ...entry, active: !active }, index })
            }
            style={style}
          >
            <Surface width={10} height={10} viewBox="0 0 10 10">
              <Symbols cx={5} cy={5} type="circle" size={80} fill={color} />
              {!active && (
                <Symbols cx={5} cy={5} type="circle" size={35} fill={"#FFF"} />
              )}
            </Surface>
            <span>{name}</span>
          </span>
        );
      })}
    </div>
  );
};

const legendNameToDataKey = {
  RMSSD: "rmssd",
  MaxMin: "minMax",
  SDNN: "sdnn",
  LowFrequency: "lf",
  HighFrequency: "hf",
  VeryLowFrequency: "vlf",
  // Add other mappings as necessary
};

const colors = [
  "#f05453",
  "#0087a1",
  "#06D6A0",
  "#FFD166",
  "#5995ED",
  "#044389",
  "#FFAD05",
  "#2E5077",
  "#D7E8BA",
  "#611C35",
  "#EF476F",
  "#118AB2",
];
const getRandomColor = (index) => {
  return (
    colors?.[index] || `#${Math.floor(Math.random() * 16777215).toString(16)}`
  );
};

function Index(props) {
  const { users = [], type, data = [] } = props;
  const [legendLineChart, setLegendLineChart] = useState([
    /* { name: "meanNni", color: getRandomColor(0), active: true },
    { name: "medianNni", color: getRandomColor(1), active: true },
    { name: "meanHr", color: getRandomColor(2), active: true },
    { name: "maxHr", color: getRandomColor(3), active: true },
    { name: "minHr", color: getRandomColor(4), active: true }, */
    { name: "RMSSD", color: getRandomColor(0), active: true },
    { name: "MaxMin", color: getRandomColor(1), active: true },
    { name: "SDNN", color: getRandomColor(2), active: true },
  ]);

  const [legendLineChartAll, setLegendLineChartAll] = useState([
    { name: "RMSSD", color: "#FF0000", active: true },
    { name: "MaxMin", color: getRandomColor(1), active: true },
    { name: "SDNN", color: getRandomColor(2), active: true },
  ]);

  const [legendBarChart, setLegendBarChart] = useState([
    { name: "LowFrequency", color: getRandomColor(0), active: true },
    { name: "HighFrequency", color: getRandomColor(1), active: true },
    { name: "VeryLowFrequency", color: getRandomColor(2), active: true },
    /* { name: 'lfnu', color: getRandomColor(3), active: true},
    { name: 'hfnu', color: getRandomColor(4), active: true}, */
  ]);

  const [legendBarChartAll, setLegendBarChartAll] = useState([
    { name: "LowFrequency", color: getRandomColor(0), active: true },
    { name: "HighFrequency", color: getRandomColor(1), active: true },
    { name: "VeryLowFrequency", color: getRandomColor(2), active: true },
  ]);
  return (
    <>
      <strong style={{ display: "flex", justifyContent: "center" }}>
        {" "}
        Time Domain{" "}
      </strong>
      <div style={{ width: "100%", height: 450, position: "relative" }}>
        <ResponsiveContainer>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" stroke="rgba(0,0,0,0.2)" />
            <XAxis stroke="rgba(0,0,0,0.4)" dataKey="dateTime" />
            <YAxis stroke="rgba(0,0,0,0.4)" />
            <Tooltip
              content={<CustomTooltip cursor={false} toolTipLabel={type} />}
            />
            <Legend
              verticalAlign="bottom"
              height={36}
              align="center"
              payload={legendLineChartAll}
              setSettings={setLegendLineChartAll}
              content={renderCusomizedLegend}
            />
            {legendLineChartAll.map((legend, index) => {
              if (!legend.active) {
                return null;
              }
              const dataKey = legendNameToDataKey[legend.name] || legend.name; // Fallback to legend.name if no mapping found
              return (
                <Line
                  key={index}
                  dataKey={dataKey}
                  stroke={legend.color}
                  activeDot={{ r: 8 }}
                  strokeWidth={3}
                  dot={true}
                  connectNulls
                />
              );
            })}
            <Brush dataKey="xAxis" height={40} stroke="rgb(0, 135, 161, 1)" />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <strong style={{ display: "flex", justifyContent: "center" }}>
        {" "}
        Frequency Domain{" "}
      </strong>
      <div style={{ width: "100%", height: 450, position: "relative" }}>
        <ResponsiveContainer>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" stroke="rgba(0,0,0,0.2)" />
            <XAxis stroke="rgba(0,0,0,0.4)" dataKey="dateTime" />
            <YAxis />
            <Tooltip
              content={<CustomTooltip cursor={false} toolTipLabel={type} />}
            />
            <Legend
              verticalAlign="bottom"
              height={36}
              align="center"
              payload={legendBarChartAll}
              setSettings={setLegendBarChartAll}
              content={renderCusomizedLegend}
            />
            {legendBarChartAll.map((legend, index) => {
              if (!legend.active) {
                return null;
              }
              const dataKey = legendNameToDataKey[legend.name] || legend.name; // Fallback to legend.name if no mapping found
              return <Bar key={index} dataKey={dataKey} fill={legend.color} />;
            })}
            <Brush dataKey="xAxis" height={40} stroke="rgb(0, 135, 161, 1)" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}

export default React.memo(Index);
