import DashboardIcon from "../../../assets/images/dashboard.png";
import GroupsIcon from "../../../assets/images/groups.png";
import ActivityIcon from "../../../assets/images/activity.png";

export const menus = [
  {
    id: "dashboard",
    title: "Dashboard",
    icon: DashboardIcon,
    path: "/group",
  },
  {
    id: "participants",
    title: "Users",
    icon: GroupsIcon,
    path: "/group/participants",
  },
  /* {
    id: "activity",
    title: "Activity",
    icon: ActivityIcon,
    path: "/group/activity"
  } */
];
