const columns = [
  {
    title: "Name",
    field: "name"
  },
  {
    title: "Users",
    field: "totalAdmins"
  }
];

const groupHandler = async (service, orgId) => {
  const group = await service.get(`/group/profile?orgId=${orgId}&rmssd=false`);

  return { group: organizeData(group), columns };
};

const organizeData = res => {
  return res.map(user => ({
    ...user,
    avg_rmssd: (user.avg_rmssd ? user.avg_rmssd : "0") + " ms",
    totalAdmins: user.members,
    hidden: user.name === "Default"
  }));
};

const groupSwitch = async (service, groupId) => {
  return await service.put(`/user/profile`, {
    currentGroupId: groupId.toString()
  });
};

const deleteGroup = async (service, orgId, groupId) => {
  return await service.delete(
    `/group/profile?orgId=${orgId}&groupId=${groupId}`
  );
};

export { groupHandler, groupSwitch, deleteGroup };
