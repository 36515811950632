import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: 114,
    paddingTop: 112
  },
  table: {
    // height: window.innerHeight - 200,
    padding: 24,
    overflow: "hidden",
    fontSize: 10
  },
  paper: {
    boxShadow: "none",
    background: "transparent"
  },
  imgContainer: {
    width: 80,
    height: 80,
    background: theme.palette.primary.main,
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5
  },
  imgContainerGroup: {
    width: 80,
    height: 80,
    background: theme.palette.info.gmain,
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5
  },
  img: { height: 40, width: 40 },
  loader: { width: 100, textAlign: "center" },
  selectPaper: {
    top: "48px !important"
  },
  btnRoot: {
    position: "fixed",
    right: 0,
    top: 64,
    padding: "12px 24px",
    width: "100%",
    zIndex: 12,
    background: "#fafafa"
  },
  paperWidth: {
    maxWidth: 630
  }
}));
