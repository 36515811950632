import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Login from "./pages/auth/login";
import Auth from "./pages/auth";
import ProtectedRoute from "./components/protected-route";
import OrgHome from "./pages/org";
import AdminHome from "./pages/admin";
import GroupHome from "./pages/group";

function Routes(props) {
  return (
    <BrowserRouter>
      {/* <ProtectedRoute exact path="/" component={Auth} /> */}
      <Route exact path="/" component={Login} />
      <ProtectedRoute path="/org" component={OrgHome} />
      <ProtectedRoute path="/admin" component={AdminHome} />
      <ProtectedRoute path="/group" component={GroupHome} />
    </BrowserRouter>
  );
}

export default Routes;
