export const getOrgAdmins = async (service, orgId) => {
  const res = await service.get(`/org/users?orgId=${orgId}&type=allAdmins`);
  const admins = organizeData(res);
  return admins;
};

export const removeOrgAdmins = (service, orgId, userId) => {
  return service.delete(`/org/admin?orgId=${orgId}&userId=${userId}`);
};

const organizeData = users => {
  const data = users.map(user => ({
    ...user,
    name: (user.first_name || "") + " " + (user.last_name || "")
  }));
  return data;
};
