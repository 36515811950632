import React, { useEffect, useState, useContext } from "react";
import { Select, MenuItem, Dialog, Grid } from "@material-ui/core";
import { useStyles } from "./style";
import withMiddleware from "../../../middleware";
import { getGroups, setCurrentGroup } from "./handler";
import { UserContext } from "../../../context";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import GroupLogo from "../../../assets/images/groups.png";
import SpinnerLoader from "react-loader-spinner";
import { useUtils } from "../../../utils";

function GroupSwitch(props) {
  const classes = useStyles();
  const history = useHistory();
  const { showToast } = useUtils();
  const user = useContext(UserContext);
  const { service } = props;
  const [groups, setGroups] = useState([]);
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const activeGroup = _.find(
      groups,
      (group) => group.id === user.current_group_id
    );
    if (activeGroup) {
      setValue(activeGroup.id.toString());
    }
  }, [groups]);

  async function init() {
    try {
      const groups = await getGroups(service, user.org_id);
      setGroups(groups);
    } catch (err) {
      showToast("error", err.message);
    }
  }

  async function groupChangeHandler(e) {
    try {
      setOpen(true);
      setValue(e.target.value);
      const currentGroupId = e.target.value;
      await setCurrentGroup(service, { currentGroupId });
      history.push("/group", {
        groupId: e.target.value,
        user: "Dashboard ",
      });
      setOpen(false);
    } catch (err) {
      setOpen(false);
      showToast("error", err.message);
    }
  }
  return (
    <>
      <Select
        value={value}
        onChange={groupChangeHandler}
        variant="outlined"
        disabled={groups.length === 0}
        color="primary"
        classes={{
          outlined: classes.root,
        }}
        MenuProps={{
          classes: {
            paper: classes.selectPaper,
          },
        }}
      >
        {groups.map((group, index) => (
          <MenuItem
            key={index}
            value={group.id.toString()}
            disabled={group.id.toString() === value}
          >
            {group.name}
          </MenuItem>
        ))}
      </Select>
      <Dialog
        open={open}
        classes={{
          paper: classes.paper,
        }}
      >
        <Grid container style={{ padding: 24 }}>
          <Grid className={classes.imgContainer}>
            <img src={GroupLogo} className={classes.img} />
          </Grid>
          <Grid className={classes.loader}>
            <SpinnerLoader
              type="ThreeDots"
              color="#fff"
              height={80}
              width={60}
            />
          </Grid>
          <Grid className={classes.imgContainer}>
            <img src={GroupLogo} className={classes.img} />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}

export default withMiddleware(GroupSwitch);
