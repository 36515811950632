import React from "react";
import { ListItem, ListItemIcon, ListItemText, Badge } from "@material-ui/core";
import { useStyles } from "./style";
import { Link } from "react-router-dom";
import { useUtils } from "../../../utils";
import { useSelector } from "react-redux";

const MenuItemComponent = (props) => {
  const { colors } = useUtils();
  const classes = useStyles(colors);
  const { notification } = useSelector((state) => ({
    notification: state.app.notification,
  }));
  const { isActive, menuItem, iconComponent, onChangeMenu } = props;
  return (
    <ListItem
      button
      component={Link}
      to={menuItem.path}
      alignItems="center"
      className={`${classes.drawerItemStyle} ${
        isActive ? classes.drawerStyleActiveItem : ""
      }`}
      onClick={() => onChangeMenu(menuItem)}
    >
      <ListItemIcon style={{ display: "block" }}>
        {notification.id === menuItem.id && notification.count > 0 ? (
          <Badge
            badgeContent={notification.count}
            classes={{
              badge: classes.badge,
            }}
          >
            <img src={iconComponent} />
          </Badge>
        ) : (
          <img src={iconComponent} />
        )}
      </ListItemIcon>
      <ListItemText
        className={isActive ? classes.activeTextStyle : classes.textStyle}
        primary={menuItem.title}
      />
    </ListItem>
  );
};

export default MenuItemComponent;
