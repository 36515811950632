import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Typography,
  FormControl,
  TextField,
  Button,
  Link,
  FormHelperText,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { useValidation } from "../../../components/validation";
import { validationInput } from "./validation";
import { useUtils } from "../../../utils";
import withMiddleware from "../../../middleware";
import loginHandler from "./handler";
import { loginUser, logoutUser } from "../../../actions";
import { useHistory } from "react-router-dom";
import { updateUser } from "../../../actions/app";
import LogoIcon from "../../../assets/images/logo.png";
import Visibility from "@material-ui/icons/VisibilityOutlined";
import VisibilityOff from "@material-ui/icons/VisibilityOffOutlined";

const InputKeys = {
  USERNAME: "username",
  PASSWORD: "password",
};

const defaultInputProps = {
  inputProps: {
    autoComplete: "new-password",
    form: {
      autoComplete: "off",
    },
  },
};

function Login(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { colors } = useUtils();
  const classes = useStyles(colors);
  const { validation, validate, autoValidate } = useValidation(validationInput);
  const { auth } = useSelector((state) => ({
    auth: state.auth,
  }));
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState();

  // useEffect(() => {
  //   dispatch(logoutUser());
  // }, []);

  useEffect(() => {
    if (auth.isAuthenticated) {
      handleLogging();
    }
  }, [auth.isAuthenticated]);

  async function handleInputChange(key, value) {
    setError(null);
    validate(validation[key], value);
    if (key === InputKeys.USERNAME) {
      setUsername(value);
    } else {
      setPassword(value);
    }
  }

  async function handleLogging() {
    try {
      setLoading(true);
      props.service.updateUser(auth.user);
      const { user, route } = await loginHandler(props.service);
      dispatch(updateUser(user));
      history.push(route, { user });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      dispatch(logoutUser());
      setError(err.message);
    }
  }

  async function handleSignIn() {
    autoValidate(true);
    setError(null);
    dispatch(loginUser(username, password));
  }

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      handleSignIn();
    }
  }

  const Footer = () => {
    return (
      <Grid
        container
        alignItems="center"
        alignContent="center"
        className={classes.footer}
      >
        <Grid item xs={5}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleSignIn()}
          >
            SIGN IN
          </Button>
        </Grid>
        <Grid xs item>
          <Link color="secondary" className={classes.link} onClick={() => {}}>
            TROUBLE SIGNING IN?
          </Link>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid className={classes.loginContainer}>
      <img src={LogoIcon} className={classes.logo} />
      <Typography className={classes.title}>Optimal HRV</Typography>
      <Grid className={classes.loginBody}>
        {(auth.isLoggingIn || loading) && (
          <Grid
            container
            className={classes.loader}
            justify="center"
            alignItems="center"
            alignContent="center"
          >
            <CircularProgress />
          </Grid>
        )}
        <>
          {(auth.loginError || error != null) && (
            <FormHelperText error={true} className={classes.error}>
              {error || "Invalid username and password combination."}
            </FormHelperText>
          )}
          <TextField
            label="USERNAME"
            type="email"
            name="username"
            fullWidth
            autoFocus
            style={{ fontSize: 22 }}
            value={username}
            {...defaultInputProps}
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            InputLabelProps={{
              classes: {
                formControl: classes.label,
              },
            }}
            error={!validation.username.isValid}
            helperText={validation.username.errMessage}
            onChange={(e) =>
              handleInputChange(InputKeys.USERNAME, e.target.value)
            }
            // onBlur={e =>
            //   validate(validation[InputKeys.USERNAME], e.target.value)
            // }
            onKeyPress={handleKeyPress}
          />
          <TextField
            label="PASSWORD"
            type="password"
            name="pwd"
            fullWidth
            value={password}
            error={!validation.password.isValid}
            helperText={validation.password.errMessage}
            {...defaultInputProps}
            InputProps={{
              classes: {
                input: classes.input,
              },
              type: showPassword ? "text" : "password",
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                formControl: classes.label,
              },
            }}
            onChange={(e) =>
              handleInputChange(InputKeys.PASSWORD, e.target.value)
            }
            // onBlur={e =>
            //   validate(validation[InputKeys.PASSWORD], e.target.value)
            // }
            onKeyPress={handleKeyPress}
          />
          <Footer />
        </>
      </Grid>
    </Grid>
  );
}

export default withMiddleware(Login);
