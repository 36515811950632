import { useState, useReducer } from "react";
import * as yup from "yup";
import { messages } from "./messages";
import _ from "lodash";

const validationObj = {
  validationStatus: false,
  errMessage: "",
  isValid: true
};

function generateSchema(key, type) {
  const validationType = {
    email: yup
      .string()
      .required(messages.required)
      .email(messages.enterEmail),
    text: yup.string().required(messages.required),
    password: yup
      .string()
      .required(messages.required)
      .min(6, messages.passwordPolicy)
      .matches(
        /(?=.*\d)(?=.*[A-Z])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/,
        messages.passwordPolicy
      ),
    time: yup
      .string()
      .required(messages.required)
      .matches(
        /^(((0[1-9])|(1[0-2])):([0-5])([0-9])\s(A|P)M)$/,
        messages.required
      ),
    domain: yup
      .string()
      .required(messages.required)
      .matches(/^[a-zA-Z0-9-]{1,61}(?:\.[a-zA-Z]{2,})+$/, messages.domain),
    alphaNumeric: yup
      .string()
      .required(messages.required)
      .matches(/^[a-zA-Z0-9_.]*$/, messages.alphaNumeric),
    name: yup
      .string()
      .required(messages.required)
      .matches(/^[a-zA-Z0-9_ -]*$/, messages.name)
  };
  return yup.object().shape({
    [key]: validationType[type]
  });
}

function addValidationProps(validationItems) {
  const validation = {};
  validationItems.forEach(validationItem => {
    validation[validationItem.key] = { ...validationObj, ...validationItem };
  });
  return validation;
}

export const useValidation = validationItems => {
  const [validationProps, dispatch] = useReducer((state, action) => {
    return { ...action.data };
  }, addValidationProps(validationItems));
  const [isValid, setIsValid] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  function validate(keys, value, show = false) {
    let newValdationProps = _.clone(validationProps);
    if (typeof keys == "object" && typeof value == "object") {
      keys.forEach((element, index) => {
        const status = getValidationStatus(element, value[index], show);
        Object.assign(newValdationProps, { [element.key]: status });
      });
    } else {
      const status = getValidationStatus(keys, value, show);
      newValdationProps[keys.key] = status;
    }
    const updatedValidationProps = newValdationProps;
    dispatch({
      data: updatedValidationProps
    });
    checkAllInputsAreValid(updatedValidationProps);
  }

  function getValidationStatus(element, value, show) {
    let schema = generateSchema(element.key, element.type);
    let status = { ...element, data: value };
    try {
      schema.validateSync({ [element.key]: value });
      status.isValid = true;
      status.validationStatus = true;
      status.errMessage = "";
      return status;
    } catch (err) {
      if (showErrors || show) {
        status.isValid = false;
        status.validationStatus = false;
        status.errMessage = err.message;
      }
      return status;
    }
  }

  function checkAllInputsAreValid(validationObj) {
    let values = _.values(validationObj);
    const invalidInputsCount = _.reject(values, e => e.validationStatus).length;
    const isAllValid = invalidInputsCount === 0;
    // if (isAllValid && !showErrors) autoValidate(true);
    setShowErrors(isAllValid);
    setIsValid(isAllValid);
  }

  function autoValidate(show) {
    setShowErrors(show);
    validateAllInputs();
  }

  function validateAllInputs() {
    const keys = Object.keys(validationProps);
    const data = [];
    const elements = [];
    keys.forEach(key => {
      const value = validationProps[key].data || "";
      data.push(value);
      elements.push(validationProps[key]);
    });
    validate(elements, data, true);
  }

  return {
    validation: validationProps,
    validate: validate,
    isValid: isValid,
    autoValidate: autoValidate
  };
};
