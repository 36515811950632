import React from "react";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";
import { useUtils } from "./utils";
import Routes from "./routes";
import { createGenerateId, JssProvider } from "react-jss";
import { ToastContainer, cssTransition } from "react-toastify";

const generateId = createGenerateId();

const Zoom = cssTransition({
  enter: "zoomIn",
  exit: "zoomOut",
  duration: 750
});

function App(props) {
  const { theme } = useUtils();
  return (
    <JssProvider generateId={generateId}>
      <MuiThemeProvider theme={theme}>
        <ToastContainer transition={Zoom} />
        <CssBaseline>
          <Routes />
        </CssBaseline>
      </MuiThemeProvider>
    </JssProvider>
  );
}

export default App;
