import _ from "lodash";

export const getOrgUsers = async (service, orgId) => {
  const res = await service.get(`/org/users?orgId=${orgId}&type=internal`);
  const users = organizeData(res);
  return users;
};

const organizeData = data => {
  const users = data.map(user => ({
    ...user,
    name: (user.first_name || "") + " " + (user.last_name || "")
  }));
  const orgAdmins = _.remove(users, ["user_type", "org"]);
  return { users, orgAdmins };
};

export const addGroup = async (service, body) => {
  return service.post(`/group/profile`, body);
};
