import React, { useState, useEffect, useContext } from "react";
import Table from "../../../../components/table";
import { getParticipants, removeParticipant, addParticipants } from "./handler";
import withMiddleware from "../../../../middleware";
import { UserContext } from "../../../../context";
import _ from "lodash";
import { Button, Grid, Dialog } from "@material-ui/core";
import AddParticipants from "./add-participants";
import { useStyles } from "./style";
import { useHistory } from "react-router-dom";
import Alert from "../../../../components/alert";
import { useUtils } from "../../../../utils";

const columns = [
  {
    title: "Name",
    field: "name",
  },
  {
    title: "Email",
    field: "email",
  },
];

function Active(props) {
  const classes = useStyles();
  const history = useHistory();
  const { showToast } = useUtils();
  const { service } = props;
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState();
  const [loading, setLoading] = useState(false);
  const [showRemove, setShowRemove] = useState(false);

  const user = useContext(UserContext);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    try {
      setLoading(true);
      const participants = await getParticipants(
        service,
        user.current_group_id,
        user.org_id
      );
      setData(participants);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      showToast("error", err.message);
    }
  }

  function rowActionHandler(e, data) {
    setRow(data);
    setShowRemove(true);
  }
  async function deletePariticipant() {
    try {
      setLoading(true);
      setShowRemove(false);
      const res = await removeParticipant(
        service,
        row.user_id,
        user.current_group_id,
        user.org_id
      );
      let users = _.clone(data);
      _.remove(users, (user) => user.user_id === row.user_id);
      setData(users);
      setLoading(false);
      showToast("success", res.message);
    } catch (err) {
      setLoading(false);
      showToast("error", err.message);
    }
  }

  async function updateParticipants(participants) {
    try {
      setLoading(true);
      setOpen(false);
      const ids = _.map(participants, (participant) => participant.user_id);
      const data = {
        orgId: user.org_id.toString(),
        groupId: user.current_group_id.toString(),
        userIds: ids,
      };
      const res = await addParticipants(service, data);
      init();
      setLoading(false);
      showToast("success", res.message);
    } catch (err) {
      setLoading(false);
      showToast("error", err.message);
    }
  }

  function navigateToUser(e, user) {
    history.push("/group/participants/profile", { user: user });
  }

  function isDefault() {
    return user["group_name"] === "Default";
  }

  return (
    <>
      <Grid container justify="flex-end" className={classes.btnRoot}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={isDefault()}
          onClick={() => setOpen(true)}
        >
          Add User
        </Button>
      </Grid>
      <Table
        columns={columns}
        data={data}
        loading={loading}
        actions={[
          {
            tootlTip: "Delete",
            icon: "delete",
            color: isDefault() ? "disabled" : "error",
            // hidden: data.length <= 1,
            disabled: isDefault(),
          },
        ]}
        onActionClick={rowActionHandler}
        onRowClick={navigateToUser}
      />
      <Dialog open={open}>
        <AddParticipants
          onOpen={(isOpen) => setOpen(isOpen)}
          user={user}
          onAddParticipants={updateParticipants}
        />
      </Dialog>
      <Alert
        title="Delete"
        content="Do you want to Remove this User from Group?"
        open={showRemove}
        onConfirm={deletePariticipant}
        onClose={() => setShowRemove(false)}
      />
    </>
  );
}

export default withMiddleware(Active);
