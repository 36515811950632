import React, { useState, useContext } from "react";
import {
  Menu,
  MenuItem,
  IconButton,
  useTheme,
  Grid,
  Typography,
  Divider,
  Chip
} from "@material-ui/core";
import { UserIcon } from "../../../assets/svg/user";
import { UserContext } from "../../../context";

function Menus(props) {
  const theme = useTheme();
  const { options, onMenuSelect } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const user = useContext(UserContext);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = id => {
    onMenuSelect(id);
    handleClose();
  };

  const getUserRole = type => {
    const role = {
      org: "Organization Admin",
      group: "Group Admin",
      super: "App Admin"
    };
    return role[type] || "";
  };

  return (
    <>
      <IconButton onClick={handleMenu}>
        <UserIcon
          size="32px"
          color={open ? "#808080" : theme.palette.primary.main}
        />
      </IconButton>
      <Menu
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        style={{ left: -10, top: 0 }}
        onClose={handleClose}
      >
        <Grid
          style={{
            position: "absolute",
            height: 32,
            width: 32,
            top: -14,
            right: -14
          }}
        >
          <UserIcon
            size="32px"
            color={open ? "#808080" : theme.palette.primary.main}
          />
        </Grid>
        <Grid
          container
          justify="center"
          style={{ width: 250, padding: 24 }}
          direction="column"
        >
          <Grid container justify="center">
            <UserIcon color={theme.palette.primary.main} size={56} />
          </Grid>
          <Grid container justify="center">
            <Typography style={{ paddingTop: 12 }}>
              {(user.first_name || "") + " " + (user.last_name || "")}
            </Typography>
          </Grid>
          <Grid container justify="center">
            <Typography variant="caption">{user.email || ""}</Typography>
          </Grid>
          <Grid container justify="center">
            {/* <Typography variant="caption"> */}
            <Chip
              label={getUserRole(user.user_type)}
              size="small"
              style={{ background: theme.palette.info.light, marginTop: 8 }}
            />
            {/* </Typography> */}
          </Grid>
        </Grid>
        <Divider />
        <Grid container justify="center" style={{ padding: 8 }}>
          {options.map((option, index) => (
            <div key={index}>
              {option.component ? (
                option.component
              ) : (
                <MenuItem onClick={() => handleChange(option.id)}>
                  {option.label}
                </MenuItem>
              )}
            </div>
          ))}
        </Grid>
      </Menu>
    </>
  );
}

export default Menus;
