import React, { useState } from "react";
import { Grid, Typography, Chip } from "@material-ui/core";
import { useStyles } from "./style";
import moment from "moment";
import { useUtils } from "../../utils";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";

function Readings(props) {
  const { colors } = useUtils();
  const classes = useStyles(colors);
  const { reading } = props;
  const [expand, setExpand] = useState(false);

  return (
    <Grid container className={classes.root}>
      <Grid container alignItems="center" alignContent="center">
        <Grid item xs={3}>
          <Typography className={classes.avgTypo}>
            {reading.rmssd} MS
          </Typography>
          <Typography style={{ fontSize: 12 }}>RMSSD</Typography>
        </Grid>
        <Grid item xs>
          <Grid style={{ paddingBottom: 6, justifyContent: "center" }}>
            <Typography
              className={classes.textTypo}
              style={{ textAlign: "right" }}
            >
              {moment(reading.created_at)
                .utc(false)
                .local()
                .format("MMM DD hh:mm A")
                .toUpperCase()}
            </Typography>
          </Grid>
          {reading.tags && reading.tags.length > 0 && (
            <Grid style={{ textAlign: "right" }}>
              {reading.tags
                .slice(0, expand ? reading.tags.length : 3)
                .map((tag) => (
                  <Chip
                    size="small"
                    color="primary"
                    label={tag.tag_name}
                    variant="outlined"
                    className={classes.tagChip}
                  />
                ))}
              {reading.tags.length > 3 && (
                <Chip
                  size="small"
                  label={
                    expand ? (
                      <ExpandLess className={classes.expandIcon} />
                    ) : (
                      <ExpandMore className={classes.expandIcon} />
                    )
                  }
                  color="primary"
                  className={classes.tagChip}
                  clickable={true}
                  onClick={() => setExpand(!expand)}
                />
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Readings;
