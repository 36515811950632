import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    background: colors => colors.hrv_white,
    padding: 16,
    border: colors => `${colors.hrv_border_color} solid 3px`,
    borderRadius: 10,
    marginBottom: 8,
    cursor: "pointer"
  },
  icon: {
    borderRadius: "50%",
    width: 40,
    height: 40,
    marginRight: 12
  },
  typoName: {
    fontSize: 16,
    fontWeight: 400
  },
  typoRmssd: {
    fontSize: 20,
    fontWeight: 500
  },
  typoAvg: {
    fontSize: 12,
    fontWeight: 400,
    paddingTop: 8,
    paddingLeft: 6
  },
  trendUp: {
    color: "#32bf11",
    transform: `rotateZ(-90deg)`
  },
  trendDown: {
    color: "#fd586f",
    transform: `rotateZ(90deg)`
  }
}));
