import React, { useState, useEffect, useContext } from "react";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import SendIcon from "../../../assets/images/send.png";
import ExitIcon from "../../../assets/images/exit.png";
import AddIcon from "../../../assets/images/activity-add-icon.png";
import ReceiveIcon from "../../../assets/images/activity-recieved-icon.png";
import { useStyles } from "./style";
import withMiddleware from "../../../middleware";
import moment from "moment";
import { getActivities } from "./handler";
import { UserContext } from "../../../context";
import { useUtils } from "../../../utils";

/* function Activity(props) {
  const classes = useStyles();
  const user = useContext(UserContext);
  const { showToast } = useUtils();
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    try {
      setLoading(true);
      const activities = await getActivities(props.service, user.user_id);
      setActivities(activities);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      showToast("error", err.message);
    }
  }

  function getActivityIcon(status) {
    const icons = {
      add: AddIcon,
      remove: ExitIcon,
      send: SendIcon,
      receive: ReceiveIcon
    };
    return <img src={icons[status]} className={classes.iconStyle} />;
  }

  return (
    <Grid className={classes.root}>
      {loading ? (
        <Grid className={classes.container}>
          <CircularProgress />
        </Grid>
      ) : activities.length > 0 ? (
        activities.map((activity, index) => (
          <Grid key={index}>
            <Typography className={classes.dateTypo}>
              {moment(activity.date, "MM-DD-YYYY").isSame(
                moment().format("MM-DD-YYYY")
              )
                ? "Today"
                : moment(activity.date, "MM-DD-YYYY").format("MMMM Do")}
            </Typography>
            <Grid className={classes.msg}>
              {activity.activity.map((item, index) => (
                <Grid container className={classes.childMsgGrid} key={index}>
                  {getActivityIcon(item.status)}
                  <Typography className={classes.time}>
                    {moment
                      .utc(item.created_at)
                      .local()
                      .format("HH:mm")}
                  </Typography>
                  <Typography variant="caption"> {item.message}</Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))
      ) : (
        <Grid className={classes.container}>No activities recorded!</Grid>
      )}
    </Grid>
  );
} */

function Activity(props) {
  //Coming soon page
  const classes = useStyles();
  return (
    <Grid className={classes.container}>
      <Typography>Coming soon</Typography>
    </Grid>
  );
}

export default withMiddleware(Activity);
