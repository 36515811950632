import { toast } from "react-toastify";
import "./style.css";

export const showToast = (type, message) => {
  toast(message, {
    type,
    position: "bottom-center",
    autoClose: 5000
  });
};
