import React from "react";
import ContentLoader from "react-content-loader";

const BoxLoader = props => {
  const { height = 100, width = 550 } = props;
  return (
    <ContentLoader speed={2} height={height} width={width}>
      <rect x="0" y="0" height="2" width={width} />
      <rect x="0" y={height - 4} height="2" width={width} />
      <rect x="0" y="0" height={height - 4} width={2} />
      <rect x={width - 4} y="0" height={height - 4} width={2} />
      <rect x="20" y={height / 2 - 5} height="10" width={width - 44} />
    </ContentLoader>
  );
};

export default BoxLoader;
