import React from "react";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { CalendarTodayRounded } from "@material-ui/icons";
import { IconButton, InputAdornment } from "@material-ui/core";

const Calendar = (props) => {
  const {
    label,
    value,
    fullWidth = false,
    onChange,
    otherProps,
    error,
    helperText,
    inputProps = {},
  } = props;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        variant="dialog"
        format="MM/DD/YYYY"
        // margin="dense"
        error={error || false}
        helperText={helperText || ""}
        label={label}
        inputProps={inputProps}
        inputVariant="outlined"
        size="small"
        fullWidth={fullWidth}
        value={value}
        maxDate={new Date()}
        // {...otherProps}
        onChange={(date) => {
          onChange(date);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size="small">
                <CalendarTodayRounded fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default Calendar;
