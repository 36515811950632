import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));

function ChartSettings(props) {
  const { defaultValues, onChange } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [values, setValues] = React.useState("");
  React.useEffect(() => {
    setValues(defaultValues);
  }, [defaultValues]);
  const handleChange = (key, event) => {
    setValues({ ...values, [key]: event.target.value });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    handleClose();
    typeof onChange === "function" && onChange(values);
  };

  return (
    <div>
      {/* <Button onClick={handleClickOpen}>Open select dialog</Button> */}
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClickOpen}
      >
        <SettingsIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Customize chart</DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <InputLabel id="reading-taken-time-label">
                Reading taken time
              </InputLabel>
              <Select
                labelId="reading-taken-time-label"
                id="reading-taken-time"
                value={values?.reading_taken_time}
                onChange={(e) => handleChange("reading_taken_time", e)}
                input={<Input />}
                autoWidth
              >
                <MenuItem value={"until_mid_day"}>Until mid day</MenuItem>
                <MenuItem value={"after_mid_day"}>After mid day</MenuItem>
                <MenuItem value={"whole_day"}>Whole day</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="chart-data-group-label">
                Chart data group
              </InputLabel>
              <Select
                labelId="chart-data-group-label"
                id="chart-data-group"
                value={values?.chart_data_group_by}
                onChange={(e) => handleChange("chart_data_group_by", e)}
                input={<Input />}
                autoWidth
              >
                <MenuItem value={"distinct"}>Distinct</MenuItem>
                <MenuItem value={"average"}>Average</MenuItem>
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default React.memo(ChartSettings);
