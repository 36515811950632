import React, { useEffect, useState } from "react";
import { useStyles } from "../style";
import { Grid, IconButton, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import clsx from "clsx";
import Table from "../../../../components/table";
import { getOrgAdmins, removeOrgAdmins } from "./handler";
import { useUtils } from "../../../../utils";
import withMiddleware from "../../../../middleware";
import _ from "lodash";

const columns = [
  {
    title: "Name",
    field: "name",
  },
  {
    title: "Username",
    field: "email",
  },
];

function AdminUsers(props) {
  const { colors, showToast } = useUtils();
  const classes = useStyles(colors);
  const { service, org, onOpen, onChangeAdmins, updateAdmin } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    init();
  }, [updateAdmin]);

  async function init() {
    try {
      setLoading(true);
      const admins = await getOrgAdmins(service, org.id);
      setData(admins);
      onChangeAdmins(admins);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      showToast("error", err.message);
    }
  }

  async function actionHandler(e, rowData) {
    try {
      setLoading(true);
      const res = await removeOrgAdmins(service, org.id, rowData.user_id);
      const admins = _.clone(data);
      _.remove(admins, (admin) => admin.user_id === rowData.user_id);
      setData(admins);
      onChangeAdmins(admins);
      setLoading(false);
      showToast("success", res.message);
    } catch (err) {
      showToast("error", err.message);
      setLoading(false);
    }
  }

  return (
    <Grid className={clsx(classes.container, classes.spacing)}>
      <Grid container alignContent="center" alignItems="center">
        <Grid item xs>
          <Typography>Admin Users ({data.length || 0})</Typography>
        </Grid>
        <Grid item>
          <IconButton color="primary" onClick={() => onOpen(true)}>
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid className={classes.table}>
        <Grid item>
          <Table
            columns={columns}
            data={data}
            loading={loading}
            actions={[
              {
                tootlTip: "Remove Admin",
                icon: "close",
                color: "error",
                hidden: data.length <= 1,
              },
            ]}
            onActionClick={actionHandler}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withMiddleware(AdminUsers);
