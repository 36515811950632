import React from "react";
import { useStyles } from "./style";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";

function SelectComponent(props) {
  const classes = useStyles();
  const { onChange, value, disabled = false, options = [], label } = props;
  return (
    <FormControl variant="outlined" size="small" fullWidth>
      <InputLabel htmlFor="outlined-age-native-simple">{label}</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        label={label}
        inputProps={{
          name: label,
          id: "outlined-age-native-simple",
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={option.id.toString()}
            disabled={option.id.toString() === value}
          >
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectComponent;
