import React, { useState, useEffect } from "react";
import { useStyles } from "./style";
import { Tabs, Tab, Typography } from "@material-ui/core";
import _ from "lodash";
import Active from "./active";
import Requests from "./requests";
import { useUtils } from "../../../utils";
import { useSelector } from "react-redux";

const tabs = [
  {
    label: "Active",
    type: "active",
    component: <Active />,
  },
  {
    label: "Join Requests",
    type: "requests",
    component: <Requests />,
  },
];

function Participants(props) {
  const { colors } = useUtils();
  const classes = useStyles(colors);
  const { notification } = useSelector((state) => ({
    notification: state.app.notification,
  }));
  const [activeTab, setActiveTab] = useState(tabs[0]);

  function getLabel(label, type) {
    const count = notification.count ? `(${notification.count})` : "";
    return (
      <span>
        {label}
        {type === "requests" && (
          <span className={classes.badgeNumber}> {count}</span>
        )}
      </span>
    );
  }

  return (
    <>
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        value={activeTab.label}
        classes={{
          indicator: classes.indicator,
        }}
        className={classes.tab}
        onChange={(e, value) =>
          setActiveTab(tabs[_.findIndex(tabs, { label: value })])
        }
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={getLabel(tab.label, tab.type)}
            value={tab.label}
          />
        ))}
      </Tabs>
      <div className={classes.root}>{activeTab.component}</div>
    </>
  );
}

export default Participants;
