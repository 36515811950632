import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Button,
  Dialog,
  TextField,
  DialogActions,
  FormControl,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/VisibilityOutlined";
import VisibilityOff from "@material-ui/icons/VisibilityOffOutlined";
import { useStyles } from "../style";
import { validationInput } from "./validation";
import { useValidation } from "../../../../components/validation";

function ChangePassword(props) {
  const classes = useStyles();
  const { validation, validate, autoValidate, isValid } = useValidation(
    validationInput
  );
  const [isNotMatch, setIsNotMatch] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState({
    password: "",
    confirmPassword: "",
  });
  const {
    openChangePassword,
    closeHandler,
    changePasswordHandler,
    loader,
  } = props;

  function handler() {
    setIsNotMatch(false);
    autoValidate(true);
    if (isValid) {
      if (password.password === password.confirmPassword) {
        changePasswordHandler(password);
      } else {
        setIsNotMatch(true);
      }
    }
  }
  return (
    <div className={classes.root}>
      {loader && (
        <Grid
          container
          className={classes.loader}
          justify="center"
          alignItems="center"
          alignContent="center"
        >
          <CircularProgress />
        </Grid>
      )}
      <Dialog maxWidth="sm" fullWidth open={openChangePassword}>
        <DialogTitle id="alert-dialog-title" className={classes.dialogHeader}>
          {"Reset Password"}
        </DialogTitle>
        <DialogContent className={classes.paddingSpacer}>
          <FormControl fullWidth>
            {isNotMatch && (
              <FormHelperText error className={classes.error}>
                Password doesn't match
              </FormHelperText>
            )}
            <TextField
              id="changePassword"
              label="Password"
              className={classes.bottomSpacer}
              variant="outlined"
              fullWidth
              type="password"
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
              error={!validation.changePassword.isValid}
              helperText={validation.changePassword.errMessage}
              onBlur={(e) =>
                validate(validation["changePassword"], e.target.value)
              }
              onChange={(e) =>
                setPassword({ ...password, password: e.target.value })
              }
            />
            <TextField
              id="changePasswordConfirm"
              label="Confirm Password"
              variant="outlined"
              className={classes.bottomSpacer}
              type={showPassword ? "text" : "password"}
              fullWidth
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={!validation.changePasswordConfirm.isValid}
              helperText={validation.changePasswordConfirm.errMessage}
              onBlur={(e) =>
                validate(validation["changePasswordConfirm"], e.target.value)
              }
              onChange={(e) =>
                setPassword({ ...password, confirmPassword: e.target.value })
              }
            />
          </FormControl>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button onClick={handler} color="primary" variant="contained">
            Reset
          </Button>
          <Button
            onClick={() => closeHandler(false)}
            variant="contained"
            className={classes.actionButton}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ChangePassword;
