import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  drawerItemStyle: {
    padding: "24px 0",
    flexDirection: "column",
    textAlign: "center",
    "& div span": {
      color: colors => colors.hrv_white,
      fontWeight: 500,
      // opacity: 0.5,
      fontSize: 12,
      textAlign: "center",
      display: "block",
      position: "relative"
    },
    "& svg": {
      fill: colors => colors.hrv_white,
      opacity: 0.5,
      marginRight: 16
    },
    "&:hover": {
      background: "transparent",
      "& div span": {
        opacity: 0.8
      },
      "& svg": {
        opacity: 0.8
      }
    },
    "& img": {
      width: 24,
      margin: "0 auto",
      opacity: 0.5
    }
  },
  drawerStyleActiveItem: {
    "& div span": {
      color: colors => colors.hrv_white,
      opacity: 1,
      fontWeight: 600,
      fontSize: 14
    },
    "& svg": {
      fill: colors => colors.hrv_white,
      opacity: 1
    },
    "&:hover": {
      background: "transparent",
      "& div span": {
        opacity: 1
      },
      "& svg": {
        opacity: 1
      }
    },
    "& img": {
      opacity: 1
    }
  },
  textStyle: {
    padding: 0,
    opacity: 0.5
  },
  activeTextStyle: {
    padding: 0,
    opacity: 1
  },
  badge: {
    position: "absolute !important",
    background: "#fd5b6d",
    display: "flex !important",
    alignContent: "center",
    alignItems: "center"
  }
});
