import React from "react";
import { useStyles } from "./style";
import { useUtils } from "../../../utils";
import NoReadingsIcon from "../../../assets/images/no-readings.png";
import { Grid, Typography } from "@material-ui/core";

function NoReadings(props) {
  const { colors } = useUtils();
  const classes = useStyles(colors);
  return (
    <Grid
      container
      justify="center"
      alignContent="center"
      alignItems="center"
      direction="column"
      className={classes.noReadings}
    >
      <img src={NoReadingsIcon} height={44} />
      <Typography color="textSecondary" className={classes.typoMessage}>
        No Readings
      </Typography>
    </Grid>
  );
}

export default NoReadings;
