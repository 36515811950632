import moment from "moment";
import { getRandomColorForLine } from "../../../utils/index.js";

export default class ReportsHandlers {
  service = null;
  startDate = null;
  endDate = null;
  orgId = null;
  #query = "";
  settings = {};
  constructor(service, orgId, startDate, endDate, settings = {}) {
    this.service = service;
    this.orgId = orgId;
    this.startDate = startDate;
    this.endDate = endDate;
    this.#query = this.queryParams();
    this.settings = settings;
  }

  queryParams() {
    const params = [
      { name: "from", value: this.startDate },
      { name: "to", value: this.endDate },
    ];
    const formattedParams = params
      .filter((param) => param.value?.length)
      .map((param) => {
        return `${param?.name}=${param?.value}`;
      });
    return formattedParams.join("&");
  }

  getUsersList = async () => {
    const users = await this.service.get(
      `/org/users?orgId=${this.orgId}&type=all`
    );
    return users;
  };

  getExerciseTagsList = async () => {
    const tags = await this.service.get(`/tags`);
    return tags;
  };

  getUserRmssd = async (user_id) => {
    const rmssd = await this.service.get(
      `/user/rmssd?user_id=${user_id}${this.#query}`
    );
    return rmssd;
  };

  getUsersRmssd = async (users = []) => {
    return Promise.all(
      users.map(async (user, index) => {
        const nUser = {};
        nUser.user = { ...user, lineColor: getRandomColorForLine(index) };
        const userRmssdHistory = await this.getUserRmssd(user.user_id);
        nUser.readings = Array.isArray(userRmssdHistory?.readings)
          ? userRmssdHistory?.readings
              .sort((a, b) => {
                return new Date(a.created_at) - new Date(b.created_at);
              })
              .map((rt) => {
                return {
                  reading_result: Number(Number(rt?.rmssd).toFixed(2)),
                  dateTime: moment(rt?.created_at, "Y-MM-DD HH:mm:ss").format("Y-MM-DD HH:mm"),
                  tags: rt?.tags,
                  tooltipText: `${user?.first_name} ${user?.last_name} RMSSD`,
                  userId: user.user_id,
                  minMax: rt?.time_domain?.minMax,
                  lf: rt?.frequency_domain?.lf,
                  hf: rt?.frequency_domain?.hf,
                  vlf: rt?.frequency_domain?.vlf,
                  totalPower: rt?.frequency_domain?.total_power,
                  /* lfnu: rt?.frequency_domain?.lfnu,
                  hfnu: rt?.frequency_domain?.hfnu, */
                  sdnn: rt?.time_domain?.sdnn,
                  sdsd: rt?.time_domain?.sdsd,
                  rmssd: rt?.rmssd,
                  meanHr: rt?.time_domain?.mean_hr,
                  maxHr: rt?.time_domain?.max_hr,
                  minHr: rt?.time_domain?.min_hr,
                  meanNni: rt?.time_domain?.mean_nni,
                  medianNni: rt?.time_domain?.median_nni,
                  rangeNni: rt?.time_domain?.range_nni,
                };
              })
          : [];
        return nUser;
      })
    );
  };
  getUserBioFeedback = async (user_id) => {
    const bioFeedbackHistory = await this.service.get(
      `/selected-history?user_id=${user_id}&type=exercise&${this.#query}`
    );
    return bioFeedbackHistory;
  };

  getUserAllTimeAverageFroBiofeedback = async (user_id) => {
    const allTimeAverageForBiofeedback = await this.service.get(
      `/hrv_analytics/biofeedback/all-time?userId=${user_id}&sessionType=exercise&${
        this.#query
      }`
    );
    return allTimeAverageForBiofeedback;
  };

  getUsersBioFeedbackHistory = async (users = []) => {
    return Promise.all(
      users.map(async (user, index) => {
        const nUser = {};
        nUser.user = { ...user, lineColor: getRandomColorForLine(index) };
        const userBioFeedbackHistory = await this.getUserBioFeedback(
          user.user_id
        );
        const userAllTimeAverageForBiofeedback =
          await this.getUserAllTimeAverageFroBiofeedback(user.user_id);
        nUser.readings = userBioFeedbackHistory
          .map((bfh) => {
            return Array.isArray(bfh?.exercises)
              ? bfh?.exercises
                  .sort((a, b) => {
                    return new Date(a.createdAt) - new Date(b.createdAt);
                  })
                  .map((rt) => {
                    return {
                      dateTime: moment(
                        bfh?.createdAt,
                        "Y-MM-DD HH:mm:ss"
                      ).format("Y-MM-DD"),
                      createdAt: bfh?.createdAt,
                      breathsPerMinute: bfh?.breathsPerMinute,
                      sessionId: bfh?.sessionId,
                      sessionMinutes: bfh?.sessionMinutes,
                      optimalZone: bfh?.optimalZoneSessionAverage,
                      ozSesAvgPercentage: bfh?.ozSesAvgPercentage || null,
                      tags: rt?.tags,
                      tooltipText: `${user?.first_name} ${user?.last_name} BioFeedback`,
                      userId: user.user_id,
                      rmssd: rt.averages?.rmssd,
                      lf: rt.averages?.lf,
                      hf: rt.averages?.hf,
                      vlf: rt.averages?.vlf,
                      minMax: rt.averages?.minMax,
                      sdnn: rt.averages?.sdnn,
                      name: rt.breathTime?.name,
                      inhalePercentage: rt.breathTime?.inhalePercentage,
                      exhalePercentage: rt.breathTime?.exhalePercentage,
                      inhaleSeconds: rt.breathTime?.inhaleSeconds,
                      exhaleSeconds: rt.breathTime?.exhaleSeconds,
                      defaultTotalMinutes: rt.breathTime?.defaultTotalMinutes,
                      results: rt.results.map((domain) => {
                        return {
                          tooltipText: `${user?.first_name} ${user?.last_name} BioFeedback`,
                          dateTime: moment(
                            domain?.countTime,
                            "Y-MM-DD HH:mm:ss"
                          ).format("Y-MM-DD"),
                          createdAt: domain?.countTime,
                          lf: domain?.frequencyDomain.lf,
                          hf: domain?.frequencyDomain.hf,
                          vlf: domain?.frequencyDomain.vlf,
                          totalPower: domain?.frequencyDomain.totalPower,
                          /* lfnu: domain?.frequencyDomain.lfnu,
                        hfnu: domain?.frequencyDomain.hfnu, */
                          sdnn: domain?.timeDomain.sdnn,
                          sdsd: domain?.timeDomain.sdsd,
                          rmssd: domain?.timeDomain.rmssd,
                          meanHr: domain?.timeDomain.meanHr,
                          maxHr: domain?.timeDomain.maxHr,
                          minHr: domain?.timeDomain.minHr,
                          meanNni: domain?.timeDomain.meanNni,
                          medianNni: domain?.timeDomain.medianNni,
                          rangeNni: domain?.timeDomain.rangeNni,
                        };
                      }),
                    };
                  })
              : [];
          })
          .flat();
        nUser.allTimeOzPercentage =
          userAllTimeAverageForBiofeedback?.alltimeozpercentage || 0;
        return nUser;
      })
    );
  };

  getUserMindfullness = async (user_id) => {
    const mindfullnessHistory = await this.service.get(
      `/selected-history?user_id=${user_id}&type=mindfulness&${this.#query}`
    );
    return mindfullnessHistory;
  };

  getUserAllTimeAverageFroMindfulness = async (user_id) => {
    const allTimeAverageForMindfulness = await this.service.get(
      `/hrv_analytics/biofeedback/all-time?userId=${user_id}&sessionType=mindfulness&${
        this.#query
      }`
    );
    return allTimeAverageForMindfulness;
  };

  getUsersMindfullnessHistory = async (users = []) => {
    return Promise.all(
      users.map(async (user, index) => {
        const nUser = {};
        nUser.user = { ...user, lineColor: getRandomColorForLine(index) };
        const userMindfullnessHistory = await this.getUserMindfullness(
          user.user_id
        );
        const userAllTimeAverageForMindfulness =
          await this.getUserAllTimeAverageFroMindfulness(user.user_id);
        nUser.readings = userMindfullnessHistory
          .map((mfh) => {
            return Array.isArray(mfh?.exercises)
              ? mfh?.exercises
                  .sort((a, b) => {
                    return new Date(a.createdAt) - new Date(b.createdAt);
                  })
                  .map((rt) => {
                    return {
                      dateTime: moment(
                        mfh?.createdAt,
                        "Y-MM-DD HH:mm:ss"
                      ).format("Y-MM-DD"),
                      createdAt: mfh?.createdAt,
                      breathsPerMinute: mfh?.breathsPerMinute,
                      sessionId: mfh?.sessionId,
                      sessionMinutes: mfh?.sessionMinutes,
                      optimalZone: mfh?.optimalZoneSessionAverage,
                      ozSesAvgPercentage: mfh?.ozSesAvgPercentage || null,
                      tags: rt?.tags,
                      tooltipText: `${user?.first_name} ${user?.last_name} Mindfullness`,
                      userId: user.user_id,
                      rmssd: rt.averages?.rmssd,
                      lf: rt.averages?.lf,
                      hf: rt.averages?.hf,
                      vlf: rt.averages?.vlf,
                      minMax: rt.averages?.minMax,
                      sdnn: rt.averages?.sdnn,
                      name: rt.breathTime?.name,
                      inhalePercentage: rt.breathTime?.inhalePercentage,
                      exhalePercentage: rt.breathTime?.exhalePercentage,
                      inhaleSeconds: rt.breathTime?.inhaleSeconds,
                      exhaleSeconds: rt.breathTime?.exhaleSeconds,
                      defaultTotalMinutes: rt.breathTime?.defaultTotalMinutes,
                      results: rt.results.map((domain) => {
                        return {
                          tooltipText: `${user?.first_name} ${user?.last_name} BioFeedback`,
                          dateTime: moment(
                            domain?.countTime,
                            "Y-MM-DD HH:mm:ss"
                          ).format("Y-MM-DD"),
                          createdAt: domain?.countTime,
                          lf: domain?.frequencyDomain.lf,
                          hf: domain?.frequencyDomain.hf,
                          vlf: domain?.frequencyDomain.vlf,
                          totalPower: domain?.frequencyDomain.totalPower,
                          /* lfnu: domain?.frequencyDomain.lfnu,
                        hfnu: domain?.frequencyDomain.hfnu, */
                          sdnn: domain?.timeDomain.sdnn,
                          sdsd: domain?.timeDomain.sdsd,
                          rmssd: domain?.timeDomain.rmssd,
                          meanHr: domain?.timeDomain.meanHr,
                          maxHr: domain?.timeDomain.maxHr,
                          minHr: domain?.timeDomain.minHr,
                          meanNni: domain?.timeDomain.meanNni,
                          medianNni: domain?.timeDomain.medianNni,
                          rangeNni: domain?.timeDomain.rangeNni,
                        };
                      }),
                    };
                  })
              : [];
          })
          .flat();
        nUser.allTimeOzPercentage =
          userAllTimeAverageForMindfulness?.alltimeozpercentage || 0;
        return nUser;
      })
    );
  };
}
