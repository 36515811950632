import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    padding: "135px 24px 24px 138px"
  },
  indicator: {
    height: 4,
    borderRadius: 10,
    width: "30px!important",
    bottom: 6
  },
  tab: {
    backgroundColor: colors => colors.hrv_light_gray,
    width: "100%",
    position: "fixed",
    top: 65,
    left: 114,
    paddingLeft: 24,
    zIndex: 11,
    "& button": {
      paddingLeft: 0,
      paddingRight: 36,
      minWidth: 0
    },
    "& span": {
      alignItems: "flex-start",
      fontSize: 13,
      fontWeight: 700,
      color: "#000"
    }
  },
  badgeNumber: {
    color: "#fd5b6d !important"
  }
}));
