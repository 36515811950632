import React, { useContext, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { UserContext } from "../../../../context/index";
import { roundAndCoalesce } from "../../../../utils";
import { allGroupsWithTheirHRV } from "../handler";
import { useUtils } from "../../../../utils";
import TablePagination from "@material-ui/core/TablePagination";
import { PlayArrowSharp } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

const columns = [
  {
    field: "isTrending",
    headerName: "Trend",
    minWidth: 160,
    type: "boolean",
    flex: 1,
    renderCell: (params) => {
      return params?.row?.isTrending !== null ? (
        <PlayArrowSharp
          style={
            params?.row?.isTrending
              ? {
                  color: "#32bf11",
                  transform: `rotateZ(-90deg)`,
                }
              : {
                  color: "#fd586f",
                  transform: `rotateZ(90deg)`,
                }
          }
        />
      ) : (
        "N/A"
      );
    },
  },
  {
    field: "groupName",
    headerName: "Group",
    minWidth: 160,
    flex: 1,
  },
  {
    field: "latest",
    headerName: "Latest",
    description: "Latest HRV",
    minWidth: 120,
    type: "number",
    hideSortIcons: true,
    flex: 1,
  },
  {
    field: "previous",
    headerName: "Previous",
    description: "Previous HRV",
    type: "number",
    width: 170,
    hideSortIcons: true,
    flex: 1,
  },
  {
    field: "last-week",
    headerName: "Last week",
    description: "Last week HRV",
    type: "number",
    width: 170,
    hideSortIcons: true,
    flex: 1,
  },
  {
    field: "last-month",
    headerName: "Last month",
    description: "Last month HRV",
    type: "number",
    width: 170,
    hideSortIcons: true,
    flex: 1,
  },
  {
    field: "all-time",
    headerName: "All time",
    description: "All time HRV",
    type: "number",
    width: 170,
    hideSortIcons: true,
    flex: 1,
  },
];

const useStyles = makeStyles({
  root: {
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    borderRadius: 10,
    border: 0,
  },
});

function GroupsWithHRV(props) {
  const { onRowClick } = props;
  const [loading, setLoading] = React.useState(false);
  const [groups, setGroups] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const user = useContext(UserContext);
  const { showToast } = useUtils();
  const classes = useStyles();
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const data = await allGroupsWithTheirHRV(props.service, {
          orgId: user.current_org_id,
          limit: rowsPerPage,
          offset: rowsPerPage * page,
        });
        setGroups(data.list);
        setTotalPages(data.noOfRows);
        setLoading(false);
      } catch (err) {
        showToast("error", err.message);
        setLoading(false);
      }
    })();
  }, [props.service, showToast, user, rowsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const rowsToDataGrid = React.useMemo(() => {
    return groups.map((group) => {
      const {
        group_id: id,
        name: groupName,
        latest_avg_rmssd: latest,
        yesterday_avg_rmssd: previous,
        last_week_avg_rmssd: lastWeek,
        last_month_avg_rmssd: lastMonth,
        all_time_avg_rmssd: allTime,
      } = group;
      return {
        id,
        isTrending: latest && lastWeek ? latest > lastWeek : null,
        groupName,
        latest: roundAndCoalesce(latest),
        previous: roundAndCoalesce(previous),
        "last-week": roundAndCoalesce(lastWeek),
        "last-month": roundAndCoalesce(lastMonth),
        "all-time": roundAndCoalesce(allTime),
      };
    });
  }, [groups]);
  const [sortModel, setSortModel] = React.useState([
    {
      field: "isTrending",
      sort: "asc",
    },
  ]);
  return (
    <React.Fragment>
      <DataGrid
        rows={rowsToDataGrid}
        columns={columns}
        pageSize={rowsPerPage}
        autoHeight
        loading={loading}
        disableSelectionOnClick
        density="compact"
        onRowClick={(params) => {
          onRowClick(params.row);
        }}
        components={{
          Pagination: () => (
            <CustomPagination
              {...{
                page,
                rowsPerPage,
                handleChangePage,
                handleChangeRowsPerPage,
                count: totalPages,
              }}
            />
          ),
        }}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        className={classes.root}
      />
    </React.Fragment>
  );
}

function CustomPagination(props) {
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, count } = props;
  return (
    <TablePagination
      component="div"
      rowsPerPageOptions={[5, 10, 20, 40, 50, 100]}
      count={count}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
}
export default React.memo(GroupsWithHRV);
