export const getRequests = async (service, groupId) => {
  const res = await service.get(`/request?groupId=${groupId}&userId=-1`);
  const data = organizeData(res);
  return data;
};

export const updateRequestStatus = async (service, data) => {
  const options = await service.put(`/request`, data);
  return options;
};

const organizeData = users => {
  const data = users.map(user => ({
    ...user,
    name: (user.first_name || "") + " " + (user.last_name || "")
  }));
  return data;
};
