import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import Table from "../../../../components/table";
import { useStyles } from "../style";

function ImportError(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { openImportError, closeHandler, data } = props;

  return (
    <div className={classes.root}>
      <Dialog maxWidth="sm" fullWidth open={openImportError}>
        <DialogTitle id="alert-dialog-title" className={classes.dialogHeader}>
          {"Import Error"}
        </DialogTitle>
        <DialogContent className={classes.dialogSpacer}>
          <Table
            columns={[
              {
                title: "Username",
                field: "email"
              },
              {
                title: "Reason",
                field: "reason"
              }
            ]}
            data={data}
            options={{
              search: true,
              toolbar: true
            }}
            headerStyle={{
              backgroundColor: theme.palette.info.light,
              color: theme.palette.secondary.main
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => closeHandler(false)}
            color="secondry"
            autoFocus
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ImportError;
