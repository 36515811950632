import React from "react";
import { useStyles } from "./style";
import { Typography, Grid } from "@material-ui/core";
import UploadImage from "../../../assets/images/upload.png";
import AddIcon from "@material-ui/icons/Add";

function ImportUsers(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid xs={6} item className={classes.center}>
        <Grid className={classes.searchContainer}>
          <Grid container className={classes.importMainGrid}>
            <Grid container>
              <Typography variant={"h6"} style={{ color: "#000" }}>
                Import data from
              </Typography>
            </Grid>
            <Grid container>
              <Typography variant={"body2"}>
                Having problems with our import? Please send us a query.
              </Typography>
            </Grid>
            <Grid
              container
              alignItems="center"
              justify="center"
              direction="column"
              className={classes.uploadGrid}
            >
              <Grid item className={classes.uploadImgGrid}>
                <img
                  alt="Upload file"
                  src={UploadImage}
                  height={50}
                  width={50}
                />
              </Grid>
              <Grid item className={classes.iconGrid}>
                <label htmlFor="file" className={classes.fileContainer}>
                  Upload
                </label>
                <input
                  type="file"
                  id="file"
                  name="file"
                  style={{ display: "none" }}
                  onChange={() => {}}
                />
              </Grid>
              <Grid item className={classes.iconGrid}>
                <Typography variant={"body2"}>
                  Only .csv files allowed. Max 10 MB
                </Typography>
              </Grid>
              <Grid item className={classes.addUserGrid} onClick={() => {}}>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  className={classes.addUserIconGrid}
                >
                  <Grid item>
                    <AddIcon />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2">Add Single User</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default ImportUsers;
