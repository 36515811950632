import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function UsersAutocomplete(props) {
  const { onUsersChange, allUsers } = props;
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  const handleChange = (_event, newOptions, reason) => {
    if (newOptions.length <= 5) {
      setSelectedOptions(newOptions);
      if (reason === "clear" || reason === "remove-option") {
        typeof onUsersChange === "function" && onUsersChange(newOptions);
      }
    }
  };

  return (
    <Autocomplete
      multiple
      id="users-list"
      options={allUsers}
      getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {`${option.first_name} ${option.last_name}`}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Users"
          placeholder="Select users"
        />
      )}
      onChange={handleChange}
      value={selectedOptions}
      limitTags={4}
      onClose={() => {
        typeof onUsersChange === "function" && onUsersChange(selectedOptions);
      }}
      disableCloseOnSelect
    />
  );
}

export default React.memo(UsersAutocomplete);
