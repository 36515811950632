const loginHandler = async service => {
  const user = await service.get("/user/profile");
  const route = getRoutes(user["user_type"]);
  return { user, route };
};

const getRoutes = userType => {
  const routes = {
    org: "/org",
    group: "/group",
    super: "/admin"
  };
  return routes[userType] || "/";
};

export default loginHandler;
