import constants from "../constants";
import { box, randomBytes } from "tweetnacl";
import { decodeUTF8, encodeBase64 } from "tweetnacl-util";

export const getEncPassword = password => {
  const secretKey = new Uint8Array(constants.SECRET_KEY);
  const publicKey = new Uint8Array(constants.PUBLIC_KEY);

  const newNonce = () => randomBytes(box.nonceLength);
  const value = decodeURIComponent(password);
  const secretOrSharedKey = box.before(publicKey, secretKey);

  const nonce = newNonce();
  const messageUint8 = decodeUTF8(value);
  const encrypted = box.after(messageUint8, nonce, secretOrSharedKey);

  const fullMessage = new Uint8Array(nonce.length + encrypted.length);
  fullMessage.set(nonce);
  fullMessage.set(encrypted, nonce.length);
  const base64FullMessage = encodeBase64(fullMessage);
  return encodeURIComponent(base64FullMessage);
};
