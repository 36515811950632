import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from "@material-ui/core";
import { useStyles } from "./style";

function Alert(props) {
  const { title, content, open = false, onConfirm, onClose } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.dialogRemove
      }}
      onClose={onClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        classes={{
          root: classes.contentRoot
        }}
      >
        {content}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} className={classes.button}>
          No
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onConfirm}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Alert;
