import { baseTheme, colors } from "./theme";
import CONSTANT from "./constants";
import { getEncPassword } from "./encrypt";
import { showToast } from "./toast";
import _ from "lodash";
import * as moment from "moment";

export const useUtils = () => {
  return {
    theme: baseTheme,
    colors: colors,
    constant: CONSTANT,
    getEncPassword,
    showToast,
  };
};

const chartColors = [
  "#f05453",
  "#0087a1",
  "#06D6A0",
  "#FFD166",
  "#5995ED",
  "#044389",
  "#FFAD05",
  "#2E5077",
  "#D7E8BA",
  "#611C35",
  "#EF476F",
  "#118AB2",
];
export const getRandomColorForLine = (index) => {
  return chartColors?.[index] || `#${Math.floor(Math.random() * 16777215).toString(16)}`;
};

export const roundAndCoalesce = (value, precision = 2) => {
  if (value === null || value === undefined) {
    return "N/A";
  }
  return parseFloat(value.toFixed(precision));
};

export function roundToTwo(num) {
  return +(Math.round(num + "e+2") + "e-2");
}

export const getBiofeedbackFormattedDataForExport = (data) => {
  return data
    ?.map((innerArray) => {
      return innerArray.map((item) => {
        const sessionsCount = item.results?.length;
        const averages = {
          totalPower: sessionsCount ? _.sumBy(item.results, "totalPower") / sessionsCount : 0,
          rmssd: sessionsCount ? _.sumBy(item.results, "rmssd") / sessionsCount : 0,
          sdsd: sessionsCount ? _.sumBy(item.results, "sdsd") / sessionsCount : 0,
          meanHr: sessionsCount ? _.sumBy(item.results, "meanHr") / sessionsCount : 0,
          maxHr: sessionsCount ? _.sumBy(item.results, "maxHr") / sessionsCount : 0,
          minHr: sessionsCount ? _.sumBy(item.results, "minHr") / sessionsCount : 0,
          meanNni: sessionsCount ? _.sumBy(item.results, "meanNni") / sessionsCount : 0,
          medianNni: sessionsCount ? _.sumBy(item.results, "medianNni") / sessionsCount : 0,
          rangeNni: sessionsCount ? _.sumBy(item.results, "rangeNni") / sessionsCount : 0,
        };
        return {
          userId: item.userId,
          dateTime: moment(item.createdAt).format("Y-MM-DD HH:mm"),
          ozSesAvgPercentage: item.ozSesAvgPercentage,
          readingResult: item?.rmssd,
          rmssd: item.rmssd,
          totalPower: roundToTwo(averages?.totalPower),
          lf: item.lf,
          hf: item.hf,
          vlf: item.vlf,
          "HF%": `${Math.round((item.hf / averages?.totalPower) * 100)}%`,
          "LF%": `${Math.round((item.lf / averages?.totalPower) * 100)}%`,
          "VLF%": `${Math.round((item.vlf / averages?.totalPower) * 100)}%`,
          meanHr: roundToTwo(averages?.meanHr),
          maxHr: roundToTwo(averages?.maxHr),
          minHr: roundToTwo(averages?.minHr),
          minHr: roundToTwo(averages?.minHr),
          minMax: item.minMax,
          sdnn: item.sdnn,
          meanNni: roundToTwo(averages?.meanNni),
          medianNni: roundToTwo(averages?.medianNni),
          rangeNni: roundToTwo(averages?.rangeNni),
          tooltipText: item.tooltipText,

          breathsPerMinute: item.breathsPerMinute,
          sessionMinutes: item.sessionMinutes,
          sessionId: item.sessionId,
          name: item.name,
          inhalePercentage: item.inhalePercentage,
          exhalePercentage: item.exhalePercentage,
          inhaleSeconds: item.inhaleSeconds,
          exhaleSeconds: item.exhaleSeconds,
          defaultTotalMinutes: item.defaultTotalMinutes,

          // sessionName: item.sessionName,
          // userId: item.userId,
          // optimalZone: item.ozSesAvgPercentage,
          // minMax: item.minMax,
          // frequencyLf: item.results.map((result) => result.lf),
          // frequencyHf: item.results.map((result) => result.hf),
        };
      });
    })
    ?.flat();
};