import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Drawer, List, Grid } from "@material-ui/core";
import _ from "lodash";
import { useStyles } from "./style";
import MenuItemComponent from "./menu-item";
import AppLogo from "../../assets/images/logo.png";
import ContentLoader from "react-content-loader";

const DrawerComponent = props => {
  const classes = useStyles();
  const { menuItems = [], onChangeMenu } = props;
  const [activeMenu, setActiveMenu] = useState(menuItems[0]);

  useEffect(() => {
    setActiveMenu(getActiveMenu());
  }, [props]);

  function getActiveMenu() {
    const path = window.location.pathname;
    const menu = menuItems.filter(item => {
      if (item.path === path || item.sub === path) {
        return item;
      }
    });
    if (menu.length) return menu[0];
    return menuItems[0];
  }
  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      open={true}
      anchor="left"
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        alignContent="flex-start"
        className={classes.logoStyle}
      >
        <img src={AppLogo} />
      </Grid>

      <List className={classes.listContainer}>
        {menuItems.map((item, index) => (
          <MenuItemComponent
            key={index}
            menuItem={item}
            isActive={
              activeMenu && activeMenu.title
                ? item.title === activeMenu.title
                : false
            }
            iconComponent={item.icon}
            onChangeMenu={menuItem => {
              setActiveMenu(menuItem);
              onChangeMenu(menuItem);
            }}
          />
        ))}
      </List>
    </Drawer>
  );
};

export default withRouter(DrawerComponent);
