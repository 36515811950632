const columns = [
  {
    title: "First name",
    field: "first_name"
  },
  {
    title: "Last name",
    field: "last_name"
  },
  {
    title: "Email",
    field: "email"
  }
];

const getAdminUsers = async (service, groupId, orgId) => {
  const adminList = await service.get(
    `/group/admins?orgId=${orgId}&groupId=${groupId}`
  );
  const admins = adminList.map(admin => {
    if (admin.user_type === "org") {
      return {
        ...admin,
        hidden: true
      };
    }
    return admin;
  });
  return { admins, columns };
};

const removeAdmin = async (service, groupId, orgId, userId) => {
  return await service.delete(
    `/group/admins?orgId=${orgId}&groupId=${groupId}&userId=${userId}`
  );
};

const getUsers = async (service, groupId, orgId) => {
  const users = await service.get(
    `/group/users?orgId=${orgId}&groupId=${groupId}&addAdmins=true`
  );
  return { users, columns };
};
const addAdmin = async (service, groupId, orgId, userId) => {
  const users = await service.put(`/group/admins`, {
    orgId,
    groupId,
    userId
  });
  return { users, columns };
};
const updateGroup = async (service, groupId, orgId, name) => {
  return await service.put(`/group/profile`, {
    orgId,
    groupId,
    name
  });
};

export { getAdminUsers, removeAdmin, getUsers, addAdmin, updateGroup };
