import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  boxStyle: {
    border: (colors) => `${colors.hrv_border_color} solid 3px`,
    borderRadius: 10,
    width: 160,
    height: 160,
    background: (colors) => `${colors.hrv_white}`,
    padding: 20,
    marginRight: 16,
    marginBottom: 16,
    float: "left",
    "&:hover": {
      borderColor: `#dadada`,
    },
  },
  boxStyle1: {
    position: "relative",
  },
  typo5: {
    fontSize: 12,
    color: (colors) => colors.hrv_black,
    fontWeight: 600,
    textTransform: "uppercase",
  },
  typo1: {
    color: "#2e2e2e",
    fontSize: 30,
    fontWeight: 500,
    float: "left",
    // textTransform: "uppercase"
  },
  typo4: {
    fontSize: 11,
    paddingLeft: 6,
    position: "relative",
    fontWeight: 400,
    // textTransform: "uppercase",
    marginTop: 10,
  },
  hrvGridPaddedStyle: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  typo6: {
    padding: 0,
    margin: 0,
  },
  iconStyle: {
    padding: 8,
    minWidth: 16,
    "& img": {
      width: 16,
    },
  },
  changeBtn: {
    position: "absolute",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "100%",
    zIndex: 1,
    right: -15,
    top: -15,
    minWidth: 10,
    height: 30,
    width: 30,
    "& img": {
      height: 16,
      width: 16,
    },
  },
  tagsTypo: {
    display: "flex",
    fontSize: 12,
    color: theme.palette.primary.main,
    fontWeight: 500,
    marginTop: 6,
  },
  tagContentTypo: {
    display: "block",
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 500,
  },
}));
