import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  FormHelperText,
  CircularProgress,
  Tooltip
} from "@material-ui/core";
import withMiddleware from "../../../middleware";
import { useStyles } from "./style";
import { useUtils } from "../../../utils";
import { useValidation } from "../../../components/validation";
import { validationInput } from "./validation";
import { generatePassword, addOrg } from "./handler";
import Visibility from "@material-ui/icons/VisibilityOutlined";
import VisibilityOff from "@material-ui/icons/VisibilityOffOutlined";
import InfoIcon from "@material-ui/icons/Info";

function AddOrg(props) {
  const { service, onOpen, onAddOrg } = props;
  const { colors, getEncPassword, showToast } = useUtils();
  const classes = useStyles(colors);
  const { validate, validation, autoValidate, isValid } = useValidation(
    validationInput
  );
  const [org, setOrg] = useState({
    name: "",
    uniqueName: "",
    email: "orgadmin"
  });
  const [password, setPassword] = useState(generatePassword());
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();

  useEffect(() => {
    validate(
      [validation["email"], validation["password"]],
      ["orgadmin", password]
    );
  }, []);

  const defaultInputProps = {
    fullWidth: true,
    variant: "outlined",
    className: `${classes.spacing}`,
    InputProps: {
      classes: {
        input: classes.input
      }
    },
    inputProps: {
      autoComplete: "new-password",
      form: {
        autoComplete: "off"
      }
    }
  };

  function handleInputChange(field, value) {
    setMessage(null);
    validate(validation[field], value);
    setOrg({ ...org, [field]: value });
  }

  async function handleOrgSave() {
    try {
      setLoading(true);
      setMessage(null);
      const pwd = getEncPassword(password);
      await addOrg(service, org, pwd);
      setLoading(false);
      onAddOrg();
    } catch (err) {
      setMessage(err.message);
      setLoading(false);
    }
  }

  const Label = ({ label, others = "" }) => (
    <Grid
      container
      className={classes.labelContainer}
      alignContent="center"
      alignItems="center"
    >
      <Typography variant="body2">{label}</Typography>
      {others}
    </Grid>
  );

  const Footer = () => (
    <Grid container justify="flex-end" style={{ padding: "0 24px 24px 24px" }}>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          disabled={!isValid}
          onClick={handleOrgSave}
          className={classes.gap}
        >
          Add
        </Button>
      </Grid>
      <Grid item>
        <Button onClick={() => onOpen(false)}>Cancel</Button>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid container alignContent="center" alignItems="center" className={classes.title}>
        <Typography>Add Organization</Typography>
      </Grid>
      {loading && (
        <Grid container className={classes.loader} justify="center" alignItems="center" alignContent="center">
          <CircularProgress />
        </Grid>
      )}
      <Grid container className={classes.form}>
        {message && (
          <Grid container justify="center" className={classes.error}>
            <FormHelperText error={true}>{message}</FormHelperText>
          </Grid>
        )}
        <Grid container>
          <Grid item xs={4}>
            <Label label="Organization Name" />
          </Grid>
          <Grid item xs>
            <TextField
              id="name"
              {...defaultInputProps}
              onChange={(e) => handleInputChange("name", e.target.value)}
              error={!validation.name.isValid}
              helperText={validation.name.errMessage}
              onBlur={(e) => validate(validation["name"], org.name)}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <Label
              label="Domain Name"
              others={
                <Tooltip title="Please specify a unique name to represent this organiation (in the format example.org, abc.biz etc)">
                  <InfoIcon color="primary" className={classes.infoIcon} />
                </Tooltip>
              }
            />
          </Grid>
          <Grid item xs>
            <TextField
              id="uniqueName"
              {...defaultInputProps}
              onChange={(e) => handleInputChange("uniqueName", e.target.value)}
              error={!validation.uniqueName.isValid}
              helperText={validation.uniqueName.errMessage}
              onBlur={(e) => validate(validation["uniqueName"], org.uniqueName)}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <Label label="Default Admin Account Email" />
          </Grid>
          <Grid item xs>
            <TextField
              value={org.email}
              {...defaultInputProps}
              onChange={(e) => handleInputChange("email", e.target.value)}
              error={!validation.email.isValid}
              helperText={validation.email.errMessage}
              InputProps={{
                // endAdornment: (
                //   <InputAdornment position="end">
                //     {org.uniqueName ? "@" + org.uniqueName : ""}
                //   </InputAdornment>
                // ),
                classes: {
                  input: classes.input,
                },
              }}
              onBlur={(e) => validate(validation["email"], org.email)}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <Label label="Password" />
          </Grid>
          <Grid item xs>
            <TextField
              id="password"
              value={password}
              {...defaultInputProps}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton classes={{ root: classes.iconButton }} onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
                classes: {
                  input: classes.input,
                },
              }}
              onChange={(e) => {
                setMessage(null);
                validate(validation["password"], e.target.value);
                setPassword(e.target.value);
              }}
              error={!validation.password.isValid}
              helperText={validation.password.errMessage}
              // onBlur={e => validate(validation["password"], password)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}

export default withMiddleware(AddOrg);
