import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  btnRoot: {
    position: "fixed",
    right: 24,
    top: 80,
    zIndex: 12
  },
  button: {
    padding: "6px 16px 4px 16px",
    fontSize: 12,
    fontWeight: 600
  },
  dialogRoot: { width: 600, background: "#fff" },
  dialogTitle: {
    height: 56,
    width: "100%",
    background: theme.palette.primary.main,
    color: "#fff",
    padding: 15,
    fontWeight: 500,
    fontSize: 18,
    textAlign: "center",
    textTransform: "uppercase"
  },
  dialogContent: { padding: "56px 30px 30px 30px" },
  autocomplete: {
    width: "100%"
  },
  option: {
    fontSize: 14
  },
  chip: {
    backgroundColor: theme.palette.info.light
  }
}));
