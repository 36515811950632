export const validationInput = [
  {
    key: "name",
    type: "text",
  },
  {
    key: "uniqueName",
    type: "domain",
  },
  {
    key: "email",
    type: "email",
  },
  {
    key: "password",
    type: "text",
  },
];
