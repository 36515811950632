import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "98px 24px 24px 138px",
  },
  divider: {
    width: 32,
    height: 4,
    background: theme.palette.primary.main,
    borderRadius: 10,
    marginBottom: 24,
  },
  typoHead: {
    fontSize: 16,
    fontWeight: 500,
  },
  trendContainer: {
    paddingTop: 24,
  },
  emptyContainer: {
    background: (colors) => colors.hrv_white,
    padding: 16,
    border: (colors) => `${colors.hrv_border_color} solid 3px`,
    borderRadius: 10,
    marginBottom: 8,
    minHeight: 78,
  },
  typoMessage: {
    fontSize: 14,
  },
  fullHeight: {
    height: "100%",
  },
}));
