export const getAggregate = async (service, userId) => {
  const res = await service.get(`/hrv_analytics/dashboard_aggregated?type=user&id=${userId}`);
  return parseResponse(res);
};

export const getUserReadings = async (service, userId) => {
  const res = await service.get(`/user/rmssd?user_id=${userId}&limit=6`);
  const parsedRes = parseResponse(res);
  return parsedRes.readings;
};

const parseResponse = (res) => {
  const hasNaN = res.toString().search("NaN");
  if (hasNaN === -1) {
    return res;
  } else {
    const parsedData = JSON.parse(res.toString().replace(/\bNaN\b/g, "0"));
    return parsedData;
  }
};
