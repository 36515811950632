export const validationInput = [
  {
    key: "username",
    type: "email"
  },
  {
    key: "password",
    type: "text"
  }
];
